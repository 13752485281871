import { ErrorModalType } from "@/types/ErrorTypes";

const ErrorModal = {
  state: {
    error: {
      show: false,
      errorMessage: "",
      subTitle: "",
    } as ErrorModalType,
  },
  getters: {
    getError(state: { error: ErrorModalType }): ErrorModalType {
      return state.error;
    },
  },
  mutations: {
    setError(state: { error: ErrorModalType }, payload: ErrorModalType) {
      state.error = { ...state.error, ...payload, show: true };
    },
    clearError(state: { error: ErrorModalType }) {
      state.error = { show: false, errorMessage: "", subTitle: "" };
    },
  },
  actions: {},
};

export default ErrorModal;
