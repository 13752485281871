import { AuthModel, StockModel } from "@/api/client";
import { currentUser } from "@/api/UserApi";
import { Commit } from "vuex";

const UserStore = {
  state: {
    currentUser: null as AuthModel | null,
  },
  getters: {
    getCurrentUser(state: { currentUser: AuthModel | null }): AuthModel | null {
      return state.currentUser;
    },
    getStocks(state: {
      currentUser: AuthModel | null;
    }): StockModel[] | undefined {
      return state.currentUser?.stocks;
    },
    getCurrentStock(state: {
      currentUser: AuthModel | null;
    }): number | undefined {
      return state.currentUser?.selectedStockId;
    },
  },
  mutations: {
    setCurrentUser(state: { currentUser: AuthModel | null }, user: AuthModel) {
      state.currentUser = user;
    },
  },
  actions: {
    async fetchCurrentUser({ commit }: { commit: Commit }) {
      try {
        const response = await currentUser();
        commit("setCurrentUser", response);
        console.log(response);
      } catch (e) {
        console.error("Failed to fetch current user:", e);
        throw e;
      }
    },
  },
};

export default UserStore;
