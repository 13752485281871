<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#121212',
        fontFamily: 'Raleway',
        borderRadius: 12,
        colorError: '#fc0004',
      },
    }"
  >
    <div class="page-wrapper">
      <router-view />
      <ModalError :error="getError" @onOk="clearError" />
    </div>
  </a-config-provider>
</template>

<script type="ts">
import {mapActions, mapGetters, mapMutations} from "vuex";
import ModalError from "@/components/ModalError.vue";

export default {
  name: 'App',
  components: { ModalError },
  methods: {
    ...mapActions(['checkTokenAndLogin']),
    ...mapMutations(['clearError'])
  },
  computed: {
    ...mapGetters(['getError'])
  },
  created () {
    this.checkTokenAndLogin()
  }
};
</script>
