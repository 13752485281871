<template>
  <a-modal
    v-model:open="internalModalState"
    ok-text="Ok"
    @ok="onOk"
    class="a-modal_custom_error"
  >
    <a-flex class="error" v-if="error.errorMessage" align="start">
      <ExclamationCircleFilled
        style="color: #fff; font-size: 16px; margin-top: 4px"
      />
      <a-flex vertical class="ml-2">
        <span>{{ error.errorMessage }}</span>
        <span class="sub-title">{{ error.subTitle }}</span>
      </a-flex>
    </a-flex>
    <template #footer>
      <a-button type="primary" class="w-100" @click="onOk">Ok</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
import { ErrorModalType } from "@/types/ErrorTypes";

export default defineComponent({
  name: "ModalError",
  components: {
    ExclamationCircleFilled,
  },
  props: {
    error: {
      type: Object as PropType<ErrorModalType>,
      required: true,
    },
  },
  data() {
    return {
      internalModalState: this.error.show,
    };
  },
  watch: {
    error: {
      handler(newVal) {
        this.internalModalState = newVal.show;
      },
      deep: true,
    },
    internalModalState(newVal) {
      if (!newVal) {
        this.onOk();
      }
    },
  },
  methods: {
    onOk() {
      this.$emit("onOk");
    },
  },
});
</script>

<style scoped>
.error {
  background-color: #ff0000;
  color: #fff;
  border-radius: 12px;
  padding: 12px;
}

.sub-title {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}
</style>
