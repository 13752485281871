import { axiosInstance, config } from "./Config";
import { AuthModel, UserControllerRestApiFactory } from "@/api/client";
import { RawAxiosRequestConfig } from "axios";

const userApiClient = UserControllerRestApiFactory(
  config,
  undefined,
  axiosInstance
);

export const currentUser = async (
  options: RawAxiosRequestConfig = {}
): Promise<AuthModel> => {
  try {
    const response = await userApiClient.current(options);
    return response.data;
  } catch (err) {
    console.error("Error getting current user:", err);
    throw err;
  }
};

export const setStock = async (
  stockId: number,
  options: RawAxiosRequestConfig = {}
) => {
  try {
    await userApiClient.selectStock(stockId, options);
  } catch (err) {
    console.error("Error setting stock:", err);
    throw err;
  }
};
