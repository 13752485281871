import { Configuration } from "@/api/client";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

interface ErrorResponse {
  message: string;
}

export const config = new Configuration({
  basePath: process.env.VUE_APP_PROTECTED_API_URL,
  baseOptions: {
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  },
});

export const axiosInstance = axios.create({
  baseURL: config.basePath,
  timeout: config.baseOptions.timeout,
  headers: config.baseOptions.headers,
});

export class CustomAxiosError extends Error {
  response?: AxiosResponse<ErrorResponse>;
  request?: AxiosRequestConfig;

  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, CustomAxiosError.prototype);
  }
}
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ErrorResponse>) => {
    const customError = new CustomAxiosError(error.message);

      if (error.response) {
      customError.message = `Error: ${error.response.status} - ${error.response.data?.message}`;
      customError.response = error.response;
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
    } else if (error.request) {
      customError.message =
        "The server did not respond. Please try again later.";
      customError.request = error.request;
    }

    return Promise.reject(customError);
  }
);

// To Do сделать еще один config для авторизации с использованием public url
