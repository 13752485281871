import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bdd0ee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleFilled = _resolveComponent("ExclamationCircleFilled")!
  const _component_a_flex = _resolveComponent("a-flex")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    open: _ctx.internalModalState,
    "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalModalState) = $event)),
    "ok-text": "Ok",
    onOk: _ctx.onOk,
    class: "a-modal_custom_error"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        type: "primary",
        class: "w-100",
        onClick: _ctx.onOk
      }, {
        default: _withCtx(() => [
          _createTextVNode("Ok")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      (_ctx.error.errorMessage)
        ? (_openBlock(), _createBlock(_component_a_flex, {
            key: 0,
            class: "error",
            align: "start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ExclamationCircleFilled, { style: {"color":"#fff","font-size":"16px","margin-top":"4px"} }),
              _createVNode(_component_a_flex, {
                vertical: "",
                class: "ml-2"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.error.errorMessage), 1),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.error.subTitle), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open", "onOk"]))
}