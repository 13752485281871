/* tslint:disable */
/* eslint-disable */
/**
 * ParcelPoint admin backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'regionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'building'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'company'?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lockerID'?: string;
}
/**
 * 
 * @export
 * @interface AddressModel
 */
export interface AddressModel {
    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'name'?: string;
    /**
     * 
     * @type {CountryDictModel}
     * @memberof AddressModel
     */
    'country'?: CountryDictModel;
    /**
     * 
     * @type {RegionDictModel}
     * @memberof AddressModel
     */
    'region'?: RegionDictModel;
    /**
     * 
     * @type {CityDictModel}
     * @memberof AddressModel
     */
    'city'?: CityDictModel;
    /**
     * 
     * @type {Array<CityDictModel>}
     * @memberof AddressModel
     */
    'cities'?: Array<CityDictModel>;
    /**
     * 
     * @type {OfficeDictModel}
     * @memberof AddressModel
     */
    'office'?: OfficeDictModel;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface AddressStorageModel
 */
export interface AddressStorageModel {
    /**
     * 
     * @type {number}
     * @memberof AddressStorageModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressStorageModel
     */
    'parcelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressStorageModel
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressStorageModel
     */
    'place'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStorageModel
     */
    'trackCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStorageModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressStorageModel
     */
    'stockId'?: number;
}
/**
 * 
 * @export
 * @interface ApiParcelLocationReadDto
 */
export interface ApiParcelLocationReadDto {
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'regionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'countryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'cityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelLocationReadDto
     */
    'regionName'?: string;
}
/**
 * 
 * @export
 * @interface ApiParcelWithStatusesReadDto
 */
export interface ApiParcelWithStatusesReadDto {
    /**
     * 
     * @type {number}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'trackCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'route'?: ApiParcelWithStatusesReadDtoRouteEnum;
    /**
     * 
     * @type {ApiParcelLocationReadDto}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'from'?: ApiParcelLocationReadDto;
    /**
     * 
     * @type {ApiParcelLocationReadDto}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'to'?: ApiParcelLocationReadDto;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'typeOfDelivery'?: ApiParcelWithStatusesReadDtoTypeOfDeliveryEnum;
    /**
     * 
     * @type {Array<ParcelStatusReadDto>}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'statuses'?: Array<ParcelStatusReadDto>;
    /**
     * 
     * @type {string}
     * @memberof ApiParcelWithStatusesReadDto
     */
    'lastMileTrack'?: string;
}

export const ApiParcelWithStatusesReadDtoRouteEnum = {
    Direct: 'DIRECT',
    Transit: 'TRANSIT'
} as const;

export type ApiParcelWithStatusesReadDtoRouteEnum = typeof ApiParcelWithStatusesReadDtoRouteEnum[keyof typeof ApiParcelWithStatusesReadDtoRouteEnum];
export const ApiParcelWithStatusesReadDtoTypeOfDeliveryEnum = {
    Delivering: 'DELIVERING',
    DropOff: 'DROP_OFF',
    BusinessUserDelivery: 'BUSINESS_USER_DELIVERY',
    None: 'NONE'
} as const;

export type ApiParcelWithStatusesReadDtoTypeOfDeliveryEnum = typeof ApiParcelWithStatusesReadDtoTypeOfDeliveryEnum[keyof typeof ApiParcelWithStatusesReadDtoTypeOfDeliveryEnum];

/**
 * 
 * @export
 * @interface AuthModel
 */
export interface AuthModel {
    /**
     * 
     * @type {number}
     * @memberof AuthModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthModel
     */
    'fullName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthModel
     */
    'selectedStockId'?: number;
    /**
     * 
     * @type {Set<RoleModel>}
     * @memberof AuthModel
     */
    'roles'?: Set<RoleModel>;
    /**
     * 
     * @type {Array<StockModel>}
     * @memberof AuthModel
     */
    'stocks'?: Array<StockModel>;
}
/**
 * 
 * @export
 * @interface BarcodeValueRange
 */
export interface BarcodeValueRange {
    /**
     * 
     * @type {number}
     * @memberof BarcodeValueRange
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof BarcodeValueRange
     */
    'to'?: number;
}
/**
 * 
 * @export
 * @interface BoxDictModel
 */
export interface BoxDictModel {
    /**
     * 
     * @type {number}
     * @memberof BoxDictModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoxDictModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoxDictModel
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof BoxDictModel
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoxDictModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoxDictModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoxDictModel
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoxDictModel
     */
    'barcode'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BoxDictModel
     */
    'show'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BoxDictModel
     */
    'stockId'?: number;
}
/**
 * 
 * @export
 * @interface BusinessTariffModel
 */
export interface BusinessTariffModel {
    /**
     * 
     * @type {number}
     * @memberof BusinessTariffModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessTariffModel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessTariffModel
     */
    'discountPerHundred'?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessTariffModel
     */
    'maximumOverweight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessTariffModel
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessTariffModel
     */
    'cdt'?: string;
}
/**
 * 
 * @export
 * @interface CargoSpaceCreateDto
 */
export interface CargoSpaceCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceCreateDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceCreateDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceCreateDto
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceCreateDto
     */
    'weight'?: number;
    /**
     * 
     * @type {Array<ProductCreateDto>}
     * @memberof CargoSpaceCreateDto
     */
    'products': Array<ProductCreateDto>;
}
/**
 * 
 * @export
 * @interface CargoSpaceReadDto
 */
export interface CargoSpaceReadDto {
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceReadDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceReadDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceReadDto
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceReadDto
     */
    'weight'?: number;
    /**
     * 
     * @type {string}
     * @memberof CargoSpaceReadDto
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceReadDto
     */
    'parcelId'?: number;
    /**
     * 
     * @type {Array<ProductReadDto>}
     * @memberof CargoSpaceReadDto
     */
    'products'?: Array<ProductReadDto>;
}
/**
 * 
 * @export
 * @interface CargoSpaceUpdateDto
 */
export interface CargoSpaceUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceUpdateDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceUpdateDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceUpdateDto
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpaceUpdateDto
     */
    'weight'?: number;
    /**
     * 
     * @type {Array<ProductUpdateDto>}
     * @memberof CargoSpaceUpdateDto
     */
    'products': Array<ProductUpdateDto>;
}
/**
 * 
 * @export
 * @interface CargoSpacesModel
 */
export interface CargoSpacesModel {
    /**
     * 
     * @type {number}
     * @memberof CargoSpacesModel
     */
    'id'?: number;
    /**
     * 
     * @type {Array<ProductModel>}
     * @memberof CargoSpacesModel
     */
    'products'?: Array<ProductModel>;
    /**
     * 
     * @type {number}
     * @memberof CargoSpacesModel
     */
    'parcelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CargoSpacesModel
     */
    'packageItemId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CargoSpacesModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpacesModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpacesModel
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpacesModel
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpacesModel
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface CdekCityModel
 */
export interface CdekCityModel {
    /**
     * 
     * @type {number}
     * @memberof CdekCityModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CdekCityModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekCityModel
     */
    'sub_region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekCityModel
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekCityModel
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekCityModel
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekCityModel
     */
    'region_code'?: string;
}
/**
 * 
 * @export
 * @interface CdekLocationModel
 */
export interface CdekLocationModel {
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'fias_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'sub_region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'city_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'kladr_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekLocationModel
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface CdekPvzModel
 */
export interface CdekPvzModel {
    /**
     * 
     * @type {number}
     * @memberof CdekPvzModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'address_comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'nearest_station'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'nearest_metro_station'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'work_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekPvzModel
     */
    'owner_code'?: string;
    /**
     * 
     * @type {CdekLocationModel}
     * @memberof CdekPvzModel
     */
    'location'?: CdekLocationModel;
}
/**
 * 
 * @export
 * @interface CdekRegionModel
 */
export interface CdekRegionModel {
    /**
     * 
     * @type {string}
     * @memberof CdekRegionModel
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekRegionModel
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekRegionModel
     */
    'region_code'?: string;
}
/**
 * 
 * @export
 * @interface CdekTariffModel
 */
export interface CdekTariffModel {
    /**
     * 
     * @type {number}
     * @memberof CdekTariffModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CdekTariffModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekTariffModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekTariffModel
     */
    'parcelType'?: CdekTariffModelParcelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CdekTariffModel
     */
    'deliveryMode'?: CdekTariffModelDeliveryModeEnum;
    /**
     * 
     * @type {number}
     * @memberof CdekTariffModel
     */
    'weight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CdekTariffModel
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CdekTariffModel
     */
    'code'?: number;
}

export const CdekTariffModelParcelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type CdekTariffModelParcelTypeEnum = typeof CdekTariffModelParcelTypeEnum[keyof typeof CdekTariffModelParcelTypeEnum];
export const CdekTariffModelDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type CdekTariffModelDeliveryModeEnum = typeof CdekTariffModelDeliveryModeEnum[keyof typeof CdekTariffModelDeliveryModeEnum];

/**
 * 
 * @export
 * @interface CityDict
 */
export interface CityDict {
    /**
     * 
     * @type {number}
     * @memberof CityDict
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CityDict
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'subRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'regionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDict
     */
    'lang'?: CityDictLangEnum;
    /**
     * 
     * @type {RegionDict}
     * @memberof CityDict
     */
    'region'?: RegionDict;
}

export const CityDictLangEnum = {
    Ru: 'RU',
    En: 'EN'
} as const;

export type CityDictLangEnum = typeof CityDictLangEnum[keyof typeof CityDictLangEnum];

/**
 * 
 * @export
 * @interface CityDictModel
 */
export interface CityDictModel {
    /**
     * 
     * @type {number}
     * @memberof CityDictModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'subRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'regionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CityDictModel
     */
    'lang'?: CityDictModelLangEnum;
}

export const CityDictModelLangEnum = {
    Ru: 'RU',
    En: 'EN'
} as const;

export type CityDictModelLangEnum = typeof CityDictModelLangEnum[keyof typeof CityDictModelLangEnum];

/**
 * 
 * @export
 * @interface ClientCommentsModel
 */
export interface ClientCommentsModel {
    /**
     * 
     * @type {number}
     * @memberof ClientCommentsModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientCommentsModel
     */
    'parcelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientCommentsModel
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCommentsModel
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCommentsModel
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCommentsModel
     */
    'cdt'?: string;
}
/**
 * 
 * @export
 * @interface CommentReadDto
 */
export interface CommentReadDto {
    /**
     * 
     * @type {number}
     * @memberof CommentReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommentReadDto
     */
    'parcelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommentReadDto
     */
    'mangerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentReadDto
     */
    'mangerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentReadDto
     */
    'type'?: CommentReadDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentReadDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentReadDto
     */
    'cdt'?: string;
}

export const CommentReadDtoTypeEnum = {
    Damaged: 'DAMAGED',
    Undefined: 'UNDEFINED',
    Prohibited: 'PROHIBITED',
    LinkInvalid: 'LINK_INVALID'
} as const;

export type CommentReadDtoTypeEnum = typeof CommentReadDtoTypeEnum[keyof typeof CommentReadDtoTypeEnum];

/**
 * 
 * @export
 * @interface CommentsModel
 */
export interface CommentsModel {
    /**
     * 
     * @type {number}
     * @memberof CommentsModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommentsModel
     */
    'parcelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommentsModel
     */
    'mangerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentsModel
     */
    'mangerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentsModel
     */
    'type'?: CommentsModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentsModel
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentsModel
     */
    'cdt'?: string;
}

export const CommentsModelTypeEnum = {
    Damaged: 'DAMAGED',
    Undefined: 'UNDEFINED',
    Prohibited: 'PROHIBITED',
    LinkInvalid: 'LINK_INVALID'
} as const;

export type CommentsModelTypeEnum = typeof CommentsModelTypeEnum[keyof typeof CommentsModelTypeEnum];

/**
 * 
 * @export
 * @interface ConsignmentFull
 */
export interface ConsignmentFull {
    /**
     * 
     * @type {string}
     * @memberof ConsignmentFull
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentFull
     */
    'awb'?: string;
    /**
     * 
     * @type {Array<Parcel>}
     * @memberof ConsignmentFull
     */
    'parcels'?: Array<Parcel>;
}
/**
 * 
 * @export
 * @interface ConsignmentNumbers
 */
export interface ConsignmentNumbers {
    /**
     * 
     * @type {string}
     * @memberof ConsignmentNumbers
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentNumbers
     */
    'awb'?: string;
    /**
     * 
     * @type {Array<ParcelDimensions>}
     * @memberof ConsignmentNumbers
     */
    'parcels'?: Array<ParcelDimensions>;
}
/**
 * 
 * @export
 * @interface ConsignmentsModel
 */
export interface ConsignmentsModel {
    /**
     * 
     * @type {number}
     * @memberof ConsignmentsModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsignmentsModel
     */
    'parcelsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'openingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'closingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'transitCountry'?: ConsignmentsModelTransitCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'status'?: ConsignmentsModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsignmentsModel
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'countryName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsignmentsModel
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'tackCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsignmentsModel
     */
    'stockId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsignmentsModel
     */
    'route'?: ConsignmentsModelRouteEnum;
    /**
     * 
     * @type {number}
     * @memberof ConsignmentsModel
     */
    'weight'?: number;
}

export const ConsignmentsModelTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ConsignmentsModelTransitCountryEnum = typeof ConsignmentsModelTransitCountryEnum[keyof typeof ConsignmentsModelTransitCountryEnum];
export const ConsignmentsModelStatusEnum = {
    Active: 'ACTIVE',
    WaitingAwb: 'WAITING_AWB',
    History: 'HISTORY',
    Sent: 'SENT',
    Deleted: 'DELETED'
} as const;

export type ConsignmentsModelStatusEnum = typeof ConsignmentsModelStatusEnum[keyof typeof ConsignmentsModelStatusEnum];
export const ConsignmentsModelRouteEnum = {
    Cdek: 'CDEK',
    Ruspost: 'RUSPOST',
    MshCdek: 'MSH_CDEK'
} as const;

export type ConsignmentsModelRouteEnum = typeof ConsignmentsModelRouteEnum[keyof typeof ConsignmentsModelRouteEnum];

/**
 * 
 * @export
 * @interface ContainerBarcodeDictModel
 */
export interface ContainerBarcodeDictModel {
    /**
     * 
     * @type {number}
     * @memberof ContainerBarcodeDictModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContainerBarcodeDictModel
     */
    'value'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContainerBarcodeDictModel
     */
    'countryDictId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContainerBarcodeDictModel
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerBarcodeDictModel
     */
    'transitType'?: ContainerBarcodeDictModelTransitTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContainerBarcodeDictModel
     */
    'status'?: ContainerBarcodeDictModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ContainerBarcodeDictModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContainerBarcodeDictModel
     */
    'stockId'?: number;
    /**
     * 
     * @type {BarcodeValueRange}
     * @memberof ContainerBarcodeDictModel
     */
    'valueRange'?: BarcodeValueRange;
    /**
     * 
     * @type {string}
     * @memberof ContainerBarcodeDictModel
     */
    'route'?: ContainerBarcodeDictModelRouteEnum;
}

export const ContainerBarcodeDictModelTransitTypeEnum = {
    Transit: 'TRANSIT',
    NotTransit: 'NOT_TRANSIT'
} as const;

export type ContainerBarcodeDictModelTransitTypeEnum = typeof ContainerBarcodeDictModelTransitTypeEnum[keyof typeof ContainerBarcodeDictModelTransitTypeEnum];
export const ContainerBarcodeDictModelStatusEnum = {
    Used: 'USED',
    NotUsed: 'NOT_USED'
} as const;

export type ContainerBarcodeDictModelStatusEnum = typeof ContainerBarcodeDictModelStatusEnum[keyof typeof ContainerBarcodeDictModelStatusEnum];
export const ContainerBarcodeDictModelRouteEnum = {
    Cdek: 'CDEK',
    Ruspost: 'RUSPOST',
    MshCdek: 'MSH_CDEK'
} as const;

export type ContainerBarcodeDictModelRouteEnum = typeof ContainerBarcodeDictModelRouteEnum[keyof typeof ContainerBarcodeDictModelRouteEnum];

/**
 * 
 * @export
 * @interface ContainerCreateModel
 */
export interface ContainerCreateModel {
    /**
     * 
     * @type {number}
     * @memberof ContainerCreateModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContainerCreateModel
     */
    'boxId'?: number;
    /**
     * 
     * @type {Array<ParcelModel>}
     * @memberof ContainerCreateModel
     */
    'parcels'?: Array<ParcelModel>;
    /**
     * 
     * @type {number}
     * @memberof ContainerCreateModel
     */
    'consignmentId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerCreateModel
     */
    'createSeal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContainerCreateModel
     */
    'seal'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContainerCreateModel
     */
    'stockId'?: number;
}
/**
 * 
 * @export
 * @interface ContainerModel
 */
export interface ContainerModel {
    /**
     * 
     * @type {number}
     * @memberof ContainerModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContainerModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerModel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContainerModel
     */
    'parcelsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContainerModel
     */
    'dimensions'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContainerModel
     */
    'weight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContainerModel
     */
    'seal'?: string;
    /**
     * 
     * @type {Array<ParcelContainerModel>}
     * @memberof ContainerModel
     */
    'parcels'?: Array<ParcelContainerModel>;
    /**
     * 
     * @type {number}
     * @memberof ContainerModel
     */
    'stockId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContainerModel
     */
    'consignmentId'?: number;
}
/**
 * 
 * @export
 * @interface CostFilterModel
 */
export interface CostFilterModel {
    /**
     * 
     * @type {number}
     * @memberof CostFilterModel
     */
    'calculatorVersion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CostFilterModel
     */
    'ccd'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CostFilterModel
     */
    'cost_of_investment'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostFilterModel
     */
    'discountType'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostFilterModel
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CostFilterModel
     */
    'parcelType'?: CostFilterModelParcelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CostFilterModel
     */
    'deliveryMode'?: CostFilterModelDeliveryModeEnum;
    /**
     * 
     * @type {Array<PackageDimensionsModel>}
     * @memberof CostFilterModel
     */
    'packages'?: Array<PackageDimensionsModel>;
    /**
     * 
     * @type {string}
     * @memberof CostFilterModel
     */
    'route'?: CostFilterModelRouteEnum;
}

export const CostFilterModelParcelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type CostFilterModelParcelTypeEnum = typeof CostFilterModelParcelTypeEnum[keyof typeof CostFilterModelParcelTypeEnum];
export const CostFilterModelDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type CostFilterModelDeliveryModeEnum = typeof CostFilterModelDeliveryModeEnum[keyof typeof CostFilterModelDeliveryModeEnum];
export const CostFilterModelRouteEnum = {
    Cdek: 'CDEK',
    Ruspost: 'RUSPOST',
    MshCdek: 'MSH_CDEK'
} as const;

export type CostFilterModelRouteEnum = typeof CostFilterModelRouteEnum[keyof typeof CostFilterModelRouteEnum];

/**
 * 
 * @export
 * @interface CostModel
 */
export interface CostModel {
    /**
     * 
     * @type {number}
     * @memberof CostModel
     */
    'tariffCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostModel
     */
    'tariffName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CostModel
     */
    'deliverySum'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostModel
     */
    'periodMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostModel
     */
    'periodMax'?: number;
}
/**
 * 
 * @export
 * @interface CountryDict
 */
export interface CountryDict {
    /**
     * 
     * @type {number}
     * @memberof CountryDict
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryDict
     */
    'modifiedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryDict
     */
    'cdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryDict
     */
    'mdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryDict
     */
    'rdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryDict
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryDict
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CountryDict
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryDict
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryDict
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface CountryDictModel
 */
export interface CountryDictModel {
    /**
     * 
     * @type {number}
     * @memberof CountryDictModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryDictModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryDictModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryDictModel
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface Credential
 */
export interface Credential {
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'idNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'idDocType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'idIssueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'identityCardGiven'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'taxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'gender'?: CredentialGenderEnum;
    /**
     * 
     * @type {Array<HwbFile>}
     * @memberof Credential
     */
    'files'?: Array<HwbFile>;
}

export const CredentialGenderEnum = {
    F: 'F',
    M: 'M'
} as const;

export type CredentialGenderEnum = typeof CredentialGenderEnum[keyof typeof CredentialGenderEnum];

/**
 * 
 * @export
 * @interface DateFilter
 */
export interface DateFilter {
    /**
     * 
     * @type {string}
     * @memberof DateFilter
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateFilter
     */
    'until'?: string;
}
/**
 * 
 * @export
 * @interface DtoPageParcelModel
 */
export interface DtoPageParcelModel {
    /**
     * 
     * @type {Array<ParcelModel>}
     * @memberof DtoPageParcelModel
     */
    'values'?: Array<ParcelModel>;
    /**
     * 
     * @type {number}
     * @memberof DtoPageParcelModel
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoPageParcelModel
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoPageParcelModel
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ExportParcelsByParcelRegistryReportParams
 */
export interface ExportParcelsByParcelRegistryReportParams {
    /**
     * 
     * @type {string}
     * @memberof ExportParcelsByParcelRegistryReportParams
     */
    'statusGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportParcelsByParcelRegistryReportParams
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportParcelsByParcelRegistryReportParams
     */
    'dateTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportParcelsByParcelRegistryReportParams
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface ExtraContact
 */
export interface ExtraContact {
    /**
     * 
     * @type {string}
     * @memberof ExtraContact
     */
    'contactType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtraContact
     */
    'contactNumber'?: string;
}
/**
 * 
 * @export
 * @interface FileDownloadDto
 */
export interface FileDownloadDto {
    /**
     * 
     * @type {string}
     * @memberof FileDownloadDto
     */
    'base64'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadDto
     */
    'extension'?: string;
}
/**
 * 
 * @export
 * @interface FileModel
 */
export interface FileModel {
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface Hwb
 */
export interface Hwb {
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'hwBRefNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'consigmentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'deliveryComments'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'contractNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'coDIndicator'?: HwbCoDIndicatorEnum;
    /**
     * 
     * @type {number}
     * @memberof Hwb
     */
    'chargeableSum'?: number;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'serviceCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof Hwb
     */
    'parcelQty'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hwb
     */
    'hwBWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'goodsCurrency'?: string;
    /**
     * 
     * @type {number}
     * @memberof Hwb
     */
    'declaredValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'declaredCurrency'?: string;
    /**
     * 
     * @type {LastMileInfo}
     * @memberof Hwb
     */
    'lastMileInfo'?: LastMileInfo;
    /**
     * 
     * @type {string}
     * @memberof Hwb
     */
    'incoterms'?: HwbIncotermsEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Hwb
     */
    'additionalServices'?: Array<string>;
    /**
     * 
     * @type {Array<HwbExtra>}
     * @memberof Hwb
     */
    'hwBExtras'?: Array<HwbExtra>;
    /**
     * 
     * @type {Array<HwbFile>}
     * @memberof Hwb
     */
    'files'?: Array<HwbFile>;
    /**
     * 
     * @type {SenderQuery}
     * @memberof Hwb
     */
    'senderInfo'?: SenderQuery;
    /**
     * 
     * @type {ReceiverQuery}
     * @memberof Hwb
     */
    'receiverInfo'?: ReceiverQuery;
    /**
     * 
     * @type {Array<ParcelQuery>}
     * @memberof Hwb
     */
    'parcels'?: Array<ParcelQuery>;
}

export const HwbCoDIndicatorEnum = {
    _0: '0',
    _1: '1'
} as const;

export type HwbCoDIndicatorEnum = typeof HwbCoDIndicatorEnum[keyof typeof HwbCoDIndicatorEnum];
export const HwbIncotermsEnum = {
    Cfr: 'CFR',
    Cif: 'CIF',
    Cip: 'CIP',
    Cpt: 'CPT',
    Dap: 'DAP',
    Ddp: 'DDP',
    Dpu: 'DPU',
    Exw: 'EXW',
    Fas: 'FAS',
    Fca: 'FCA',
    Fob: 'FOB'
} as const;

export type HwbIncotermsEnum = typeof HwbIncotermsEnum[keyof typeof HwbIncotermsEnum];

/**
 * 
 * @export
 * @interface HwbExtra
 */
export interface HwbExtra {
    /**
     * 
     * @type {string}
     * @memberof HwbExtra
     */
    'extraKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbExtra
     */
    'extraValue'?: string;
}
/**
 * 
 * @export
 * @interface HwbFile
 */
export interface HwbFile {
    /**
     * 
     * @type {string}
     * @memberof HwbFile
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbFile
     */
    'fileName'?: string;
    /**
     * 
     * @type {HwbFileMetadata}
     * @memberof HwbFile
     */
    'metadata'?: HwbFileMetadata;
}
/**
 * 
 * @export
 * @interface HwbFileMetadata
 */
export interface HwbFileMetadata {
    /**
     * 
     * @type {string}
     * @memberof HwbFileMetadata
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbFileMetadata
     */
    'source'?: HwbFileMetadataSourceEnum;
}

export const HwbFileMetadataSourceEnum = {
    External: 'External'
} as const;

export type HwbFileMetadataSourceEnum = typeof HwbFileMetadataSourceEnum[keyof typeof HwbFileMetadataSourceEnum];

/**
 * 
 * @export
 * @interface HwbId
 */
export interface HwbId {
    /**
     * 
     * @type {string}
     * @memberof HwbId
     */
    'hwBNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbId
     */
    'hwBRefNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbId
     */
    'consigmentNumber'?: string;
}
/**
 * 
 * @export
 * @interface HwbResponse
 */
export interface HwbResponse {
    /**
     * 
     * @type {string}
     * @memberof HwbResponse
     */
    'hwBNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbResponse
     */
    'hwBRefNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HwbResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HwbResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface HwbUpload
 */
export interface HwbUpload {
    /**
     * 
     * @type {boolean}
     * @memberof HwbUpload
     */
    'synch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HwbUpload
     */
    'contractNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbUpload
     */
    'author'?: string;
    /**
     * 
     * @type {number}
     * @memberof HwbUpload
     */
    'totalWeigth'?: number;
    /**
     * 
     * @type {number}
     * @memberof HwbUpload
     */
    'totalVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof HwbUpload
     */
    'totalCOD'?: number;
    /**
     * 
     * @type {number}
     * @memberof HwbUpload
     */
    'hwBQty'?: number;
    /**
     * 
     * @type {string}
     * @memberof HwbUpload
     */
    'sender'?: string;
    /**
     * 
     * @type {string}
     * @memberof HwbUpload
     */
    'receiver'?: string;
    /**
     * 
     * @type {Array<Hwb>}
     * @memberof HwbUpload
     */
    'hwb'?: Array<Hwb>;
}
/**
 * 
 * @export
 * @interface HwbUploadDialogue
 */
export interface HwbUploadDialogue {
    /**
     * 
     * @type {HwbUpload}
     * @memberof HwbUploadDialogue
     */
    'query'?: HwbUpload;
    /**
     * 
     * @type {HwbUploadResponse}
     * @memberof HwbUploadDialogue
     */
    'response'?: HwbUploadResponse;
}
/**
 * 
 * @export
 * @interface HwbUploadResponse
 */
export interface HwbUploadResponse {
    /**
     * 
     * @type {Array<HwbResponse>}
     * @memberof HwbUploadResponse
     */
    'hwb'?: Array<HwbResponse>;
}
/**
 * 
 * @export
 * @interface IdNameModel
 */
export interface IdNameModel {
    /**
     * 
     * @type {number}
     * @memberof IdNameModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameModel
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface IncomingParcelCountsByTabs
 */
export interface IncomingParcelCountsByTabs {
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelCountsByTabs
     */
    'expected'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelCountsByTabs
     */
    'intoStock'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelCountsByTabs
     */
    'toFix'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelCountsByTabs
     */
    'toRecheck'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelCountsByTabs
     */
    'toReturn'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelCountsByTabs
     */
    'utilization'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelCountsByTabs
     */
    'history'?: number;
}
/**
 * 
 * @export
 * @interface IncomingParcelFilter
 */
export interface IncomingParcelFilter {
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelFilter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IncomingParcelFilter
     */
    'recipientFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomingParcelFilter
     */
    'creatorFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomingParcelFilter
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomingParcelFilter
     */
    'firstMileTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomingParcelFilter
     */
    'parcelPointTrack'?: string;
    /**
     * 
     * @type {number}
     * @memberof IncomingParcelFilter
     */
    'parcelRegistryCountryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IncomingParcelFilter
     */
    'transitCountry'?: IncomingParcelFilterTransitCountryEnum;
}

export const IncomingParcelFilterTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type IncomingParcelFilterTransitCountryEnum = typeof IncomingParcelFilterTransitCountryEnum[keyof typeof IncomingParcelFilterTransitCountryEnum];

/**
 * 
 * @export
 * @interface InvoiceReadDto
 */
export interface InvoiceReadDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDto
     */
    'status'?: InvoiceReadDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDto
     */
    'businessUserId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDto
     */
    'businessUserEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDto
     */
    'businessUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDto
     */
    'cdt'?: string;
}

export const InvoiceReadDtoStatusEnum = {
    New: 'NEW',
    Approved: 'APPROVED',
    Declined: 'DECLINED'
} as const;

export type InvoiceReadDtoStatusEnum = typeof InvoiceReadDtoStatusEnum[keyof typeof InvoiceReadDtoStatusEnum];

/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'unitValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'countryOfOrigin'?: string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'unitWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'detailedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'constructionComposition'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'htSCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'htSCodeExport'?: string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'vaTRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'skUBarcode'?: string;
    /**
     * 
     * @type {object}
     * @memberof Item
     */
    'itemExtras'?: object;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface LabelFileModel
 */
export interface LabelFileModel {
    /**
     * 
     * @type {string}
     * @memberof LabelFileModel
     */
    'png'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelFileModel
     */
    'pdf'?: string;
}
/**
 * 
 * @export
 * @interface LastMileInfo
 */
export interface LastMileInfo {
    /**
     * 
     * @type {string}
     * @memberof LastMileInfo
     */
    'contractor'?: string;
    /**
     * 
     * @type {string}
     * @memberof LastMileInfo
     */
    'contractorICN'?: string;
}
/**
 * 
 * @export
 * @interface LogModel
 */
export interface LogModel {
    /**
     * 
     * @type {number}
     * @memberof LogModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogModel
     */
    'source'?: LogModelSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof LogModel
     */
    'sourceId'?: number;
}

export const LogModelSourceEnum = {
    Consignments: 'CONSIGNMENTS',
    ConsignmentsContainers: 'CONSIGNMENTS_CONTAINERS',
    ConsignmentsPallet: 'CONSIGNMENTS_PALLET'
} as const;

export type LogModelSourceEnum = typeof LogModelSourceEnum[keyof typeof LogModelSourceEnum];

/**
 * 
 * @export
 * @interface LoginModel
 */
export interface LoginModel {
    /**
     * 
     * @type {string}
     * @memberof LoginModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginModel
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface MnfUploadFull
 */
export interface MnfUploadFull {
    /**
     * 
     * @type {boolean}
     * @memberof MnfUploadFull
     */
    'synch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadFull
     */
    'contractNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadFull
     */
    'manifestDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadFull
     */
    'manifestNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadFull
     */
    'haulNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadFull
     */
    'author'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnfUploadFull
     */
    'manifestWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnfUploadFull
     */
    'manifestVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnfUploadFull
     */
    'totalCOD'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnfUploadFull
     */
    'hwBQty'?: number;
    /**
     * 
     * @type {Array<Hwb>}
     * @memberof MnfUploadFull
     */
    'hwb'?: Array<Hwb>;
}
/**
 * 
 * @export
 * @interface MnfUploadFullDialogue
 */
export interface MnfUploadFullDialogue {
    /**
     * 
     * @type {MnfUploadFull}
     * @memberof MnfUploadFullDialogue
     */
    'query'?: MnfUploadFull;
    /**
     * 
     * @type {MnfUploadResponse}
     * @memberof MnfUploadFullDialogue
     */
    'response'?: MnfUploadResponse;
}
/**
 * 
 * @export
 * @interface MnfUploadNumbers
 */
export interface MnfUploadNumbers {
    /**
     * 
     * @type {string}
     * @memberof MnfUploadNumbers
     */
    'manifestDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadNumbers
     */
    'manifestNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadNumbers
     */
    'haulNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadNumbers
     */
    'contractNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadNumbers
     */
    'author'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnfUploadNumbers
     */
    'manifestWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnfUploadNumbers
     */
    'manifestVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnfUploadNumbers
     */
    'hwBQty'?: number;
    /**
     * 
     * @type {Array<HwbId>}
     * @memberof MnfUploadNumbers
     */
    'hwb'?: Array<HwbId>;
}
/**
 * 
 * @export
 * @interface MnfUploadNumbersDialogue
 */
export interface MnfUploadNumbersDialogue {
    /**
     * 
     * @type {MnfUploadNumbers}
     * @memberof MnfUploadNumbersDialogue
     */
    'query'?: MnfUploadNumbers;
    /**
     * 
     * @type {MnfUploadResponse}
     * @memberof MnfUploadNumbersDialogue
     */
    'response'?: MnfUploadResponse;
}
/**
 * 
 * @export
 * @interface MnfUploadResponse
 */
export interface MnfUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof MnfUploadResponse
     */
    'manifestID'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadResponse
     */
    'manifestMD5'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadResponse
     */
    'manifestNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnfUploadResponse
     */
    'status'?: string;
    /**
     * 
     * @type {Array<HwbResponse>}
     * @memberof MnfUploadResponse
     */
    'hwb'?: Array<HwbResponse>;
}
/**
 * 
 * @export
 * @interface OfficeDictModel
 */
export interface OfficeDictModel {
    /**
     * 
     * @type {number}
     * @memberof OfficeDictModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'city_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'address_comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDictModel
     */
    'cityCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeDictModel
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OfficeDictModel
     */
    'cityId'?: number;
}
/**
 * 
 * @export
 * @interface OutgoingParcelCountsByTabs
 */
export interface OutgoingParcelCountsByTabs {
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelCountsByTabs
     */
    'tabNew'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelCountsByTabs
     */
    'needFix'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelCountsByTabs
     */
    'unpaid'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelCountsByTabs
     */
    'paid'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelCountsByTabs
     */
    'awaitingShipment'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelCountsByTabs
     */
    'sent'?: number;
}
/**
 * 
 * @export
 * @interface OutgoingParcelFilter
 */
export interface OutgoingParcelFilter {
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelFilter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutgoingParcelFilter
     */
    'recipientFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingParcelFilter
     */
    'creatorFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingParcelFilter
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingParcelFilter
     */
    'firstMileTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingParcelFilter
     */
    'parcelPointTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingParcelFilter
     */
    'lastMileTrack'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutgoingParcelFilter
     */
    'parcelRegistryCountryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutgoingParcelFilter
     */
    'transitCountry'?: OutgoingParcelFilterTransitCountryEnum;
}

export const OutgoingParcelFilterTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type OutgoingParcelFilterTransitCountryEnum = typeof OutgoingParcelFilterTransitCountryEnum[keyof typeof OutgoingParcelFilterTransitCountryEnum];

/**
 * 
 * @export
 * @interface PackageDimensionsModel
 */
export interface PackageDimensionsModel {
    /**
     * 
     * @type {number}
     * @memberof PackageDimensionsModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackageDimensionsModel
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackageDimensionsModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackageDimensionsModel
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoParcelReadDto
 */
export interface PageDtoParcelReadDto {
    /**
     * 
     * @type {Array<ParcelReadDto>}
     * @memberof PageDtoParcelReadDto
     */
    'items'?: Array<ParcelReadDto>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoParcelReadDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PalletDictModel
 */
export interface PalletDictModel {
    /**
     * 
     * @type {number}
     * @memberof PalletDictModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PalletDictModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PalletDictModel
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PalletDictModel
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletDictModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletDictModel
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletDictModel
     */
    'barcode'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletDictModel
     */
    'stockId'?: number;
}
/**
 * 
 * @export
 * @interface PalletModel
 */
export interface PalletModel {
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'palletId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'consignmentId'?: number;
    /**
     * 
     * @type {Array<ContainerModel>}
     * @memberof PalletModel
     */
    'containers'?: Array<ContainerModel>;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'length'?: number;
    /**
     * 
     * @type {string}
     * @memberof PalletModel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PalletModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'stockId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PalletModel
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface Parcel
 */
export interface Parcel {
    /**
     * 
     * @type {string}
     * @memberof Parcel
     */
    'trackNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parcel
     */
    'costOfDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parcel
     */
    'heightMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parcel
     */
    'widthMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parcel
     */
    'depthMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parcel
     */
    'weightGr'?: number;
    /**
     * 
     * @type {Receiver}
     * @memberof Parcel
     */
    'receiver'?: Receiver;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Parcel
     */
    'products'?: Array<Product>;
}
/**
 * 
 * @export
 * @interface ParcelAdminFilter
 */
export interface ParcelAdminFilter {
    /**
     * 
     * @type {number}
     * @memberof ParcelAdminFilter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'recipientFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'creatorFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'firstMileTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'parcelPointTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'status'?: ParcelAdminFilterStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'transitCountry'?: ParcelAdminFilterTransitCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'orderId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelAdminFilter
     */
    'countryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelAdminFilter
     */
    'stockId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'lastMileTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAdminFilter
     */
    'dateTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelAdminFilter
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelAdminFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelAdminFilter
     */
    'limit'?: number;
}

export const ParcelAdminFilterStatusEnum = {
    NotFilled: 'NOT_FILLED',
    New: 'NEW',
    Registered: 'REGISTERED',
    PreparedToSent: 'PREPARED_TO_SENT',
    CheckingConsignment: 'CHECKING_CONSIGNMENT',
    RegistryVerified: 'REGISTRY_VERIFIED',
    Sent: 'SENT',
    Received: 'RECEIVED',
    Undefined: 'UNDEFINED',
    WaitForInfo: 'WAIT_FOR_INFO',
    ReturnParcel: 'RETURN_PARCEL',
    Utilize: 'UTILIZE',
    Removed: 'REMOVED',
    History: 'HISTORY',
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    Assemble: 'ASSEMBLE',
    Consigned: 'CONSIGNED'
} as const;

export type ParcelAdminFilterStatusEnum = typeof ParcelAdminFilterStatusEnum[keyof typeof ParcelAdminFilterStatusEnum];
export const ParcelAdminFilterTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ParcelAdminFilterTransitCountryEnum = typeof ParcelAdminFilterTransitCountryEnum[keyof typeof ParcelAdminFilterTransitCountryEnum];

/**
 * 
 * @export
 * @interface ParcelAttribute
 */
export interface ParcelAttribute {
    /**
     * 
     * @type {string}
     * @memberof ParcelAttribute
     */
    'l'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAttribute
     */
    'val'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAttribute
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAttribute
     */
    'n'?: string;
}
/**
 * 
 * @export
 * @interface ParcelAutomaticErrorDto
 */
export interface ParcelAutomaticErrorDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelAutomaticErrorDto
     */
    'parcelErrors'?: Array<ParcelAutomaticErrorDtoParcelErrorsEnum>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ParcelAutomaticErrorDto
     */
    'cargoSpaceErrors'?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ParcelAutomaticErrorDto
     */
    'productErrors'?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {boolean}
     * @memberof ParcelAutomaticErrorDto
     */
    'noErrors'?: boolean;
}

export const ParcelAutomaticErrorDtoParcelErrorsEnum = {
    FirstMileTrackNotFilled: 'FIRST_MILE_TRACK_NOT_FILLED',
    DeliveryServiceNotFilled: 'DELIVERY_SERVICE_NOT_FILLED',
    AddressNotFilled: 'ADDRESS_NOT_FILLED',
    AddressCityNotFilled: 'ADDRESS_CITY_NOT_FILLED',
    AddressOfficeNotFilled: 'ADDRESS_OFFICE_NOT_FILLED',
    RecipientAddressNotFilled: 'RECIPIENT_ADDRESS_NOT_FILLED',
    RecipientNotFilled: 'RECIPIENT_NOT_FILLED',
    RecipientAuthorityNotFilled: 'RECIPIENT_AUTHORITY_NOT_FILLED',
    RecipientDateOfIssueNotFilled: 'RECIPIENT_DATE_OF_ISSUE_NOT_FILLED',
    RecipientEmailNotFilled: 'RECIPIENT_EMAIL_NOT_FILLED',
    RecipientEmailInvalid: 'RECIPIENT_EMAIL_INVALID',
    RecipientFullNameNotFilled: 'RECIPIENT_FULL_NAME_NOT_FILLED',
    RecipientPassportNumberNotFilled: 'RECIPIENT_PASSPORT_NUMBER_NOT_FILLED',
    RecipientPhoneNotFilled: 'RECIPIENT_PHONE_NOT_FILLED',
    RecipientTinNotFilled: 'RECIPIENT_TIN_NOT_FILLED'
} as const;

export type ParcelAutomaticErrorDtoParcelErrorsEnum = typeof ParcelAutomaticErrorDtoParcelErrorsEnum[keyof typeof ParcelAutomaticErrorDtoParcelErrorsEnum];
export const ParcelAutomaticErrorDtoProductErrorsEnum = {
    CostNotFilled: 'PRODUCT_COST_NOT_FILLED',
    LinkNotFilled: 'PRODUCT_LINK_NOT_FILLED',
    LinkInvalid: 'PRODUCT_LINK_INVALID',
    NameNotFilled: 'PRODUCT_NAME_NOT_FILLED',
    QuantityNotFilled: 'PRODUCT_QUANTITY_NOT_FILLED'
} as const;

export type ParcelAutomaticErrorDtoProductErrorsEnum = typeof ParcelAutomaticErrorDtoProductErrorsEnum[keyof typeof ParcelAutomaticErrorDtoProductErrorsEnum];

/**
 * 
 * @export
 * @interface ParcelCollectResponseModel
 */
export interface ParcelCollectResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ParcelCollectResponseModel
     */
    'firstBarcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelCollectResponseModel
     */
    'secondBarcode'?: string;
}
/**
 * 
 * @export
 * @interface ParcelContainerModel
 */
export interface ParcelContainerModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelContainerModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelContainerModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelContainerModel
     */
    'track'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelContainerModel
     */
    'weight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelContainerModel
     */
    'hasBattery'?: boolean;
}
/**
 * 
 * @export
 * @interface ParcelCreateDto
 */
export interface ParcelCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'type': ParcelCreateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'typeOfDelivery': ParcelCreateDtoTypeOfDeliveryEnum;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateDto
     */
    'stockId': number;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'deliveryMode': ParcelCreateDtoDeliveryModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'transitCountry': ParcelCreateDtoTransitCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelCreateDto
     */
    'hasBattery'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateDto
     */
    'costOfDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateDto
     */
    'tariffCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'wareKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateDto
     */
    'firstMileTrack'?: string;
    /**
     * 
     * @type {Array<CargoSpaceCreateDto>}
     * @memberof ParcelCreateDto
     */
    'cargoSpaces': Array<CargoSpaceCreateDto>;
    /**
     * 
     * @type {RecipientCreateDto}
     * @memberof ParcelCreateDto
     */
    'recipient'?: RecipientCreateDto;
    /**
     * 
     * @type {ParcelRecipientAddressCreateDto}
     * @memberof ParcelCreateDto
     */
    'address'?: ParcelRecipientAddressCreateDto;
}

export const ParcelCreateDtoTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelCreateDtoTypeEnum = typeof ParcelCreateDtoTypeEnum[keyof typeof ParcelCreateDtoTypeEnum];
export const ParcelCreateDtoTypeOfDeliveryEnum = {
    Delivering: 'DELIVERING',
    DropOff: 'DROP_OFF',
    BusinessUserDelivery: 'BUSINESS_USER_DELIVERY',
    None: 'NONE'
} as const;

export type ParcelCreateDtoTypeOfDeliveryEnum = typeof ParcelCreateDtoTypeOfDeliveryEnum[keyof typeof ParcelCreateDtoTypeOfDeliveryEnum];
export const ParcelCreateDtoDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type ParcelCreateDtoDeliveryModeEnum = typeof ParcelCreateDtoDeliveryModeEnum[keyof typeof ParcelCreateDtoDeliveryModeEnum];
export const ParcelCreateDtoTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ParcelCreateDtoTransitCountryEnum = typeof ParcelCreateDtoTransitCountryEnum[keyof typeof ParcelCreateDtoTransitCountryEnum];

/**
 * 
 * @export
 * @interface ParcelCreateModel
 */
export interface ParcelCreateModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'calculatorVersion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelCreateModel
     */
    'ccd'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'cost_of_investment'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'discountType'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'id'?: number;
    /**
     * 
     * @type {AddressModel}
     * @memberof ParcelCreateModel
     */
    'address'?: AddressModel;
    /**
     * 
     * @type {Array<CargoSpacesModel>}
     * @memberof ParcelCreateModel
     */
    'cargoSpaces'?: Array<CargoSpacesModel>;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'type'?: ParcelCreateModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'deliveryMode'?: ParcelCreateModelDeliveryModeEnum;
    /**
     * 
     * @type {CountryDictModel}
     * @memberof ParcelCreateModel
     */
    'country'?: CountryDictModel;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'tariffCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'costOfDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'stockId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'parcelType'?: ParcelCreateModelParcelTypeEnum;
    /**
     * 
     * @type {RecipientModel}
     * @memberof ParcelCreateModel
     */
    'recipient'?: RecipientModel;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'recipientAddress'?: string;
    /**
     * 
     * @type {OfficeDictModel}
     * @memberof ParcelCreateModel
     */
    'pvz'?: OfficeDictModel;
    /**
     * 
     * @type {number}
     * @memberof ParcelCreateModel
     */
    'pvzId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'zipCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelCreateModel
     */
    'hasBattery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParcelCreateModel
     */
    'route'?: ParcelCreateModelRouteEnum;
}

export const ParcelCreateModelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelCreateModelTypeEnum = typeof ParcelCreateModelTypeEnum[keyof typeof ParcelCreateModelTypeEnum];
export const ParcelCreateModelDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type ParcelCreateModelDeliveryModeEnum = typeof ParcelCreateModelDeliveryModeEnum[keyof typeof ParcelCreateModelDeliveryModeEnum];
export const ParcelCreateModelParcelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelCreateModelParcelTypeEnum = typeof ParcelCreateModelParcelTypeEnum[keyof typeof ParcelCreateModelParcelTypeEnum];
export const ParcelCreateModelRouteEnum = {
    Cdek: 'CDEK',
    Ruspost: 'RUSPOST',
    MshCdek: 'MSH_CDEK'
} as const;

export type ParcelCreateModelRouteEnum = typeof ParcelCreateModelRouteEnum[keyof typeof ParcelCreateModelRouteEnum];

/**
 * 
 * @export
 * @interface ParcelDetectedCarrier
 */
export interface ParcelDetectedCarrier {
    /**
     * 
     * @type {string}
     * @memberof ParcelDetectedCarrier
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDetectedCarrier
     */
    'slug'?: string;
}
/**
 * 
 * @export
 * @interface ParcelDimensions
 */
export interface ParcelDimensions {
    /**
     * 
     * @type {string}
     * @memberof ParcelDimensions
     */
    'trackNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelDimensions
     */
    'heightMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelDimensions
     */
    'widthMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelDimensions
     */
    'depthMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelDimensions
     */
    'weightGr'?: number;
}
/**
 * 
 * @export
 * @interface ParcelErrorDto
 */
export interface ParcelErrorDto {
    /**
     * 
     * @type {ParcelAutomaticErrorDto}
     * @memberof ParcelErrorDto
     */
    'automaticErrors'?: ParcelAutomaticErrorDto;
    /**
     * 
     * @type {Array<ParcelManualErrorReadDto>}
     * @memberof ParcelErrorDto
     */
    'manualErrors'?: Array<ParcelManualErrorReadDto>;
    /**
     * 
     * @type {Array<ParcelExternalErrorDto>}
     * @memberof ParcelErrorDto
     */
    'externalErrors'?: Array<ParcelExternalErrorDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelErrorDto
     */
    'noErrors'?: boolean;
}
/**
 * 
 * @export
 * @interface ParcelExtTracking
 */
export interface ParcelExtTracking {
    /**
     * 
     * @type {string}
     * @memberof ParcelExtTracking
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelExtTracking
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelExtTracking
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelExtTracking
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ParcelExternalErrorDto
 */
export interface ParcelExternalErrorDto {
    /**
     * 
     * @type {number}
     * @memberof ParcelExternalErrorDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelExternalErrorDto
     */
    'parcelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelExternalErrorDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelExternalErrorDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ParcelFromAddressModel
 */
export interface ParcelFromAddressModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelFromAddressModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelFromAddressModel
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelFromAddressModel
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelFromAddressModel
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelFromAddressModel
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelFromAddressModel
     */
    'zipCode'?: string;
    /**
     * 
     * @type {ShipEngineCarrierServiceModel}
     * @memberof ParcelFromAddressModel
     */
    'courier'?: ShipEngineCarrierServiceModel;
}
/**
 * 
 * @export
 * @interface ParcelManualErrorCreateDto
 */
export interface ParcelManualErrorCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ParcelManualErrorCreateDto
     */
    'parcelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelManualErrorCreateDto
     */
    'errorType'?: ParcelManualErrorCreateDtoErrorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelManualErrorCreateDto
     */
    'description'?: string;
}

export const ParcelManualErrorCreateDtoErrorTypeEnum = {
    WrongProductArrived: 'WRONG_PRODUCT_ARRIVED',
    BrokenGoods: 'BROKEN_GOODS',
    ProhibitedForDelivery: 'PROHIBITED_FOR_DELIVERY',
    GoodsDidNotArrived: 'GOODS_DID_NOT_ARRIVED',
    NumberOfGoodsDoesNotRespond: 'NUMBER_OF_GOODS_DOES_NOT_RESPOND',
    LinkIsInvalid: 'LINK_IS_INVALID',
    PriceIsInvalid: 'PRICE_IS_INVALID',
    Undefined: 'UNDEFINED'
} as const;

export type ParcelManualErrorCreateDtoErrorTypeEnum = typeof ParcelManualErrorCreateDtoErrorTypeEnum[keyof typeof ParcelManualErrorCreateDtoErrorTypeEnum];

/**
 * 
 * @export
 * @interface ParcelManualErrorReadDto
 */
export interface ParcelManualErrorReadDto {
    /**
     * 
     * @type {number}
     * @memberof ParcelManualErrorReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelManualErrorReadDto
     */
    'parcelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelManualErrorReadDto
     */
    'errorType'?: ParcelManualErrorReadDtoErrorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelManualErrorReadDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelManualErrorReadDto
     */
    'solved'?: boolean;
}

export const ParcelManualErrorReadDtoErrorTypeEnum = {
    WrongProductArrived: 'WRONG_PRODUCT_ARRIVED',
    BrokenGoods: 'BROKEN_GOODS',
    ProhibitedForDelivery: 'PROHIBITED_FOR_DELIVERY',
    GoodsDidNotArrived: 'GOODS_DID_NOT_ARRIVED',
    NumberOfGoodsDoesNotRespond: 'NUMBER_OF_GOODS_DOES_NOT_RESPOND',
    LinkIsInvalid: 'LINK_IS_INVALID',
    PriceIsInvalid: 'PRICE_IS_INVALID',
    Undefined: 'UNDEFINED'
} as const;

export type ParcelManualErrorReadDtoErrorTypeEnum = typeof ParcelManualErrorReadDtoErrorTypeEnum[keyof typeof ParcelManualErrorReadDtoErrorTypeEnum];

/**
 * 
 * @export
 * @interface ParcelManualErrorUpdateDto
 */
export interface ParcelManualErrorUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ParcelManualErrorUpdateDto
     */
    'errorType'?: ParcelManualErrorUpdateDtoErrorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelManualErrorUpdateDto
     */
    'description'?: string;
}

export const ParcelManualErrorUpdateDtoErrorTypeEnum = {
    WrongProductArrived: 'WRONG_PRODUCT_ARRIVED',
    BrokenGoods: 'BROKEN_GOODS',
    ProhibitedForDelivery: 'PROHIBITED_FOR_DELIVERY',
    GoodsDidNotArrived: 'GOODS_DID_NOT_ARRIVED',
    NumberOfGoodsDoesNotRespond: 'NUMBER_OF_GOODS_DOES_NOT_RESPOND',
    LinkIsInvalid: 'LINK_IS_INVALID',
    PriceIsInvalid: 'PRICE_IS_INVALID',
    Undefined: 'UNDEFINED'
} as const;

export type ParcelManualErrorUpdateDtoErrorTypeEnum = typeof ParcelManualErrorUpdateDtoErrorTypeEnum[keyof typeof ParcelManualErrorUpdateDtoErrorTypeEnum];

/**
 * 
 * @export
 * @interface ParcelModel
 */
export interface ParcelModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'partnerOrderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'cdekNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'cdekId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'trackCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'tariffCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'status'?: ParcelModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'type'?: ParcelModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'deliveryMode'?: ParcelModelDeliveryModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'typeOfDelivery'?: ParcelModelTypeOfDeliveryEnum;
    /**
     * 
     * @type {ShipEngineLabelModel}
     * @memberof ParcelModel
     */
    'shipment'?: ShipEngineLabelModel;
    /**
     * 
     * @type {Array<CargoSpacesModel>}
     * @memberof ParcelModel
     */
    'cargoSpaces'?: Array<CargoSpacesModel>;
    /**
     * 
     * @type {RecipientModel}
     * @memberof ParcelModel
     */
    'recipient'?: RecipientModel;
    /**
     * 
     * @type {AddressModel}
     * @memberof ParcelModel
     */
    'address'?: AddressModel;
    /**
     * 
     * @type {RecipientAddressModel}
     * @memberof ParcelModel
     */
    'pickupPoint'?: RecipientAddressModel;
    /**
     * 
     * @type {CommentsModel}
     * @memberof ParcelModel
     */
    'comment'?: CommentsModel;
    /**
     * 
     * @type {Array<CommentsModel>}
     * @memberof ParcelModel
     */
    'comments'?: Array<CommentsModel>;
    /**
     * 
     * @type {Array<ClientCommentsModel>}
     * @memberof ParcelModel
     */
    'clientComments'?: Array<ClientCommentsModel>;
    /**
     * 
     * @type {Array<StatusUpdatesModel>}
     * @memberof ParcelModel
     */
    'statusUpdates'?: Array<StatusUpdatesModel>;
    /**
     * 
     * @type {UserModel}
     * @memberof ParcelModel
     */
    'user'?: UserModel;
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'qr'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'label'?: string;
    /**
     * 
     * @type {ParcelFromAddressModel}
     * @memberof ParcelModel
     */
    'fromAddress'?: ParcelFromAddressModel;
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'costOfDelivery'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'externalTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'deliveryService'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'wareKey'?: string;
    /**
     * 
     * @type {ParcelErrorDto}
     * @memberof ParcelModel
     */
    'errors'?: ParcelErrorDto;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'transitCountry'?: ParcelModelTransitCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelModel
     */
    'hasBattery'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'containerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'stockId'?: number;
    /**
     * 
     * @type {StockModel}
     * @memberof ParcelModel
     */
    'stock'?: StockModel;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'place'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'seal'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelModel
     */
    'tariff_version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelModel
     */
    'route'?: ParcelModelRouteEnum;
}

export const ParcelModelStatusEnum = {
    NotFilled: 'NOT_FILLED',
    New: 'NEW',
    Registered: 'REGISTERED',
    PreparedToSent: 'PREPARED_TO_SENT',
    CheckingConsignment: 'CHECKING_CONSIGNMENT',
    RegistryVerified: 'REGISTRY_VERIFIED',
    Sent: 'SENT',
    Received: 'RECEIVED',
    Undefined: 'UNDEFINED',
    WaitForInfo: 'WAIT_FOR_INFO',
    ReturnParcel: 'RETURN_PARCEL',
    Utilize: 'UTILIZE',
    Removed: 'REMOVED',
    History: 'HISTORY',
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    Assemble: 'ASSEMBLE',
    Consigned: 'CONSIGNED'
} as const;

export type ParcelModelStatusEnum = typeof ParcelModelStatusEnum[keyof typeof ParcelModelStatusEnum];
export const ParcelModelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelModelTypeEnum = typeof ParcelModelTypeEnum[keyof typeof ParcelModelTypeEnum];
export const ParcelModelDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type ParcelModelDeliveryModeEnum = typeof ParcelModelDeliveryModeEnum[keyof typeof ParcelModelDeliveryModeEnum];
export const ParcelModelTypeOfDeliveryEnum = {
    Delivering: 'DELIVERING',
    DropOff: 'DROP_OFF',
    BusinessUserDelivery: 'BUSINESS_USER_DELIVERY',
    None: 'NONE'
} as const;

export type ParcelModelTypeOfDeliveryEnum = typeof ParcelModelTypeOfDeliveryEnum[keyof typeof ParcelModelTypeOfDeliveryEnum];
export const ParcelModelTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ParcelModelTransitCountryEnum = typeof ParcelModelTransitCountryEnum[keyof typeof ParcelModelTransitCountryEnum];
export const ParcelModelRouteEnum = {
    Cdek: 'CDEK',
    Ruspost: 'RUSPOST',
    MshCdek: 'MSH_CDEK'
} as const;

export type ParcelModelRouteEnum = typeof ParcelModelRouteEnum[keyof typeof ParcelModelRouteEnum];

/**
 * 
 * @export
 * @interface ParcelOwner
 */
export interface ParcelOwner {
    /**
     * 
     * @type {number}
     * @memberof ParcelOwner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelOwner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelOwner
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelOwner
     */
    'type'?: ParcelOwnerTypeEnum;
}

export const ParcelOwnerTypeEnum = {
    Manager: 'MANAGER',
    Client: 'CLIENT',
    Business: 'BUSINESS',
    Shopping: 'SHOPPING',
    Api: 'API',
    TelegramBot: 'TELEGRAM_BOT'
} as const;

export type ParcelOwnerTypeEnum = typeof ParcelOwnerTypeEnum[keyof typeof ParcelOwnerTypeEnum];

/**
 * 
 * @export
 * @interface ParcelQuery
 */
export interface ParcelQuery {
    /**
     * 
     * @type {string}
     * @memberof ParcelQuery
     */
    'barCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelQuery
     */
    'refBarCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelQuery
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelQuery
     */
    'parcelNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelQuery
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelQuery
     */
    'depth'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelQuery
     */
    'length'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelQuery
     */
    'description'?: string;
    /**
     * 
     * @type {object}
     * @memberof ParcelQuery
     */
    'parcelExtras'?: object;
    /**
     * 
     * @type {Array<Item>}
     * @memberof ParcelQuery
     */
    'items'?: Array<Item>;
}
/**
 * 
 * @export
 * @interface ParcelReadDto
 */
export interface ParcelReadDto {
    /**
     * 
     * @type {number}
     * @memberof ParcelReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'trackCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'orderId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelReadDto
     */
    'tariffCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'status'?: ParcelReadDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'transitCountry'?: ParcelReadDtoTransitCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'type'?: ParcelReadDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'deliveryMode'?: ParcelReadDtoDeliveryModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'typeOfDelivery'?: ParcelReadDtoTypeOfDeliveryEnum;
    /**
     * 
     * @type {number}
     * @memberof ParcelReadDto
     */
    'costOfDelivery'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'cdekId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'cdekNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'cdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'externalTrack'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelReadDto
     */
    'hasBattery'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcelReadDto
     */
    'stockId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'wareKey'?: string;
    /**
     * 
     * @type {ParcelOwner}
     * @memberof ParcelReadDto
     */
    'owner'?: ParcelOwner;
    /**
     * 
     * @type {string}
     * @memberof ParcelReadDto
     */
    'addressStoragePlace'?: string;
    /**
     * 
     * @type {RecipientReadDto}
     * @memberof ParcelReadDto
     */
    'recipient'?: RecipientReadDto;
    /**
     * 
     * @type {RecipientAddressReadDto}
     * @memberof ParcelReadDto
     */
    'recipientAddress'?: RecipientAddressReadDto;
    /**
     * 
     * @type {Array<CargoSpaceReadDto>}
     * @memberof ParcelReadDto
     */
    'cargoSpaces'?: Array<CargoSpaceReadDto>;
    /**
     * 
     * @type {ParcelErrorDto}
     * @memberof ParcelReadDto
     */
    'errors'?: ParcelErrorDto;
    /**
     * 
     * @type {Array<StatusUpdateReadDto>}
     * @memberof ParcelReadDto
     */
    'statusUpdates'?: Array<StatusUpdateReadDto>;
    /**
     * 
     * @type {Array<CommentReadDto>}
     * @memberof ParcelReadDto
     */
    'comments'?: Array<CommentReadDto>;
}

export const ParcelReadDtoStatusEnum = {
    NotFilled: 'NOT_FILLED',
    New: 'NEW',
    Registered: 'REGISTERED',
    PreparedToSent: 'PREPARED_TO_SENT',
    CheckingConsignment: 'CHECKING_CONSIGNMENT',
    RegistryVerified: 'REGISTRY_VERIFIED',
    Sent: 'SENT',
    Received: 'RECEIVED',
    Undefined: 'UNDEFINED',
    WaitForInfo: 'WAIT_FOR_INFO',
    ReturnParcel: 'RETURN_PARCEL',
    Utilize: 'UTILIZE',
    Removed: 'REMOVED',
    History: 'HISTORY',
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    Assemble: 'ASSEMBLE',
    Consigned: 'CONSIGNED'
} as const;

export type ParcelReadDtoStatusEnum = typeof ParcelReadDtoStatusEnum[keyof typeof ParcelReadDtoStatusEnum];
export const ParcelReadDtoTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ParcelReadDtoTransitCountryEnum = typeof ParcelReadDtoTransitCountryEnum[keyof typeof ParcelReadDtoTransitCountryEnum];
export const ParcelReadDtoTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelReadDtoTypeEnum = typeof ParcelReadDtoTypeEnum[keyof typeof ParcelReadDtoTypeEnum];
export const ParcelReadDtoDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type ParcelReadDtoDeliveryModeEnum = typeof ParcelReadDtoDeliveryModeEnum[keyof typeof ParcelReadDtoDeliveryModeEnum];
export const ParcelReadDtoTypeOfDeliveryEnum = {
    Delivering: 'DELIVERING',
    DropOff: 'DROP_OFF',
    BusinessUserDelivery: 'BUSINESS_USER_DELIVERY',
    None: 'NONE'
} as const;

export type ParcelReadDtoTypeOfDeliveryEnum = typeof ParcelReadDtoTypeOfDeliveryEnum[keyof typeof ParcelReadDtoTypeOfDeliveryEnum];

/**
 * 
 * @export
 * @interface ParcelRecipientAddressCreateDto
 */
export interface ParcelRecipientAddressCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ParcelRecipientAddressCreateDto
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelRecipientAddressCreateDto
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelRecipientAddressCreateDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelRecipientAddressCreateDto
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface ParcelRegistryModel
 */
export interface ParcelRegistryModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelRegistryModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelRegistryModel
     */
    'status'?: ParcelRegistryModelStatusEnum;
    /**
     * 
     * @type {IdNameModel}
     * @memberof ParcelRegistryModel
     */
    'country'?: IdNameModel;
    /**
     * 
     * @type {string}
     * @memberof ParcelRegistryModel
     */
    'jiraNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelRegistryModel
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelRegistryModel
     */
    'parcelsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelRegistryModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelRegistryModel
     */
    'sendDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelRegistryModel
     */
    'transitCountry'?: ParcelRegistryModelTransitCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelRegistryModel
     */
    'withBattery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParcelRegistryModel
     */
    'type'?: ParcelRegistryModelTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ParcelRegistryModel
     */
    'stockId'?: number;
}

export const ParcelRegistryModelStatusEnum = {
    Created: 'CREATED',
    WaitingAnswer: 'WAITING_ANSWER',
    Verified: 'VERIFIED',
    Send: 'SEND',
    Checking: 'CHECKING',
    Received: 'RECEIVED',
    Deleted: 'DELETED'
} as const;

export type ParcelRegistryModelStatusEnum = typeof ParcelRegistryModelStatusEnum[keyof typeof ParcelRegistryModelStatusEnum];
export const ParcelRegistryModelTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ParcelRegistryModelTransitCountryEnum = typeof ParcelRegistryModelTransitCountryEnum[keyof typeof ParcelRegistryModelTransitCountryEnum];
export const ParcelRegistryModelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelRegistryModelTypeEnum = typeof ParcelRegistryModelTypeEnum[keyof typeof ParcelRegistryModelTypeEnum];

/**
 * 
 * @export
 * @interface ParcelServiceModel
 */
export interface ParcelServiceModel {
    /**
     * 
     * @type {string}
     * @memberof ParcelServiceModel
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelServiceModel
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ParcelShipment
 */
export interface ParcelShipment {
    /**
     * 
     * @type {Array<ParcelState>}
     * @memberof ParcelShipment
     */
    'states'?: Array<ParcelState>;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'origin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'destination'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelShipment
     */
    'carriers'?: Array<string>;
    /**
     * 
     * @type {Array<ParcelExtTracking>}
     * @memberof ParcelShipment
     */
    'externalTracking'?: Array<ParcelExtTracking>;
    /**
     * 
     * @type {Array<ParcelServiceModel>}
     * @memberof ParcelShipment
     */
    'services'?: Array<ParcelServiceModel>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParcelShipment
     */
    'detected'?: Array<number>;
    /**
     * 
     * @type {ParcelDetectedCarrier}
     * @memberof ParcelShipment
     */
    'detectedCarrier'?: ParcelDetectedCarrier;
    /**
     * 
     * @type {number}
     * @memberof ParcelShipment
     */
    'carrier'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'checkedCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'checkedCountryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'destinationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'originCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'status'?: string;
    /**
     * 
     * @type {Array<ParcelAttribute>}
     * @memberof ParcelShipment
     */
    'attributes'?: Array<ParcelAttribute>;
    /**
     * 
     * @type {string}
     * @memberof ParcelShipment
     */
    'trackingId'?: string;
    /**
     * 
     * @type {ParcelState}
     * @memberof ParcelShipment
     */
    'lastState'?: ParcelState;
}
/**
 * 
 * @export
 * @interface ParcelShipmentData
 */
export interface ParcelShipmentData {
    /**
     * 
     * @type {string}
     * @memberof ParcelShipmentData
     */
    'uuid'?: string;
    /**
     * 
     * @type {Array<ParcelShipment>}
     * @memberof ParcelShipmentData
     */
    'shipments'?: Array<ParcelShipment>;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelShipmentData
     */
    'done'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelShipmentData
     */
    'fromCache'?: boolean;
}
/**
 * 
 * @export
 * @interface ParcelSizeModel
 */
export interface ParcelSizeModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'calculatorVersion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelSizeModel
     */
    'ccd'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'cost_of_investment'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'discountType'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelSizeModel
     */
    'weight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelSizeModel
     */
    'transitCountry'?: ParcelSizeModelTransitCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelSizeModel
     */
    'route'?: ParcelSizeModelRouteEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelSizeModel
     */
    'error'?: ParcelSizeModelErrorEnum;
}

export const ParcelSizeModelTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ParcelSizeModelTransitCountryEnum = typeof ParcelSizeModelTransitCountryEnum[keyof typeof ParcelSizeModelTransitCountryEnum];
export const ParcelSizeModelRouteEnum = {
    Cdek: 'CDEK',
    Ruspost: 'RUSPOST',
    MshCdek: 'MSH_CDEK'
} as const;

export type ParcelSizeModelRouteEnum = typeof ParcelSizeModelRouteEnum[keyof typeof ParcelSizeModelRouteEnum];
export const ParcelSizeModelErrorEnum = {
    WrongProductArrived: 'WRONG_PRODUCT_ARRIVED',
    BrokenGoods: 'BROKEN_GOODS',
    ProhibitedForDelivery: 'PROHIBITED_FOR_DELIVERY',
    GoodsDidNotArrived: 'GOODS_DID_NOT_ARRIVED',
    NumberOfGoodsDoesNotRespond: 'NUMBER_OF_GOODS_DOES_NOT_RESPOND',
    LinkIsInvalid: 'LINK_IS_INVALID',
    PriceIsInvalid: 'PRICE_IS_INVALID',
    Undefined: 'UNDEFINED'
} as const;

export type ParcelSizeModelErrorEnum = typeof ParcelSizeModelErrorEnum[keyof typeof ParcelSizeModelErrorEnum];

/**
 * 
 * @export
 * @interface ParcelState
 */
export interface ParcelState {
    /**
     * 
     * @type {string}
     * @memberof ParcelState
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelState
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelState
     */
    'carrier'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelState
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface ParcelStatusReadDto
 */
export interface ParcelStatusReadDto {
    /**
     * 
     * @type {string}
     * @memberof ParcelStatusReadDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelStatusReadDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelStatusReadDto
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelStatusReadDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelStatusReadDto
     */
    'category'?: string;
}
/**
 * 
 * @export
 * @interface ParcelStockFirstStepModel
 */
export interface ParcelStockFirstStepModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFirstStepModel
     */
    'calculatorVersion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelStockFirstStepModel
     */
    'ccd'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFirstStepModel
     */
    'cost_of_investment'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFirstStepModel
     */
    'discountType'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFirstStepModel
     */
    'id'?: number;
    /**
     * 
     * @type {AddressModel}
     * @memberof ParcelStockFirstStepModel
     */
    'address'?: AddressModel;
    /**
     * 
     * @type {Array<CargoSpacesModel>}
     * @memberof ParcelStockFirstStepModel
     */
    'cargoSpaces'?: Array<CargoSpacesModel>;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockFirstStepModel
     */
    'type'?: ParcelStockFirstStepModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockFirstStepModel
     */
    'deliveryMode'?: ParcelStockFirstStepModelDeliveryModeEnum;
    /**
     * 
     * @type {CountryDictModel}
     * @memberof ParcelStockFirstStepModel
     */
    'country'?: CountryDictModel;
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFirstStepModel
     */
    'tariffCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFirstStepModel
     */
    'costOfDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFirstStepModel
     */
    'stockId'?: number;
}

export const ParcelStockFirstStepModelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelStockFirstStepModelTypeEnum = typeof ParcelStockFirstStepModelTypeEnum[keyof typeof ParcelStockFirstStepModelTypeEnum];
export const ParcelStockFirstStepModelDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type ParcelStockFirstStepModelDeliveryModeEnum = typeof ParcelStockFirstStepModelDeliveryModeEnum[keyof typeof ParcelStockFirstStepModelDeliveryModeEnum];

/**
 * 
 * @export
 * @interface ParcelStockFourthStepModel
 */
export interface ParcelStockFourthStepModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFourthStepModel
     */
    'id'?: number;
    /**
     * 
     * @type {RecipientModel}
     * @memberof ParcelStockFourthStepModel
     */
    'recipient'?: RecipientModel;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockFourthStepModel
     */
    'address'?: string;
    /**
     * 
     * @type {OfficeDictModel}
     * @memberof ParcelStockFourthStepModel
     */
    'pvz'?: OfficeDictModel;
    /**
     * 
     * @type {number}
     * @memberof ParcelStockFourthStepModel
     */
    'pvzId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockFourthStepModel
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockFourthStepModel
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockFourthStepModel
     */
    'deliveryMode'?: ParcelStockFourthStepModelDeliveryModeEnum;
}

export const ParcelStockFourthStepModelDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type ParcelStockFourthStepModelDeliveryModeEnum = typeof ParcelStockFourthStepModelDeliveryModeEnum[keyof typeof ParcelStockFourthStepModelDeliveryModeEnum];

/**
 * 
 * @export
 * @interface ParcelStockThirdStepModel
 */
export interface ParcelStockThirdStepModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelStockThirdStepModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockThirdStepModel
     */
    'name'?: string;
    /**
     * 
     * @type {Array<CargoSpacesModel>}
     * @memberof ParcelStockThirdStepModel
     */
    'cargoSpaces'?: Array<CargoSpacesModel>;
    /**
     * 
     * @type {string}
     * @memberof ParcelStockThirdStepModel
     */
    'parcelType'?: ParcelStockThirdStepModelParcelTypeEnum;
}

export const ParcelStockThirdStepModelParcelTypeEnum = {
    Package: 'PACKAGE',
    Document: 'DOCUMENT'
} as const;

export type ParcelStockThirdStepModelParcelTypeEnum = typeof ParcelStockThirdStepModelParcelTypeEnum[keyof typeof ParcelStockThirdStepModelParcelTypeEnum];

/**
 * 
 * @export
 * @interface ParcelUndefinedModel
 */
export interface ParcelUndefinedModel {
    /**
     * 
     * @type {number}
     * @memberof ParcelUndefinedModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelUndefinedModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelUndefinedModel
     */
    'partnerOrderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelUndefinedModel
     */
    'firstMileTrack'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelUndefinedModel
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelUndefinedModel
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelUndefinedModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelUndefinedModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelUndefinedModel
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelUndefinedModel
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface ParcelUpdateDto
 */
export interface ParcelUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdateDto
     */
    'typeOfDelivery': ParcelUpdateDtoTypeOfDeliveryEnum;
    /**
     * 
     * @type {number}
     * @memberof ParcelUpdateDto
     */
    'stockId': number;
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdateDto
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdateDto
     */
    'deliveryMode': ParcelUpdateDtoDeliveryModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdateDto
     */
    'transitCountry': ParcelUpdateDtoTransitCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelUpdateDto
     */
    'hasBattery'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcelUpdateDto
     */
    'costOfDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelUpdateDto
     */
    'tariffCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdateDto
     */
    'firstMileTrack'?: string;
    /**
     * 
     * @type {Array<CargoSpaceUpdateDto>}
     * @memberof ParcelUpdateDto
     */
    'cargoSpaces': Array<CargoSpaceUpdateDto>;
    /**
     * 
     * @type {RecipientUpdateDto}
     * @memberof ParcelUpdateDto
     */
    'recipient'?: RecipientUpdateDto;
    /**
     * 
     * @type {RecipientAddressUpdateDto}
     * @memberof ParcelUpdateDto
     */
    'address'?: RecipientAddressUpdateDto;
}

export const ParcelUpdateDtoTypeOfDeliveryEnum = {
    Delivering: 'DELIVERING',
    DropOff: 'DROP_OFF',
    BusinessUserDelivery: 'BUSINESS_USER_DELIVERY',
    None: 'NONE'
} as const;

export type ParcelUpdateDtoTypeOfDeliveryEnum = typeof ParcelUpdateDtoTypeOfDeliveryEnum[keyof typeof ParcelUpdateDtoTypeOfDeliveryEnum];
export const ParcelUpdateDtoDeliveryModeEnum = {
    WarehouseDoor: 'WAREHOUSE_DOOR',
    WarehouseWarehouse: 'WAREHOUSE_WAREHOUSE',
    None: 'NONE'
} as const;

export type ParcelUpdateDtoDeliveryModeEnum = typeof ParcelUpdateDtoDeliveryModeEnum[keyof typeof ParcelUpdateDtoDeliveryModeEnum];
export const ParcelUpdateDtoTransitCountryEnum = {
    None: 'NONE',
    Dubai: 'DUBAI'
} as const;

export type ParcelUpdateDtoTransitCountryEnum = typeof ParcelUpdateDtoTransitCountryEnum[keyof typeof ParcelUpdateDtoTransitCountryEnum];

/**
 * 
 * @export
 * @interface Passport
 */
export interface Passport {
    /**
     * 
     * @type {string}
     * @memberof Passport
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Passport
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Passport
     */
    'authority'?: string;
    /**
     * 
     * @type {string}
     * @memberof Passport
     */
    'taxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Passport
     */
    'birthDate'?: string;
}
/**
 * 
 * @export
 * @interface PermissionGroup
 */
export interface PermissionGroup {
    /**
     * 
     * @type {string}
     * @memberof PermissionGroup
     */
    'screen'?: PermissionGroupScreenEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermissionGroup
     */
    'permissions'?: Array<PermissionGroupPermissionsEnum>;
}

export const PermissionGroupScreenEnum = {
    Parcels: 'PARCELS',
    AdministrationScreen: 'ADMINISTRATION_SCREEN',
    General: 'GENERAL',
    Dict: 'DICT',
    Business: 'BUSINESS',
    Transfer: 'TRANSFER'
} as const;

export type PermissionGroupScreenEnum = typeof PermissionGroupScreenEnum[keyof typeof PermissionGroupScreenEnum];
export const PermissionGroupPermissionsEnum = {
    ScanQr: 'SCAN_QR',
    AddNewParcel: 'ADD_NEW_PARCEL',
    IncomingParcels: 'INCOMING_PARCELS',
    OutgoingParcels: 'OUTGOING_PARCELS',
    EditParcelStock: 'EDIT_PARCEL_STOCK',
    ParcelManualError: 'PARCEL_MANUAL_ERROR',
    AddressStorage: 'ADDRESS_STORAGE',
    TrackNumber: 'TRACK_NUMBER',
    Reports: 'REPORTS',
    BusinessUsers: 'BUSINESS_USERS',
    BusinessInvoice: 'BUSINESS_INVOICE',
    BusinessTariff: 'BUSINESS_TARIFF',
    Consignments: 'CONSIGNMENTS',
    TransitStock: 'TRANSIT_STOCK',
    ParcelRegistry: 'PARCEL_REGISTRY',
    Users: 'USERS',
    UsersApi: 'USERS_API',
    Role: 'ROLE',
    Permission: 'PERMISSION',
    DeliveryServices: 'DELIVERY_SERVICES',
    CdekTariff: 'CDEK_TARIFF',
    Tnved: 'TNVED',
    Stock: 'STOCK',
    Support: 'SUPPORT',
    CountryDict: 'COUNTRY_DICT',
    PickupStateDict: 'PICKUP_STATE_DICT',
    PickupCityDict: 'PICKUP_CITY_DICT',
    Container: 'CONTAINER',
    Pallet: 'PALLET',
    Seal: 'SEAL',
    Place: 'PLACE',
    Logs: 'LOGS'
} as const;

export type PermissionGroupPermissionsEnum = typeof PermissionGroupPermissionsEnum[keyof typeof PermissionGroupPermissionsEnum];

/**
 * 
 * @export
 * @interface PermissionModel
 */
export interface PermissionModel {
    /**
     * 
     * @type {number}
     * @memberof PermissionModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionModel
     */
    'name'?: PermissionModelNameEnum;
    /**
     * 
     * @type {number}
     * @memberof PermissionModel
     */
    'operationPermissions'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionModel
     */
    'access'?: number;
}

export const PermissionModelNameEnum = {
    ScanQr: 'SCAN_QR',
    AddNewParcel: 'ADD_NEW_PARCEL',
    IncomingParcels: 'INCOMING_PARCELS',
    OutgoingParcels: 'OUTGOING_PARCELS',
    EditParcelStock: 'EDIT_PARCEL_STOCK',
    ParcelManualError: 'PARCEL_MANUAL_ERROR',
    AddressStorage: 'ADDRESS_STORAGE',
    TrackNumber: 'TRACK_NUMBER',
    Reports: 'REPORTS',
    BusinessUsers: 'BUSINESS_USERS',
    BusinessInvoice: 'BUSINESS_INVOICE',
    BusinessTariff: 'BUSINESS_TARIFF',
    Consignments: 'CONSIGNMENTS',
    TransitStock: 'TRANSIT_STOCK',
    ParcelRegistry: 'PARCEL_REGISTRY',
    Users: 'USERS',
    UsersApi: 'USERS_API',
    Role: 'ROLE',
    Permission: 'PERMISSION',
    DeliveryServices: 'DELIVERY_SERVICES',
    CdekTariff: 'CDEK_TARIFF',
    Tnved: 'TNVED',
    Stock: 'STOCK',
    Support: 'SUPPORT',
    CountryDict: 'COUNTRY_DICT',
    PickupStateDict: 'PICKUP_STATE_DICT',
    PickupCityDict: 'PICKUP_CITY_DICT',
    Container: 'CONTAINER',
    Pallet: 'PALLET',
    Seal: 'SEAL',
    Place: 'PLACE',
    Logs: 'LOGS'
} as const;

export type PermissionModelNameEnum = typeof PermissionModelNameEnum[keyof typeof PermissionModelNameEnum];

/**
 * 
 * @export
 * @interface PlaceModel
 */
export interface PlaceModel {
    /**
     * 
     * @type {number}
     * @memberof PlaceModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlaceModel
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'countryOfOrigin'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'hsCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'hsExportCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'barcode'?: string;
}
/**
 * 
 * @export
 * @interface ProductCreateDto
 */
export interface ProductCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'info'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    'cost': number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'packageItemId'?: string;
}
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'info'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'cargoSpaceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'registryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'packageItemId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'partnerSku'?: string;
}
/**
 * 
 * @export
 * @interface ProductReadDto
 */
export interface ProductReadDto {
    /**
     * 
     * @type {number}
     * @memberof ProductReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductReadDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReadDto
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReadDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReadDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReadDto
     */
    'info'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductReadDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductReadDto
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductReadDto
     */
    'packageItemId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductReadDto
     */
    'utilize'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductReadDto
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductReadDto
     */
    'cargoSpaceId'?: number;
}
/**
 * 
 * @export
 * @interface ProductUpdateDto
 */
export interface ProductUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'info'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    'cost': number;
}
/**
 * 
 * @export
 * @interface Receiver
 */
export interface Receiver {
    /**
     * 
     * @type {string}
     * @memberof Receiver
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Receiver
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Receiver
     */
    'email'?: string;
    /**
     * 
     * @type {ReceiverAddress}
     * @memberof Receiver
     */
    'address'?: ReceiverAddress;
    /**
     * 
     * @type {Passport}
     * @memberof Receiver
     */
    'passport'?: Passport;
}
/**
 * 
 * @export
 * @interface ReceiverAddress
 */
export interface ReceiverAddress {
    /**
     * 
     * @type {string}
     * @memberof ReceiverAddress
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverAddress
     */
    'regionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverAddress
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverAddress
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverAddress
     */
    'building'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverAddress
     */
    'officeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReceiverAddress
     */
    'zip'?: number;
}
/**
 * 
 * @export
 * @interface ReceiverQuery
 */
export interface ReceiverQuery {
    /**
     * 
     * @type {string}
     * @memberof ReceiverQuery
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverQuery
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverQuery
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverQuery
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverQuery
     */
    'addressRB'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverQuery
     */
    'addressCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiverQuery
     */
    'receiverCode'?: string;
    /**
     * 
     * @type {Array<ExtraContact>}
     * @memberof ReceiverQuery
     */
    'extraContact'?: Array<ExtraContact>;
    /**
     * 
     * @type {Address}
     * @memberof ReceiverQuery
     */
    'receiverAddress'?: Address;
    /**
     * 
     * @type {Credential}
     * @memberof ReceiverQuery
     */
    'personalData'?: Credential;
}
/**
 * 
 * @export
 * @interface RecipientAddressModel
 */
export interface RecipientAddressModel {
    /**
     * 
     * @type {number}
     * @memberof RecipientAddressModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecipientAddressModel
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressModel
     */
    'zipCode'?: string;
    /**
     * 
     * @type {CityDict}
     * @memberof RecipientAddressModel
     */
    'city'?: CityDict;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressModel
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressModel
     */
    'code'?: string;
    /**
     * 
     * @type {CountryDictModel}
     * @memberof RecipientAddressModel
     */
    'country'?: CountryDictModel;
    /**
     * 
     * @type {CdekPvzModel}
     * @memberof RecipientAddressModel
     */
    'pvz'?: CdekPvzModel;
}
/**
 * 
 * @export
 * @interface RecipientAddressReadDto
 */
export interface RecipientAddressReadDto {
    /**
     * 
     * @type {number}
     * @memberof RecipientAddressReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressReadDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressReadDto
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressReadDto
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressReadDto
     */
    'cityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressReadDto
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressReadDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressReadDto
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface RecipientAddressUpdateDto
 */
export interface RecipientAddressUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressUpdateDto
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressUpdateDto
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressUpdateDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientAddressUpdateDto
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface RecipientCreateDto
 */
export interface RecipientCreateDto {
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'passportNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'passportSerial'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'authority'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'dateOfIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientCreateDto
     */
    'dateOfBirth'?: string;
}
/**
 * 
 * @export
 * @interface RecipientModel
 */
export interface RecipientModel {
    /**
     * 
     * @type {number}
     * @memberof RecipientModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecipientModel
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'passportNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'passportSerial'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'authority'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'dateOfIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientModel
     */
    'dateOfBirth'?: string;
}
/**
 * 
 * @export
 * @interface RecipientReadDto
 */
export interface RecipientReadDto {
    /**
     * 
     * @type {number}
     * @memberof RecipientReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'passportNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'passportSerial'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'authority'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'dateOfIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientReadDto
     */
    'dateOfBirth'?: string;
}
/**
 * 
 * @export
 * @interface RecipientUpdateDto
 */
export interface RecipientUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'passportNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'passportSerial'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'authority'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'dateOfIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateDto
     */
    'dateOfBirth'?: string;
}
/**
 * 
 * @export
 * @interface RegionDict
 */
export interface RegionDict {
    /**
     * 
     * @type {number}
     * @memberof RegionDict
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionDict
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionDict
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionDict
     */
    'countryCode'?: string;
    /**
     * 
     * @type {CountryDict}
     * @memberof RegionDict
     */
    'country'?: CountryDict;
    /**
     * 
     * @type {string}
     * @memberof RegionDict
     */
    'lang'?: RegionDictLangEnum;
}

export const RegionDictLangEnum = {
    Ru: 'RU',
    En: 'EN'
} as const;

export type RegionDictLangEnum = typeof RegionDictLangEnum[keyof typeof RegionDictLangEnum];

/**
 * 
 * @export
 * @interface RegionDictModel
 */
export interface RegionDictModel {
    /**
     * 
     * @type {number}
     * @memberof RegionDictModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionDictModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionDictModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionDictModel
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionDictModel
     */
    'lang'?: RegionDictModelLangEnum;
}

export const RegionDictModelLangEnum = {
    Ru: 'RU',
    En: 'EN'
} as const;

export type RegionDictModelLangEnum = typeof RegionDictModelLangEnum[keyof typeof RegionDictModelLangEnum];

/**
 * 
 * @export
 * @interface RegisterModel
 */
export interface RegisterModel {
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface RegistryExcelResponseModel
 */
export interface RegistryExcelResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RegistryExcelResponseModel
     */
    'file'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistryExcelResponseModel
     */
    'notFound'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReportParamsRoot
 */
export interface ReportParamsRoot {
    /**
     * 
     * @type {ExportParcelsByParcelRegistryReportParams}
     * @memberof ReportParamsRoot
     */
    'exportParcelsByParcelRegistry'?: ExportParcelsByParcelRegistryReportParams;
}
/**
 * 
 * @export
 * @interface ReportQueueCreateDto
 */
export interface ReportQueueCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ReportQueueCreateDto
     */
    'reportType'?: ReportQueueCreateDtoReportTypeEnum;
    /**
     * 
     * @type {ReportParamsRoot}
     * @memberof ReportQueueCreateDto
     */
    'params'?: ReportParamsRoot;
}

export const ReportQueueCreateDtoReportTypeEnum = {
    ExportParcelsByParcelRegistry: 'EXPORT_PARCELS_BY_PARCEL_REGISTRY'
} as const;

export type ReportQueueCreateDtoReportTypeEnum = typeof ReportQueueCreateDtoReportTypeEnum[keyof typeof ReportQueueCreateDtoReportTypeEnum];

/**
 * 
 * @export
 * @interface ReportQueueReadDto
 */
export interface ReportQueueReadDto {
    /**
     * 
     * @type {number}
     * @memberof ReportQueueReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportQueueReadDto
     */
    'reportType'?: ReportQueueReadDtoReportTypeEnum;
    /**
     * 
     * @type {ReportParamsRoot}
     * @memberof ReportQueueReadDto
     */
    'params'?: ReportParamsRoot;
    /**
     * 
     * @type {string}
     * @memberof ReportQueueReadDto
     */
    'state'?: ReportQueueReadDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportQueueReadDto
     */
    'username'?: string;
}

export const ReportQueueReadDtoReportTypeEnum = {
    ExportParcelsByParcelRegistry: 'EXPORT_PARCELS_BY_PARCEL_REGISTRY'
} as const;

export type ReportQueueReadDtoReportTypeEnum = typeof ReportQueueReadDtoReportTypeEnum[keyof typeof ReportQueueReadDtoReportTypeEnum];
export const ReportQueueReadDtoStateEnum = {
    New: 'NEW',
    InProgress: 'IN_PROGRESS',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;

export type ReportQueueReadDtoStateEnum = typeof ReportQueueReadDtoStateEnum[keyof typeof ReportQueueReadDtoStateEnum];

/**
 * 
 * @export
 * @interface RestorePasswordModel
 */
export interface RestorePasswordModel {
    /**
     * 
     * @type {string}
     * @memberof RestorePasswordModel
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestorePasswordModel
     */
    'verifyPassword'?: string;
}
/**
 * 
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     * 
     * @type {number}
     * @memberof RoleModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleModel
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleModel
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<PermissionModel>}
     * @memberof RoleModel
     */
    'permissions'?: Array<PermissionModel>;
}
/**
 * 
 * @export
 * @interface SearchModel
 */
export interface SearchModel {
    /**
     * 
     * @type {Array<ParcelModel>}
     * @memberof SearchModel
     */
    'incoming'?: Array<ParcelModel>;
    /**
     * 
     * @type {Array<ParcelModel>}
     * @memberof SearchModel
     */
    'outgoing'?: Array<ParcelModel>;
    /**
     * 
     * @type {Array<UserModel>}
     * @memberof SearchModel
     */
    'users'?: Array<UserModel>;
}
/**
 * 
 * @export
 * @interface SenderQuery
 */
export interface SenderQuery {
    /**
     * 
     * @type {string}
     * @memberof SenderQuery
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SenderQuery
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SenderQuery
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SenderQuery
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SenderQuery
     */
    'addressRB'?: string;
    /**
     * 
     * @type {string}
     * @memberof SenderQuery
     */
    'addressCode'?: string;
    /**
     * 
     * @type {Array<ExtraContact>}
     * @memberof SenderQuery
     */
    'extraContact'?: Array<ExtraContact>;
    /**
     * 
     * @type {Address}
     * @memberof SenderQuery
     */
    'senderAddress'?: Address;
}
/**
 * 
 * @export
 * @interface ShipEngineCarrierServiceModel
 */
export interface ShipEngineCarrierServiceModel {
    /**
     * 
     * @type {number}
     * @memberof ShipEngineCarrierServiceModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineCarrierServiceModel
     */
    'carrier_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineCarrierServiceModel
     */
    'carrier_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineCarrierServiceModel
     */
    'service_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineCarrierServiceModel
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipEngineCarrierServiceModel
     */
    'domestic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipEngineCarrierServiceModel
     */
    'international'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipEngineCarrierServiceModel
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface ShipEngineCostModel
 */
export interface ShipEngineCostModel {
    /**
     * 
     * @type {string}
     * @memberof ShipEngineCostModel
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipEngineCostModel
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface ShipEngineLabelDownloadModel
 */
export interface ShipEngineLabelDownloadModel {
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelDownloadModel
     */
    'pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelDownloadModel
     */
    'png'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelDownloadModel
     */
    'zpl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelDownloadModel
     */
    'href'?: string;
}
/**
 * 
 * @export
 * @interface ShipEngineLabelModel
 */
export interface ShipEngineLabelModel {
    /**
     * 
     * @type {number}
     * @memberof ShipEngineLabelModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelModel
     */
    'label_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelModel
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelModel
     */
    'shipment_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelModel
     */
    'service_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipEngineLabelModel
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {ShipEngineCostModel}
     * @memberof ShipEngineLabelModel
     */
    'shipment_cost'?: ShipEngineCostModel;
    /**
     * 
     * @type {ShipEngineLabelDownloadModel}
     * @memberof ShipEngineLabelModel
     */
    'label_download'?: ShipEngineLabelDownloadModel;
    /**
     * 
     * @type {number}
     * @memberof ShipEngineLabelModel
     */
    'parcelId'?: number;
}
/**
 * 
 * @export
 * @interface StatusUpdateReadDto
 */
export interface StatusUpdateReadDto {
    /**
     * 
     * @type {number}
     * @memberof StatusUpdateReadDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusUpdateReadDto
     */
    'parcelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusUpdateReadDto
     */
    'status'?: StatusUpdateReadDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StatusUpdateReadDto
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusUpdateReadDto
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusUpdateReadDto
     */
    'userEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusUpdateReadDto
     */
    'userFullName'?: string;
}

export const StatusUpdateReadDtoStatusEnum = {
    NotFilled: 'NOT_FILLED',
    New: 'NEW',
    Registered: 'REGISTERED',
    PreparedToSent: 'PREPARED_TO_SENT',
    CheckingConsignment: 'CHECKING_CONSIGNMENT',
    RegistryVerified: 'REGISTRY_VERIFIED',
    Sent: 'SENT',
    Received: 'RECEIVED',
    Undefined: 'UNDEFINED',
    WaitForInfo: 'WAIT_FOR_INFO',
    ReturnParcel: 'RETURN_PARCEL',
    Utilize: 'UTILIZE',
    Removed: 'REMOVED',
    History: 'HISTORY',
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    Assemble: 'ASSEMBLE',
    Consigned: 'CONSIGNED'
} as const;

export type StatusUpdateReadDtoStatusEnum = typeof StatusUpdateReadDtoStatusEnum[keyof typeof StatusUpdateReadDtoStatusEnum];

/**
 * 
 * @export
 * @interface StatusUpdatesModel
 */
export interface StatusUpdatesModel {
    /**
     * 
     * @type {number}
     * @memberof StatusUpdatesModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusUpdatesModel
     */
    'status'?: StatusUpdatesModelStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof StatusUpdatesModel
     */
    'parcelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusUpdatesModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusUpdatesModel
     */
    'user_id'?: number;
}

export const StatusUpdatesModelStatusEnum = {
    NotFilled: 'NOT_FILLED',
    New: 'NEW',
    Registered: 'REGISTERED',
    PreparedToSent: 'PREPARED_TO_SENT',
    CheckingConsignment: 'CHECKING_CONSIGNMENT',
    RegistryVerified: 'REGISTRY_VERIFIED',
    Sent: 'SENT',
    Received: 'RECEIVED',
    Undefined: 'UNDEFINED',
    WaitForInfo: 'WAIT_FOR_INFO',
    ReturnParcel: 'RETURN_PARCEL',
    Utilize: 'UTILIZE',
    Removed: 'REMOVED',
    History: 'HISTORY',
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    Assemble: 'ASSEMBLE',
    Consigned: 'CONSIGNED'
} as const;

export type StatusUpdatesModelStatusEnum = typeof StatusUpdatesModelStatusEnum[keyof typeof StatusUpdatesModelStatusEnum];

/**
 * 
 * @export
 * @interface StockModel
 */
export interface StockModel {
    /**
     * 
     * @type {number}
     * @memberof StockModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'subAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'regionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'cityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockModel
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockModel
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockModel
     */
    'subRegion'?: string;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface TrackHistoryModel
 */
export interface TrackHistoryModel {
    /**
     * 
     * @type {string}
     * @memberof TrackHistoryModel
     */
    'status_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackHistoryModel
     */
    'status_details'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackHistoryModel
     */
    'object_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackHistoryModel
     */
    'object_updated'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackHistoryModel
     */
    'object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackHistoryModel
     */
    'status'?: string;
    /**
     * 
     * @type {TrackLocationModel}
     * @memberof TrackHistoryModel
     */
    'location'?: TrackLocationModel;
    /**
     * 
     * @type {TrackSubStatusModel}
     * @memberof TrackHistoryModel
     */
    'substatus'?: TrackSubStatusModel;
}
/**
 * 
 * @export
 * @interface TrackLocationModel
 */
export interface TrackLocationModel {
    /**
     * 
     * @type {string}
     * @memberof TrackLocationModel
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackLocationModel
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackLocationModel
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackLocationModel
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface TrackResponseModel
 */
export interface TrackResponseModel {
    /**
     * 
     * @type {number}
     * @memberof TrackResponseModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrackResponseModel
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackResponseModel
     */
    'carrier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackResponseModel
     */
    'transaction'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackResponseModel
     */
    'address_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackResponseModel
     */
    'address_to'?: string;
    /**
     * 
     * @type {TrackStatusModel}
     * @memberof TrackResponseModel
     */
    'tracking_status'?: TrackStatusModel;
    /**
     * 
     * @type {Array<TrackHistoryModel>}
     * @memberof TrackResponseModel
     */
    'tracking_history'?: Array<TrackHistoryModel>;
}
/**
 * 
 * @export
 * @interface TrackStatusModel
 */
export interface TrackStatusModel {
    /**
     * 
     * @type {string}
     * @memberof TrackStatusModel
     */
    'status_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackStatusModel
     */
    'status_details'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackStatusModel
     */
    'object_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackStatusModel
     */
    'object_updated'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackStatusModel
     */
    'object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackStatusModel
     */
    'status'?: string;
    /**
     * 
     * @type {TrackLocationModel}
     * @memberof TrackStatusModel
     */
    'location'?: TrackLocationModel;
    /**
     * 
     * @type {TrackSubStatusModel}
     * @memberof TrackStatusModel
     */
    'substatus'?: TrackSubStatusModel;
}
/**
 * 
 * @export
 * @interface TrackSubStatusModel
 */
export interface TrackSubStatusModel {
    /**
     * 
     * @type {string}
     * @memberof TrackSubStatusModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackSubStatusModel
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackSubStatusModel
     */
    'action_required'?: string;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'cdt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'lastActivity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'phoneNum'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'userType'?: UserModelUserTypeEnum;
    /**
     * 
     * @type {Set<RoleModel>}
     * @memberof UserModel
     */
    'roles'?: Set<RoleModel>;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    'blocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'paymentId'?: string;
    /**
     * 
     * @type {Array<StockModel>}
     * @memberof UserModel
     */
    'stocks'?: Array<StockModel>;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    'selectedStockId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    'negativeBalanceEnabled'?: boolean;
}

export const UserModelUserTypeEnum = {
    Manager: 'MANAGER',
    Client: 'CLIENT',
    Business: 'BUSINESS',
    Shopping: 'SHOPPING',
    Api: 'API',
    TelegramBot: 'TELEGRAM_BOT'
} as const;

export type UserModelUserTypeEnum = typeof UserModelUserTypeEnum[keyof typeof UserModelUserTypeEnum];

/**
 * 
 * @export
 * @interface ValidateRegistryModel
 */
export interface ValidateRegistryModel {
    /**
     * 
     * @type {string}
     * @memberof ValidateRegistryModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateRegistryModel
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateRegistryModel
     */
    'value'?: string;
    /**
     * 
     * @type {ProductModel}
     * @memberof ValidateRegistryModel
     */
    'product'?: ProductModel;
}
/**
 * 
 * @export
 * @interface ValidatedRegistryModel
 */
export interface ValidatedRegistryModel {
    /**
     * 
     * @type {number}
     * @memberof ValidatedRegistryModel
     */
    'id'?: number;
    /**
     * 
     * @type {Array<ValidateRegistryModel>}
     * @memberof ValidatedRegistryModel
     */
    'errors'?: Array<ValidateRegistryModel>;
    /**
     * 
     * @type {Array<ValidateRegistryModel>}
     * @memberof ValidatedRegistryModel
     */
    'warnings'?: Array<ValidateRegistryModel>;
    /**
     * 
     * @type {Array<ValidateRegistryModel>}
     * @memberof ValidatedRegistryModel
     */
    'valid'?: Array<ValidateRegistryModel>;
}

/**
 * AddNewParcelControllerApi - axios parameter creator
 * @export
 */
export const AddNewParcelControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ParcelCreateModel} parcelCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create5: async (parcelCreateModel: ParcelCreateModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelCreateModel' is not null or undefined
            assertParamExists('create5', 'parcelCreateModel', parcelCreateModel)
            const localVarPath = `/api/add/new/parcels/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParcel: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createParcel', 'id', id)
            const localVarPath = `/api/add/new/parcels/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CostFilterModel} costFilterModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCost: async (costFilterModel: CostFilterModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'costFilterModel' is not null or undefined
            assertParamExists('getCost', 'costFilterModel', costFilterModel)
            const localVarPath = `/api/add/new/parcels/cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costFilterModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/add/new/parcels/counties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstStep: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFirstStep', 'id', id)
            const localVarPath = `/api/add/new/parcels/first/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourthStep: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFourthStep', 'id', id)
            const localVarPath = `/api/add/new/parcels/fourth/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions: async (countryCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('getRegions', 'countryCode', countryCode)
            const localVarPath = `/api/add/new/parcels/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecondStep: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSecondStep', 'id', id)
            const localVarPath = `/api/add/new/parcels/second/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThirdStep: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getThirdStep', 'id', id)
            const localVarPath = `/api/add/new/parcels/third/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        liatChecking: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/add/new/parcels/not/filled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllClientsMin1: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/add/new/parcels/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} regionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCities1: async (regionCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionCode' is not null or undefined
            assertParamExists('listCities1', 'regionCode', regionCode)
            const localVarPath = `/api/add/new/parcels/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (regionCode !== undefined) {
                localVarQueryParameter['regionCode'] = regionCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOffices: async (cityCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityCode' is not null or undefined
            assertParamExists('listOffices', 'cityCode', cityCode)
            const localVarPath = `/api/add/new/parcels/offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cityCode !== undefined) {
                localVarQueryParameter['cityCode'] = cityCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeParcel: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeParcel', 'id', id)
            const localVarPath = `/api/add/new/parcels/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelStockFirstStepModel} parcelStockFirstStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFirstStep: async (parcelStockFirstStepModel: ParcelStockFirstStepModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelStockFirstStepModel' is not null or undefined
            assertParamExists('saveFirstStep', 'parcelStockFirstStepModel', parcelStockFirstStepModel)
            const localVarPath = `/api/add/new/parcels/first/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelStockFirstStepModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelStockFourthStepModel} parcelStockFourthStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFourthStep: async (parcelStockFourthStepModel: ParcelStockFourthStepModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelStockFourthStepModel' is not null or undefined
            assertParamExists('saveFourthStep', 'parcelStockFourthStepModel', parcelStockFourthStepModel)
            const localVarPath = `/api/add/new/parcels/fourth/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelStockFourthStepModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSecondStep: async (id: number, userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveSecondStep', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('saveSecondStep', 'userId', userId)
            const localVarPath = `/api/add/new/parcels/second/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelStockThirdStepModel} parcelStockThirdStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveThirdStep: async (parcelStockThirdStepModel: ParcelStockThirdStepModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelStockThirdStepModel' is not null or undefined
            assertParamExists('saveThirdStep', 'parcelStockThirdStepModel', parcelStockThirdStepModel)
            const localVarPath = `/api/add/new/parcels/third/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelStockThirdStepModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCity1: async (name: string, countryCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('searchCity1', 'name', name)
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('searchCity1', 'countryCode', countryCode)
            const localVarPath = `/api/add/new/parcels/search/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} verificationCode 
         * @param {RegisterModel} registerModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyClient: async (verificationCode: string, registerModel: RegisterModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verificationCode' is not null or undefined
            assertParamExists('verifyClient', 'verificationCode', verificationCode)
            // verify required parameter 'registerModel' is not null or undefined
            assertParamExists('verifyClient', 'registerModel', registerModel)
            const localVarPath = `/api/add/new/parcels/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verificationCode !== undefined) {
                localVarQueryParameter['verificationCode'] = verificationCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddNewParcelControllerApi - functional programming interface
 * @export
 */
export const AddNewParcelControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddNewParcelControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ParcelCreateModel} parcelCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create5(parcelCreateModel: ParcelCreateModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create5(parcelCreateModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.create5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParcel(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParcel(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.createParcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CostFilterModel} costFilterModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCost(costFilterModel: CostFilterModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCost(costFilterModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.getCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.getCountries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstStep(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelStockFirstStepModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFirstStep(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.getFirstStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFourthStep(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelStockFourthStepModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFourthStep(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.getFourthStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegions(countryCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegionDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(countryCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.getRegions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecondStep(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecondStep(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.getSecondStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThirdStep(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelStockThirdStepModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThirdStep(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.getThirdStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async liatChecking(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParcelModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.liatChecking(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.liatChecking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllClientsMin1(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdNameModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllClientsMin1(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.listAllClientsMin1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} regionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCities1(regionCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCities1(regionCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.listCities1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOffices(cityCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficeDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOffices(cityCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.listOffices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeParcel(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeParcel(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.removeParcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelStockFirstStepModel} parcelStockFirstStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFirstStep(parcelStockFirstStepModel: ParcelStockFirstStepModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFirstStep(parcelStockFirstStepModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.saveFirstStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelStockFourthStepModel} parcelStockFourthStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFourthStep(parcelStockFourthStepModel: ParcelStockFourthStepModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFourthStep(parcelStockFourthStepModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.saveFourthStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSecondStep(id: number, userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSecondStep(id, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.saveSecondStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelStockThirdStepModel} parcelStockThirdStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveThirdStep(parcelStockThirdStepModel: ParcelStockThirdStepModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveThirdStep(parcelStockThirdStepModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.saveThirdStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCity1(name: string, countryCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCity1(name, countryCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.searchCity1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} verificationCode 
         * @param {RegisterModel} registerModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyClient(verificationCode: string, registerModel: RegisterModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyClient(verificationCode, registerModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddNewParcelControllerApi.verifyClient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AddNewParcelControllerApi - factory interface
 * @export
 */
export const AddNewParcelControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddNewParcelControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ParcelCreateModel} parcelCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create5(parcelCreateModel: ParcelCreateModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.create5(parcelCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParcel(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createParcel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CostFilterModel} costFilterModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCost(costFilterModel: CostFilterModel, options?: RawAxiosRequestConfig): AxiosPromise<CostModel> {
            return localVarFp.getCost(costFilterModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: RawAxiosRequestConfig): AxiosPromise<Array<CountryDictModel>> {
            return localVarFp.getCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstStep(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ParcelStockFirstStepModel> {
            return localVarFp.getFirstStep(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourthStep(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ParcelStockFourthStepModel> {
            return localVarFp.getFourthStep(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(countryCode: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<RegionDictModel>> {
            return localVarFp.getRegions(countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecondStep(id: number, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getSecondStep(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThirdStep(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ParcelStockThirdStepModel> {
            return localVarFp.getThirdStep(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        liatChecking(options?: RawAxiosRequestConfig): AxiosPromise<Array<ParcelModel>> {
            return localVarFp.liatChecking(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllClientsMin1(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<IdNameModel>> {
            return localVarFp.listAllClientsMin1(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} regionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCities1(regionCode: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CityDictModel>> {
            return localVarFp.listCities1(regionCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOffices(cityCode: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<OfficeDictModel>> {
            return localVarFp.listOffices(cityCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeParcel(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeParcel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelStockFirstStepModel} parcelStockFirstStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFirstStep(parcelStockFirstStepModel: ParcelStockFirstStepModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.saveFirstStep(parcelStockFirstStepModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelStockFourthStepModel} parcelStockFourthStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFourthStep(parcelStockFourthStepModel: ParcelStockFourthStepModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.saveFourthStep(parcelStockFourthStepModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSecondStep(id: number, userId: number, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.saveSecondStep(id, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelStockThirdStepModel} parcelStockThirdStepModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveThirdStep(parcelStockThirdStepModel: ParcelStockThirdStepModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.saveThirdStep(parcelStockThirdStepModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCity1(name: string, countryCode: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CityDictModel>> {
            return localVarFp.searchCity1(name, countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} verificationCode 
         * @param {RegisterModel} registerModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyClient(verificationCode: string, registerModel: RegisterModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.verifyClient(verificationCode, registerModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddNewParcelControllerApi - object-oriented interface
 * @export
 * @class AddNewParcelControllerApi
 * @extends {BaseAPI}
 */
export class AddNewParcelControllerApi extends BaseAPI {
    /**
     * 
     * @param {ParcelCreateModel} parcelCreateModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public create5(parcelCreateModel: ParcelCreateModel, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).create5(parcelCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public createParcel(id: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).createParcel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CostFilterModel} costFilterModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public getCost(costFilterModel: CostFilterModel, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).getCost(costFilterModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public getCountries(options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public getFirstStep(id: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).getFirstStep(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public getFourthStep(id: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).getFourthStep(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public getRegions(countryCode: string, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).getRegions(countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public getSecondStep(id: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).getSecondStep(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public getThirdStep(id: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).getThirdStep(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public liatChecking(options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).liatChecking(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public listAllClientsMin1(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).listAllClientsMin1(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} regionCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public listCities1(regionCode: string, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).listCities1(regionCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cityCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public listOffices(cityCode: string, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).listOffices(cityCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public removeParcel(id: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).removeParcel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelStockFirstStepModel} parcelStockFirstStepModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public saveFirstStep(parcelStockFirstStepModel: ParcelStockFirstStepModel, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).saveFirstStep(parcelStockFirstStepModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelStockFourthStepModel} parcelStockFourthStepModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public saveFourthStep(parcelStockFourthStepModel: ParcelStockFourthStepModel, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).saveFourthStep(parcelStockFourthStepModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public saveSecondStep(id: number, userId: number, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).saveSecondStep(id, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelStockThirdStepModel} parcelStockThirdStepModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public saveThirdStep(parcelStockThirdStepModel: ParcelStockThirdStepModel, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).saveThirdStep(parcelStockThirdStepModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public searchCity1(name: string, countryCode: string, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).searchCity1(name, countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} verificationCode 
     * @param {RegisterModel} registerModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddNewParcelControllerApi
     */
    public verifyClient(verificationCode: string, registerModel: RegisterModel, options?: RawAxiosRequestConfig) {
        return AddNewParcelControllerApiFp(this.configuration).verifyClient(verificationCode, registerModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AddressStorageControllerApi - axios parameter creator
 * @export
 */
export const AddressStorageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete13: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete13', 'id', id)
            const localVarPath = `/api/address/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list5: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/address/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncoming: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/address/storage/incoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutgoing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/address/storage/outgoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressStorageModel} addressStorageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save14: async (addressStorageModel: AddressStorageModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressStorageModel' is not null or undefined
            assertParamExists('save14', 'addressStorageModel', addressStorageModel)
            const localVarPath = `/api/address/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressStorageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressStorageControllerApi - functional programming interface
 * @export
 */
export const AddressStorageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressStorageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete13(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete13(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressStorageControllerApi.delete13']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list5(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressStorageModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list5(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressStorageControllerApi.list5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIncoming(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressStorageModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIncoming(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressStorageControllerApi.listIncoming']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOutgoing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressStorageModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOutgoing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressStorageControllerApi.listOutgoing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddressStorageModel} addressStorageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save14(addressStorageModel: AddressStorageModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save14(addressStorageModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressStorageControllerApi.save14']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AddressStorageControllerApi - factory interface
 * @export
 */
export const AddressStorageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressStorageControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete13(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete13(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list5(options?: RawAxiosRequestConfig): AxiosPromise<Array<AddressStorageModel>> {
            return localVarFp.list5(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncoming(options?: RawAxiosRequestConfig): AxiosPromise<Array<AddressStorageModel>> {
            return localVarFp.listIncoming(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutgoing(options?: RawAxiosRequestConfig): AxiosPromise<Array<AddressStorageModel>> {
            return localVarFp.listOutgoing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressStorageModel} addressStorageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save14(addressStorageModel: AddressStorageModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save14(addressStorageModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressStorageControllerApi - object-oriented interface
 * @export
 * @class AddressStorageControllerApi
 * @extends {BaseAPI}
 */
export class AddressStorageControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressStorageControllerApi
     */
    public delete13(id: number, options?: RawAxiosRequestConfig) {
        return AddressStorageControllerApiFp(this.configuration).delete13(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressStorageControllerApi
     */
    public list5(options?: RawAxiosRequestConfig) {
        return AddressStorageControllerApiFp(this.configuration).list5(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressStorageControllerApi
     */
    public listIncoming(options?: RawAxiosRequestConfig) {
        return AddressStorageControllerApiFp(this.configuration).listIncoming(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressStorageControllerApi
     */
    public listOutgoing(options?: RawAxiosRequestConfig) {
        return AddressStorageControllerApiFp(this.configuration).listOutgoing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressStorageModel} addressStorageModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressStorageControllerApi
     */
    public save14(addressStorageModel: AddressStorageModel, options?: RawAxiosRequestConfig) {
        return AddressStorageControllerApiFp(this.configuration).save14(addressStorageModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthControllerRestApi - axios parameter creator
 * @export
 */
export const AuthControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginModel} loginModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginModel: LoginModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginModel' is not null or undefined
            assertParamExists('login', 'loginModel', loginModel)
            const localVarPath = `/api/public/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} secret 
         * @param {RestorePasswordModel} restorePasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePassword: async (secret: string, restorePasswordModel: RestorePasswordModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secret' is not null or undefined
            assertParamExists('restorePassword', 'secret', secret)
            // verify required parameter 'restorePasswordModel' is not null or undefined
            assertParamExists('restorePassword', 'restorePasswordModel', restorePasswordModel)
            const localVarPath = `/api/restore/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (secret !== undefined) {
                localVarQueryParameter['secret'] = secret;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restorePasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} secret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPasswordRestore: async (secret: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secret' is not null or undefined
            assertParamExists('verifyPasswordRestore', 'secret', secret)
            const localVarPath = `/api/verify/password/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (secret !== undefined) {
                localVarQueryParameter['secret'] = secret;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerRestApi - functional programming interface
 * @export
 */
export const AuthControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginModel} loginModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginModel: LoginModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerRestApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} secret 
         * @param {RestorePasswordModel} restorePasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restorePassword(secret: string, restorePasswordModel: RestorePasswordModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restorePassword(secret, restorePasswordModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerRestApi.restorePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} secret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPasswordRestore(secret: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPasswordRestore(secret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerRestApi.verifyPasswordRestore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthControllerRestApi - factory interface
 * @export
 */
export const AuthControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginModel} loginModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginModel: LoginModel, options?: RawAxiosRequestConfig): AxiosPromise<Token> {
            return localVarFp.login(loginModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} secret 
         * @param {RestorePasswordModel} restorePasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePassword(secret: string, restorePasswordModel: RestorePasswordModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.restorePassword(secret, restorePasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} secret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPasswordRestore(secret: string, options?: RawAxiosRequestConfig): AxiosPromise<UserModel> {
            return localVarFp.verifyPasswordRestore(secret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerRestApi - object-oriented interface
 * @export
 * @class AuthControllerRestApi
 * @extends {BaseAPI}
 */
export class AuthControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {LoginModel} loginModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerRestApi
     */
    public login(loginModel: LoginModel, options?: RawAxiosRequestConfig) {
        return AuthControllerRestApiFp(this.configuration).login(loginModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} secret 
     * @param {RestorePasswordModel} restorePasswordModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerRestApi
     */
    public restorePassword(secret: string, restorePasswordModel: RestorePasswordModel, options?: RawAxiosRequestConfig) {
        return AuthControllerRestApiFp(this.configuration).restorePassword(secret, restorePasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} secret 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerRestApi
     */
    public verifyPasswordRestore(secret: string, options?: RawAxiosRequestConfig) {
        return AuthControllerRestApiFp(this.configuration).verifyPasswordRestore(secret, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BarcodeDictControllerRestApi - axios parameter creator
 * @export
 */
export const BarcodeDictControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete11: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete11', 'id', id)
            const localVarPath = `/api/dict/barcode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListAllByStatusStatusEnum} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllByStatus: async (status: ListAllByStatusStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('listAllByStatus', 'status', status)
            const localVarPath = `/api/dict/barcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} consignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeeCount: async (consignmentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consignmentId' is not null or undefined
            assertParamExists('listFeeCount', 'consignmentId', consignmentId)
            const localVarPath = `/api/dict/barcode/free/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (consignmentId !== undefined) {
                localVarQueryParameter['consignmentId'] = consignmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save10: async (containerBarcodeDictModel: ContainerBarcodeDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerBarcodeDictModel' is not null or undefined
            assertParamExists('save10', 'containerBarcodeDictModel', containerBarcodeDictModel)
            const localVarPath = `/api/dict/barcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(containerBarcodeDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInRange: async (containerBarcodeDictModel: ContainerBarcodeDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerBarcodeDictModel' is not null or undefined
            assertParamExists('saveInRange', 'containerBarcodeDictModel', containerBarcodeDictModel)
            const localVarPath = `/api/dict/barcode/range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(containerBarcodeDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update6: async (containerBarcodeDictModel: ContainerBarcodeDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerBarcodeDictModel' is not null or undefined
            assertParamExists('update6', 'containerBarcodeDictModel', containerBarcodeDictModel)
            const localVarPath = `/api/dict/barcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(containerBarcodeDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BarcodeDictControllerRestApi - functional programming interface
 * @export
 */
export const BarcodeDictControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BarcodeDictControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete11(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete11(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BarcodeDictControllerRestApi.delete11']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ListAllByStatusStatusEnum} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllByStatus(status: ListAllByStatusStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerBarcodeDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllByStatus(status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BarcodeDictControllerRestApi.listAllByStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} consignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFeeCount(consignmentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFeeCount(consignmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BarcodeDictControllerRestApi.listFeeCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save10(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save10(containerBarcodeDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BarcodeDictControllerRestApi.save10']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveInRange(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInRange(containerBarcodeDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BarcodeDictControllerRestApi.saveInRange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update6(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update6(containerBarcodeDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BarcodeDictControllerRestApi.update6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BarcodeDictControllerRestApi - factory interface
 * @export
 */
export const BarcodeDictControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BarcodeDictControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete11(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete11(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListAllByStatusStatusEnum} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllByStatus(status: ListAllByStatusStatusEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<ContainerBarcodeDictModel>> {
            return localVarFp.listAllByStatus(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} consignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeeCount(consignmentId: number, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.listFeeCount(consignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save10(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save10(containerBarcodeDictModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInRange(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.saveInRange(containerBarcodeDictModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update6(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update6(containerBarcodeDictModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BarcodeDictControllerRestApi - object-oriented interface
 * @export
 * @class BarcodeDictControllerRestApi
 * @extends {BaseAPI}
 */
export class BarcodeDictControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarcodeDictControllerRestApi
     */
    public delete11(id: number, options?: RawAxiosRequestConfig) {
        return BarcodeDictControllerRestApiFp(this.configuration).delete11(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListAllByStatusStatusEnum} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarcodeDictControllerRestApi
     */
    public listAllByStatus(status: ListAllByStatusStatusEnum, options?: RawAxiosRequestConfig) {
        return BarcodeDictControllerRestApiFp(this.configuration).listAllByStatus(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} consignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarcodeDictControllerRestApi
     */
    public listFeeCount(consignmentId: number, options?: RawAxiosRequestConfig) {
        return BarcodeDictControllerRestApiFp(this.configuration).listFeeCount(consignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarcodeDictControllerRestApi
     */
    public save10(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig) {
        return BarcodeDictControllerRestApiFp(this.configuration).save10(containerBarcodeDictModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarcodeDictControllerRestApi
     */
    public saveInRange(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig) {
        return BarcodeDictControllerRestApiFp(this.configuration).saveInRange(containerBarcodeDictModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerBarcodeDictModel} containerBarcodeDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarcodeDictControllerRestApi
     */
    public update6(containerBarcodeDictModel: ContainerBarcodeDictModel, options?: RawAxiosRequestConfig) {
        return BarcodeDictControllerRestApiFp(this.configuration).update6(containerBarcodeDictModel, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAllByStatusStatusEnum = {
    Used: 'USED',
    NotUsed: 'NOT_USED'
} as const;
export type ListAllByStatusStatusEnum = typeof ListAllByStatusStatusEnum[keyof typeof ListAllByStatusStatusEnum];


/**
 * BoxControllerRestApi - axios parameter creator
 * @export
 */
export const BoxControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete10: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete10', 'id', id)
            const localVarPath = `/api/dict/boxes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} barcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBarCode: async (barcode: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'barcode' is not null or undefined
            assertParamExists('getByBarCode', 'barcode', barcode)
            const localVarPath = `/api/dict/boxes/barcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById3: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById3', 'id', id)
            const localVarPath = `/api/dict/boxes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll5: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict/boxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BoxDictModel} boxDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save9: async (boxDictModel: BoxDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxDictModel' is not null or undefined
            assertParamExists('save9', 'boxDictModel', boxDictModel)
            const localVarPath = `/api/dict/boxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boxDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BoxDictModel} boxDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update5: async (boxDictModel: BoxDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxDictModel' is not null or undefined
            assertParamExists('update5', 'boxDictModel', boxDictModel)
            const localVarPath = `/api/dict/boxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boxDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoxControllerRestApi - functional programming interface
 * @export
 */
export const BoxControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoxControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete10(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete10(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxControllerRestApi.delete10']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} barcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByBarCode(barcode: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxDictModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByBarCode(barcode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxControllerRestApi.getByBarCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById3(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxDictModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById3(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxControllerRestApi.getById3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll5(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoxDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll5(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxControllerRestApi.listAll5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BoxDictModel} boxDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save9(boxDictModel: BoxDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save9(boxDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxControllerRestApi.save9']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BoxDictModel} boxDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update5(boxDictModel: BoxDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update5(boxDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxControllerRestApi.update5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BoxControllerRestApi - factory interface
 * @export
 */
export const BoxControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoxControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete10(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete10(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} barcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBarCode(barcode: number, options?: RawAxiosRequestConfig): AxiosPromise<BoxDictModel> {
            return localVarFp.getByBarCode(barcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById3(id: number, options?: RawAxiosRequestConfig): AxiosPromise<BoxDictModel> {
            return localVarFp.getById3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll5(options?: RawAxiosRequestConfig): AxiosPromise<Array<BoxDictModel>> {
            return localVarFp.listAll5(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BoxDictModel} boxDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save9(boxDictModel: BoxDictModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.save9(boxDictModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BoxDictModel} boxDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update5(boxDictModel: BoxDictModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update5(boxDictModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoxControllerRestApi - object-oriented interface
 * @export
 * @class BoxControllerRestApi
 * @extends {BaseAPI}
 */
export class BoxControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxControllerRestApi
     */
    public delete10(id: number, options?: RawAxiosRequestConfig) {
        return BoxControllerRestApiFp(this.configuration).delete10(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} barcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxControllerRestApi
     */
    public getByBarCode(barcode: number, options?: RawAxiosRequestConfig) {
        return BoxControllerRestApiFp(this.configuration).getByBarCode(barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxControllerRestApi
     */
    public getById3(id: number, options?: RawAxiosRequestConfig) {
        return BoxControllerRestApiFp(this.configuration).getById3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxControllerRestApi
     */
    public listAll5(options?: RawAxiosRequestConfig) {
        return BoxControllerRestApiFp(this.configuration).listAll5(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoxDictModel} boxDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxControllerRestApi
     */
    public save9(boxDictModel: BoxDictModel, options?: RawAxiosRequestConfig) {
        return BoxControllerRestApiFp(this.configuration).save9(boxDictModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoxDictModel} boxDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxControllerRestApi
     */
    public update5(boxDictModel: BoxDictModel, options?: RawAxiosRequestConfig) {
        return BoxControllerRestApiFp(this.configuration).update5(boxDictModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BusinessControllerRestApi - axios parameter creator
 * @export
 */
export const BusinessControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        block1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('block1', 'id', id)
            const localVarPath = `/api/business/users/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create3: async (userModel: UserModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userModel' is not null or undefined
            assertParamExists('create3', 'userModel', userModel)
            const localVarPath = `/api/business/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiKey: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserApiKey', 'id', id)
            const localVarPath = `/api/business/users/byUserId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll7: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/business/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessControllerRestApi - functional programming interface
 * @export
 */
export const BusinessControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async block1(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.block1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessControllerRestApi.block1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create3(userModel: UserModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create3(userModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessControllerRestApi.create3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiKey(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiKey(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessControllerRestApi.getUserApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll7(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll7(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessControllerRestApi.listAll7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BusinessControllerRestApi - factory interface
 * @export
 */
export const BusinessControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        block1(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.block1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create3(userModel: UserModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.create3(userModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiKey(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getUserApiKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll7(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserModel>> {
            return localVarFp.listAll7(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessControllerRestApi - object-oriented interface
 * @export
 * @class BusinessControllerRestApi
 * @extends {BaseAPI}
 */
export class BusinessControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessControllerRestApi
     */
    public block1(id: number, options?: RawAxiosRequestConfig) {
        return BusinessControllerRestApiFp(this.configuration).block1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserModel} userModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessControllerRestApi
     */
    public create3(userModel: UserModel, options?: RawAxiosRequestConfig) {
        return BusinessControllerRestApiFp(this.configuration).create3(userModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessControllerRestApi
     */
    public getUserApiKey(id: number, options?: RawAxiosRequestConfig) {
        return BusinessControllerRestApiFp(this.configuration).getUserApiKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessControllerRestApi
     */
    public listAll7(options?: RawAxiosRequestConfig) {
        return BusinessControllerRestApiFp(this.configuration).listAll7(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BusinessTariffControllerApi - axios parameter creator
 * @export
 */
export const BusinessTariffControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BusinessTariffModel} businessTariffModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create4: async (businessTariffModel: BusinessTariffModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessTariffModel' is not null or undefined
            assertParamExists('create4', 'businessTariffModel', businessTariffModel)
            const localVarPath = `/api/business/tariff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessTariffModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete1', 'id', id)
            const localVarPath = `/api/business/tariff/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll8: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/business/tariff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessTariffControllerApi - functional programming interface
 * @export
 */
export const BusinessTariffControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessTariffControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BusinessTariffModel} businessTariffModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create4(businessTariffModel: BusinessTariffModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create4(businessTariffModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessTariffControllerApi.create4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete1(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessTariffControllerApi.delete1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll8(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessTariffModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll8(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessTariffControllerApi.listAll8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BusinessTariffControllerApi - factory interface
 * @export
 */
export const BusinessTariffControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessTariffControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {BusinessTariffModel} businessTariffModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create4(businessTariffModel: BusinessTariffModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.create4(businessTariffModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll8(options?: RawAxiosRequestConfig): AxiosPromise<Array<BusinessTariffModel>> {
            return localVarFp.listAll8(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessTariffControllerApi - object-oriented interface
 * @export
 * @class BusinessTariffControllerApi
 * @extends {BaseAPI}
 */
export class BusinessTariffControllerApi extends BaseAPI {
    /**
     * 
     * @param {BusinessTariffModel} businessTariffModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTariffControllerApi
     */
    public create4(businessTariffModel: BusinessTariffModel, options?: RawAxiosRequestConfig) {
        return BusinessTariffControllerApiFp(this.configuration).create4(businessTariffModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTariffControllerApi
     */
    public delete1(id: number, options?: RawAxiosRequestConfig) {
        return BusinessTariffControllerApiFp(this.configuration).delete1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTariffControllerApi
     */
    public listAll8(options?: RawAxiosRequestConfig) {
        return BusinessTariffControllerApiFp(this.configuration).listAll8(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CdekControllerApi - axios parameter creator
 * @export
 */
export const CdekControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll6: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cdek/tariff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeliveryMode: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cdek/tariff/delivery/mode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParcelTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cdek/tariff/parcel/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CdekTariffModel} cdekTariffModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save13: async (cdekTariffModel: CdekTariffModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cdekTariffModel' is not null or undefined
            assertParamExists('save13', 'cdekTariffModel', cdekTariffModel)
            const localVarPath = `/api/cdek/tariff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cdekTariffModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CdekControllerApi - functional programming interface
 * @export
 */
export const CdekControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CdekControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll6(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CdekTariffModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll6(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CdekControllerApi.listAll6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeliveryMode(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeliveryMode(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CdekControllerApi.listDeliveryMode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listParcelTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listParcelTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CdekControllerApi.listParcelTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CdekTariffModel} cdekTariffModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save13(cdekTariffModel: CdekTariffModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save13(cdekTariffModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CdekControllerApi.save13']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CdekControllerApi - factory interface
 * @export
 */
export const CdekControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CdekControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll6(options?: RawAxiosRequestConfig): AxiosPromise<Array<CdekTariffModel>> {
            return localVarFp.listAll6(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeliveryMode(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.listDeliveryMode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParcelTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.listParcelTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CdekTariffModel} cdekTariffModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save13(cdekTariffModel: CdekTariffModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save13(cdekTariffModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CdekControllerApi - object-oriented interface
 * @export
 * @class CdekControllerApi
 * @extends {BaseAPI}
 */
export class CdekControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdekControllerApi
     */
    public listAll6(options?: RawAxiosRequestConfig) {
        return CdekControllerApiFp(this.configuration).listAll6(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdekControllerApi
     */
    public listDeliveryMode(options?: RawAxiosRequestConfig) {
        return CdekControllerApiFp(this.configuration).listDeliveryMode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdekControllerApi
     */
    public listParcelTypes(options?: RawAxiosRequestConfig) {
        return CdekControllerApiFp(this.configuration).listParcelTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CdekTariffModel} cdekTariffModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdekControllerApi
     */
    public save13(cdekTariffModel: CdekTariffModel, options?: RawAxiosRequestConfig) {
        return CdekControllerApiFp(this.configuration).save13(cdekTariffModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CdekReportControllerApi - axios parameter creator
 * @export
 */
export const CdekReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consignment: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consignment', 'id', id)
            const localVarPath = `/api/report/cdek/consignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CdekReportControllerApi - functional programming interface
 * @export
 */
export const CdekReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CdekReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consignment(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consignment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CdekReportControllerApi.consignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CdekReportControllerApi - factory interface
 * @export
 */
export const CdekReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CdekReportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consignment(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.consignment(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CdekReportControllerApi - object-oriented interface
 * @export
 * @class CdekReportControllerApi
 * @extends {BaseAPI}
 */
export class CdekReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdekReportControllerApi
     */
    public consignment(id: number, options?: RawAxiosRequestConfig) {
        return CdekReportControllerApiFp(this.configuration).consignment(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConsignmentsControllerApi - axios parameter creator
 * @export
 */
export const ConsignmentsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} parcelId 
         * @param {number} consignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOversizeContainer: async (parcelId: number, consignmentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelId' is not null or undefined
            assertParamExists('createOversizeContainer', 'parcelId', parcelId)
            // verify required parameter 'consignmentId' is not null or undefined
            assertParamExists('createOversizeContainer', 'consignmentId', consignmentId)
            const localVarPath = `/api/consignments/crate/oversize/container`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parcelId !== undefined) {
                localVarQueryParameter['parcelId'] = parcelId;
            }

            if (consignmentId !== undefined) {
                localVarQueryParameter['consignmentId'] = consignmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete12: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete12', 'id', id)
            const localVarPath = `/api/consignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwbInfo: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAwbInfo', 'id', id)
            const localVarPath = `/api/consignments/get/awb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById4: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById4', 'id', id)
            const localVarPath = `/api/consignments/byId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTrack1: async (track: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'track' is not null or undefined
            assertParamExists('getByTrack1', 'track', track)
            const localVarPath = `/api/consignments/getByTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (track !== undefined) {
                localVarQueryParameter['track'] = track;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerExcelByContainer: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContainerExcelByContainer', 'id', id)
            const localVarPath = `/api/consignments/excel/container`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerExcelByPallet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContainerExcelByPallet', 'id', id)
            const localVarPath = `/api/consignments/excel/pallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcel1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExcel1', 'id', id)
            const localVarPath = `/api/consignments/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelChecking: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExcelChecking', 'id', id)
            const localVarPath = `/api/consignments/excel/checking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegistryById', 'id', id)
            const localVarPath = `/api/consignments/registry/by`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list4: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/consignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChecking: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/consignments/checking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistory1: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/consignments/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSent: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/consignments/sent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIntoHistory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putIntoHistory', 'id', id)
            const localVarPath = `/api/consignments/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConsignmentsModel} consignmentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save12: async (consignmentsModel: ConsignmentsModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consignmentsModel' is not null or undefined
            assertParamExists('save12', 'consignmentsModel', consignmentsModel)
            const localVarPath = `/api/consignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consignmentsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('send', 'id', id)
            const localVarPath = `/api/consignments/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} awb 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAwb: async (id: number, awb: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setAwb', 'id', id)
            // verify required parameter 'awb' is not null or undefined
            assertParamExists('setAwb', 'awb', awb)
            const localVarPath = `/api/consignments/set/awb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (awb !== undefined) {
                localVarQueryParameter['awb'] = awb;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsignmentsControllerApi - functional programming interface
 * @export
 */
export const ConsignmentsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsignmentsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} parcelId 
         * @param {number} consignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOversizeContainer(parcelId: number, consignmentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOversizeContainer(parcelId, consignmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.createOversizeContainer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete12(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete12(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.delete12']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAwbInfo(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelShipmentData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAwbInfo(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getAwbInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById4(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsignmentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById4(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getById4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByTrack1(track: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByTrack1(track, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getByTrack1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContainerExcelByContainer(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContainerExcelByContainer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getContainerExcelByContainer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContainerExcelByPallet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContainerExcelByPallet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getContainerExcelByPallet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcel1(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcel1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getExcel1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcelChecking(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcelChecking(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getExcelChecking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.getRegistryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list4(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsignmentsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list4(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.list4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listChecking(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsignmentsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listChecking(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.listChecking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHistory1(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsignmentsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHistory1(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.listHistory1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSent(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsignmentsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSent(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.listSent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putIntoHistory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putIntoHistory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.putIntoHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ConsignmentsModel} consignmentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save12(consignmentsModel: ConsignmentsModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save12(consignmentsModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.save12']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async send(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.send(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.send']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} awb 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAwb(id: number, awb: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAwb(id, awb, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsignmentsControllerApi.setAwb']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConsignmentsControllerApi - factory interface
 * @export
 */
export const ConsignmentsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsignmentsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} parcelId 
         * @param {number} consignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOversizeContainer(parcelId: number, consignmentId: number, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createOversizeContainer(parcelId, consignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete12(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete12(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwbInfo(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ParcelShipmentData> {
            return localVarFp.getAwbInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById4(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConsignmentsModel> {
            return localVarFp.getById4(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTrack1(track: string, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.getByTrack1(track, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerExcelByContainer(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getContainerExcelByContainer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerExcelByPallet(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getContainerExcelByPallet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcel1(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getExcel1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelChecking(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getExcelChecking(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistryById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getRegistryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list4(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ConsignmentsModel>> {
            return localVarFp.list4(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChecking(options?: RawAxiosRequestConfig): AxiosPromise<Array<ConsignmentsModel>> {
            return localVarFp.listChecking(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistory1(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ConsignmentsModel>> {
            return localVarFp.listHistory1(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSent(options?: RawAxiosRequestConfig): AxiosPromise<Array<ConsignmentsModel>> {
            return localVarFp.listSent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIntoHistory(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putIntoHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConsignmentsModel} consignmentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save12(consignmentsModel: ConsignmentsModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save12(consignmentsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.send(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} awb 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAwb(id: number, awb: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setAwb(id, awb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsignmentsControllerApi - object-oriented interface
 * @export
 * @class ConsignmentsControllerApi
 * @extends {BaseAPI}
 */
export class ConsignmentsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} parcelId 
     * @param {number} consignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public createOversizeContainer(parcelId: number, consignmentId: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).createOversizeContainer(parcelId, consignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public delete12(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).delete12(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getAwbInfo(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getAwbInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getById4(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getById4(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} track 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getByTrack1(track: string, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getByTrack1(track, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getContainerExcelByContainer(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getContainerExcelByContainer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getContainerExcelByPallet(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getContainerExcelByPallet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getExcel1(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getExcel1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getExcelChecking(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getExcelChecking(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public getRegistryById(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).getRegistryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public list4(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).list4(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public listChecking(options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).listChecking(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public listHistory1(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).listHistory1(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public listSent(options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).listSent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public putIntoHistory(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).putIntoHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConsignmentsModel} consignmentsModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public save12(consignmentsModel: ConsignmentsModel, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).save12(consignmentsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public send(id: number, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).send(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} awb 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsignmentsControllerApi
     */
    public setAwb(id: number, awb: string, options?: RawAxiosRequestConfig) {
        return ConsignmentsControllerApiFp(this.configuration).setAwb(id, awb, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContainerControllerRestApi - axios parameter creator
 * @export
 */
export const ContainerControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParcel1: async (id: number, track: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addParcel1', 'id', id)
            // verify required parameter 'track' is not null or undefined
            assertParamExists('addParcel1', 'track', track)
            const localVarPath = `/api/containers/add/parcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (track !== undefined) {
                localVarQueryParameter['track'] = track;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContainer: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContainer', 'id', id)
            const localVarPath = `/api/containers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByConsignmentId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllByConsignmentId', 'id', id)
            const localVarPath = `/api/containers/consignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findById1', 'id', id)
            const localVarPath = `/api/containers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode3: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBarCode3', 'id', id)
            const localVarPath = `/api/containers/barcode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromContainer: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFromContainer', 'id', id)
            const localVarPath = `/api/containers/remove/from/container`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContainerCreateModel} containerCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save11: async (containerCreateModel: ContainerCreateModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerCreateModel' is not null or undefined
            assertParamExists('save11', 'containerCreateModel', containerCreateModel)
            const localVarPath = `/api/containers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(containerCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1: async (val: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'val' is not null or undefined
            assertParamExists('search1', 'val', val)
            const localVarPath = `/api/containers/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (val !== undefined) {
                localVarQueryParameter['val'] = val;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContainerCreateModel} containerCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update7: async (containerCreateModel: ContainerCreateModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerCreateModel' is not null or undefined
            assertParamExists('update7', 'containerCreateModel', containerCreateModel)
            const localVarPath = `/api/containers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(containerCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContainerControllerRestApi - functional programming interface
 * @export
 */
export const ContainerControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContainerControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addParcel1(id: number, track: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addParcel1(id, track, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.addParcel1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContainer(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContainer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.deleteContainer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByConsignmentId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByConsignmentId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.findAllByConsignmentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById1(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findById1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.findById1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBarCode3(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBarCode3(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.getBarCode3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromContainer(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromContainer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.removeFromContainer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ContainerCreateModel} containerCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save11(containerCreateModel: ContainerCreateModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save11(containerCreateModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.save11']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search1(val: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search1(val, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.search1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ContainerCreateModel} containerCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update7(containerCreateModel: ContainerCreateModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update7(containerCreateModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContainerControllerRestApi.update7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContainerControllerRestApi - factory interface
 * @export
 */
export const ContainerControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContainerControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParcel1(id: number, track: string, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.addParcel1(id, track, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContainer(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteContainer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByConsignmentId(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ContainerModel>> {
            return localVarFp.findAllByConsignmentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById1(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ContainerModel> {
            return localVarFp.findById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode3(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getBarCode3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromContainer(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.removeFromContainer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerCreateModel} containerCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save11(containerCreateModel: ContainerCreateModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.save11(containerCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1(val: string, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.search1(val, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerCreateModel} containerCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update7(containerCreateModel: ContainerCreateModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update7(containerCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContainerControllerRestApi - object-oriented interface
 * @export
 * @class ContainerControllerRestApi
 * @extends {BaseAPI}
 */
export class ContainerControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {string} track 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public addParcel1(id: number, track: string, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).addParcel1(id, track, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public deleteContainer(id: number, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).deleteContainer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public findAllByConsignmentId(id: number, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).findAllByConsignmentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public findById1(id: number, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).findById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public getBarCode3(id: number, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).getBarCode3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public removeFromContainer(id: number, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).removeFromContainer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerCreateModel} containerCreateModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public save11(containerCreateModel: ContainerCreateModel, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).save11(containerCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} val 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public search1(val: string, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).search1(val, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerCreateModel} containerCreateModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerControllerRestApi
     */
    public update7(containerCreateModel: ContainerCreateModel, options?: RawAxiosRequestConfig) {
        return ContainerControllerRestApiFp(this.configuration).update7(containerCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CountryControllerRestApi - axios parameter creator
 * @export
 */
export const CountryControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/dict/country/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll4: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CountryDictModel} countryDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save8: async (countryDictModel: CountryDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryDictModel' is not null or undefined
            assertParamExists('save8', 'countryDictModel', countryDictModel)
            const localVarPath = `/api/dict/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CountryDictModel} countryDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update10: async (countryDictModel: CountryDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryDictModel' is not null or undefined
            assertParamExists('update10', 'countryDictModel', countryDictModel)
            const localVarPath = `/api/dict/country/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryControllerRestApi - functional programming interface
 * @export
 */
export const CountryControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerRestApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll4(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll4(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerRestApi.listAll4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CountryDictModel} countryDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save8(countryDictModel: CountryDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save8(countryDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerRestApi.save8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CountryDictModel} countryDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update10(countryDictModel: CountryDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update10(countryDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerRestApi.update10']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CountryControllerRestApi - factory interface
 * @export
 */
export const CountryControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll4(options?: RawAxiosRequestConfig): AxiosPromise<Array<CountryDictModel>> {
            return localVarFp.listAll4(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CountryDictModel} countryDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save8(countryDictModel: CountryDictModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.save8(countryDictModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CountryDictModel} countryDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update10(countryDictModel: CountryDictModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update10(countryDictModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryControllerRestApi - object-oriented interface
 * @export
 * @class CountryControllerRestApi
 * @extends {BaseAPI}
 */
export class CountryControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerRestApi
     */
    public _delete(id: number, options?: RawAxiosRequestConfig) {
        return CountryControllerRestApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerRestApi
     */
    public listAll4(options?: RawAxiosRequestConfig) {
        return CountryControllerRestApiFp(this.configuration).listAll4(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CountryDictModel} countryDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerRestApi
     */
    public save8(countryDictModel: CountryDictModel, options?: RawAxiosRequestConfig) {
        return CountryControllerRestApiFp(this.configuration).save8(countryDictModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CountryDictModel} countryDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerRestApi
     */
    public update10(countryDictModel: CountryDictModel, options?: RawAxiosRequestConfig) {
        return CountryControllerRestApiFp(this.configuration).update10(countryDictModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliveryServicesControllerApi - axios parameter creator
 * @export
 */
export const DeliveryServicesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistory: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/delivery/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} active 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update11: async (active: boolean, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'active' is not null or undefined
            assertParamExists('update11', 'active', active)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update11', 'id', id)
            const localVarPath = `/api/delivery/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryServicesControllerApi - functional programming interface
 * @export
 */
export const DeliveryServicesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryServicesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHistory(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShipEngineCarrierServiceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHistory(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryServicesControllerApi.listHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} active 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update11(active: boolean, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update11(active, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryServicesControllerApi.update11']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeliveryServicesControllerApi - factory interface
 * @export
 */
export const DeliveryServicesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryServicesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistory(options?: RawAxiosRequestConfig): AxiosPromise<Array<ShipEngineCarrierServiceModel>> {
            return localVarFp.listHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} active 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update11(active: boolean, id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update11(active, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryServicesControllerApi - object-oriented interface
 * @export
 * @class DeliveryServicesControllerApi
 * @extends {BaseAPI}
 */
export class DeliveryServicesControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryServicesControllerApi
     */
    public listHistory(options?: RawAxiosRequestConfig) {
        return DeliveryServicesControllerApiFp(this.configuration).listHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} active 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryServicesControllerApi
     */
    public update11(active: boolean, id: number, options?: RawAxiosRequestConfig) {
        return DeliveryServicesControllerApiFp(this.configuration).update11(active, id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DictControllerRestApi - axios parameter creator
 * @export
 */
export const DictControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offices: async (cityCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityCode' is not null or undefined
            assertParamExists('offices', 'cityCode', cityCode)
            const localVarPath = `/api/dict/office`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cityCode !== undefined) {
                localVarQueryParameter['cityCode'] = cityCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCity: async (name: string, countryCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('searchCity', 'name', name)
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('searchCity', 'countryCode', countryCode)
            const localVarPath = `/api/dict/search/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocks: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictControllerRestApi - functional programming interface
 * @export
 */
export const DictControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countries(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countries(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictControllerRestApi.countries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offices(cityCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficeDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offices(cityCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictControllerRestApi.offices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCity(name: string, countryCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCity(name, countryCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictControllerRestApi.searchCity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocks(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocks(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictControllerRestApi.stocks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DictControllerRestApi - factory interface
 * @export
 */
export const DictControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(options?: RawAxiosRequestConfig): AxiosPromise<Array<CountryDictModel>> {
            return localVarFp.countries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offices(cityCode: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<OfficeDictModel>> {
            return localVarFp.offices(cityCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCity(name: string, countryCode: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CityDictModel>> {
            return localVarFp.searchCity(name, countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocks(options?: RawAxiosRequestConfig): AxiosPromise<Array<StockModel>> {
            return localVarFp.stocks(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictControllerRestApi - object-oriented interface
 * @export
 * @class DictControllerRestApi
 * @extends {BaseAPI}
 */
export class DictControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictControllerRestApi
     */
    public countries(options?: RawAxiosRequestConfig) {
        return DictControllerRestApiFp(this.configuration).countries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cityCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictControllerRestApi
     */
    public offices(cityCode: string, options?: RawAxiosRequestConfig) {
        return DictControllerRestApiFp(this.configuration).offices(cityCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictControllerRestApi
     */
    public searchCity(name: string, countryCode: string, options?: RawAxiosRequestConfig) {
        return DictControllerRestApiFp(this.configuration).searchCity(name, countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictControllerRestApi
     */
    public stocks(options?: RawAxiosRequestConfig) {
        return DictControllerRestApiFp(this.configuration).stocks(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GbsControllerApi - axios parameter creator
 * @export
 */
export const GbsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignment: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadConsignment', 'id', id)
            const localVarPath = `/api/gbs/upload-consignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignmentNoop: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadConsignmentNoop', 'id', id)
            const localVarPath = `/api/gbs/noop/upload-consignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignmentNumbers: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadConsignmentNumbers', 'id', id)
            const localVarPath = `/api/gbs/upload-consignment-numbers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignmentNumbersNoop: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadConsignmentNumbersNoop', 'id', id)
            const localVarPath = `/api/gbs/noop/upload-consignment-numbers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadParcel: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadParcel', 'id', id)
            const localVarPath = `/api/gbs/upload-parcel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadParcelNoop: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadParcelNoop', 'id', id)
            const localVarPath = `/api/gbs/noop/upload-parcel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GbsControllerApi - functional programming interface
 * @export
 */
export const GbsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GbsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConsignment(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnfUploadFullDialogue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConsignment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GbsControllerApi.uploadConsignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConsignmentNoop(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsignmentFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConsignmentNoop(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GbsControllerApi.uploadConsignmentNoop']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConsignmentNumbers(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnfUploadNumbersDialogue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConsignmentNumbers(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GbsControllerApi.uploadConsignmentNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConsignmentNumbersNoop(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsignmentNumbers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConsignmentNumbersNoop(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GbsControllerApi.uploadConsignmentNumbersNoop']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadParcel(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HwbUploadDialogue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadParcel(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GbsControllerApi.uploadParcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadParcelNoop(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Parcel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadParcelNoop(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GbsControllerApi.uploadParcelNoop']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GbsControllerApi - factory interface
 * @export
 */
export const GbsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GbsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignment(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MnfUploadFullDialogue> {
            return localVarFp.uploadConsignment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignmentNoop(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConsignmentFull> {
            return localVarFp.uploadConsignmentNoop(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignmentNumbers(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MnfUploadNumbersDialogue> {
            return localVarFp.uploadConsignmentNumbers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConsignmentNumbersNoop(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConsignmentNumbers> {
            return localVarFp.uploadConsignmentNumbersNoop(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadParcel(id: number, options?: RawAxiosRequestConfig): AxiosPromise<HwbUploadDialogue> {
            return localVarFp.uploadParcel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadParcelNoop(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Parcel> {
            return localVarFp.uploadParcelNoop(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GbsControllerApi - object-oriented interface
 * @export
 * @class GbsControllerApi
 * @extends {BaseAPI}
 */
export class GbsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GbsControllerApi
     */
    public uploadConsignment(id: number, options?: RawAxiosRequestConfig) {
        return GbsControllerApiFp(this.configuration).uploadConsignment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GbsControllerApi
     */
    public uploadConsignmentNoop(id: number, options?: RawAxiosRequestConfig) {
        return GbsControllerApiFp(this.configuration).uploadConsignmentNoop(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GbsControllerApi
     */
    public uploadConsignmentNumbers(id: number, options?: RawAxiosRequestConfig) {
        return GbsControllerApiFp(this.configuration).uploadConsignmentNumbers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GbsControllerApi
     */
    public uploadConsignmentNumbersNoop(id: number, options?: RawAxiosRequestConfig) {
        return GbsControllerApiFp(this.configuration).uploadConsignmentNumbersNoop(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GbsControllerApi
     */
    public uploadParcel(id: number, options?: RawAxiosRequestConfig) {
        return GbsControllerApiFp(this.configuration).uploadParcel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GbsControllerApi
     */
    public uploadParcelNoop(id: number, options?: RawAxiosRequestConfig) {
        return GbsControllerApiFp(this.configuration).uploadParcelNoop(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IncomingParcelsControllerRestApi - axios parameter creator
 * @export
 */
export const IncomingParcelsControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countsByTabs1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incoming/parcels/counts-by-tabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommentsModel} commentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment1: async (commentsModel: CommentsModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentsModel' is not null or undefined
            assertParamExists('createComment1', 'commentsModel', commentsModel)
            const localVarPath = `/api/incoming/parcels/create/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incoming/parcels/comments/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelAdminFilter} parcelAdminFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcelsByFilter1: async (parcelAdminFilter: ParcelAdminFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelAdminFilter' is not null or undefined
            assertParamExists('getParcelsByFilter1', 'parcelAdminFilter', parcelAdminFilter)
            const localVarPath = `/api/incoming/parcels/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelAdminFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incoming/parcels/sending/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrackByDeliveringTrack: async (track: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'track' is not null or undefined
            assertParamExists('getTrackByDeliveringTrack', 'track', track)
            const localVarPath = `/api/incoming/parcels/track/by/delivering/track`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (track !== undefined) {
                localVarQueryParameter['track'] = track;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll1: async (limit?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incoming/parcels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllClientsMin: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incoming/parcels/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} solved 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInStockWithErrors: async (solved: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solved' is not null or undefined
            assertParamExists('listInStockWithErrors', 'solved', solved)
            const localVarPath = `/api/incoming/parcels/inStock/with-errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (solved !== undefined) {
                localVarQueryParameter['solved'] = solved;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelSizeModel} parcelSizeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (parcelSizeModel: ParcelSizeModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelSizeModel' is not null or undefined
            assertParamExists('register', 'parcelSizeModel', parcelSizeModel)
            const localVarPath = `/api/incoming/parcels/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelSizeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelUndefinedModel} parcelUndefinedModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUndefined: async (parcelUndefinedModel: ParcelUndefinedModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelUndefinedModel' is not null or undefined
            assertParamExists('registerUndefined', 'parcelUndefinedModel', parcelUndefinedModel)
            const localVarPath = `/api/incoming/parcels/register/undefined`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelUndefinedModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnParcel: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('returnParcel', 'id', id)
            const localVarPath = `/api/incoming/parcels/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilize: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('utilize', 'id', id)
            const localVarPath = `/api/incoming/parcels/utilize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IncomingParcelsControllerRestApi - functional programming interface
 * @export
 */
export const IncomingParcelsControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IncomingParcelsControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countsByTabs1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomingParcelCountsByTabs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countsByTabs1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.countsByTabs1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CommentsModel} commentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment1(commentsModel: CommentsModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment1(commentsModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.createComment1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.getCommentsTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelAdminFilter} parcelAdminFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParcelsByFilter1(parcelAdminFilter: ParcelAdminFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoPageParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParcelsByFilter1(parcelAdminFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.getParcelsByFilter1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSendingCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSendingCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.getSendingCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrackByDeliveringTrack(track: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrackByDeliveringTrack(track, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.getTrackByDeliveringTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll1(limit?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParcelModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll1(limit, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.listAll1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllClientsMin(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdNameModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllClientsMin(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.listAllClientsMin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} solved 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInStockWithErrors(solved: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParcelModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInStockWithErrors(solved, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.listInStockWithErrors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelSizeModel} parcelSizeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(parcelSizeModel: ParcelSizeModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelFileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(parcelSizeModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.register']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelUndefinedModel} parcelUndefinedModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUndefined(parcelUndefinedModel: ParcelUndefinedModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUndefined(parcelUndefinedModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.registerUndefined']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnParcel(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnParcel(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.returnParcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilize(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilize(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomingParcelsControllerRestApi.utilize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IncomingParcelsControllerRestApi - factory interface
 * @export
 */
export const IncomingParcelsControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IncomingParcelsControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countsByTabs1(options?: RawAxiosRequestConfig): AxiosPromise<IncomingParcelCountsByTabs> {
            return localVarFp.countsByTabs1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommentsModel} commentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment1(commentsModel: CommentsModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createComment1(commentsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getCommentsTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelAdminFilter} parcelAdminFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcelsByFilter1(parcelAdminFilter: ParcelAdminFilter, options?: RawAxiosRequestConfig): AxiosPromise<DtoPageParcelModel> {
            return localVarFp.getParcelsByFilter1(parcelAdminFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingCompany(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getSendingCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrackByDeliveringTrack(track: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getTrackByDeliveringTrack(track, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll1(limit?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ParcelModel>> {
            return localVarFp.listAll1(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllClientsMin(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<IdNameModel>> {
            return localVarFp.listAllClientsMin(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} solved 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInStockWithErrors(solved: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ParcelModel>> {
            return localVarFp.listInStockWithErrors(solved, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelSizeModel} parcelSizeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(parcelSizeModel: ParcelSizeModel, options?: RawAxiosRequestConfig): AxiosPromise<LabelFileModel> {
            return localVarFp.register(parcelSizeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelUndefinedModel} parcelUndefinedModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUndefined(parcelUndefinedModel: ParcelUndefinedModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.registerUndefined(parcelUndefinedModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnParcel(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.returnParcel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilize(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.utilize(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IncomingParcelsControllerRestApi - object-oriented interface
 * @export
 * @class IncomingParcelsControllerRestApi
 * @extends {BaseAPI}
 */
export class IncomingParcelsControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public countsByTabs1(options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).countsByTabs1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommentsModel} commentsModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public createComment1(commentsModel: CommentsModel, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).createComment1(commentsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public getCommentsTypes(options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).getCommentsTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelAdminFilter} parcelAdminFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public getParcelsByFilter1(parcelAdminFilter: ParcelAdminFilter, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).getParcelsByFilter1(parcelAdminFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public getSendingCompany(options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).getSendingCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} track 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public getTrackByDeliveringTrack(track: string, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).getTrackByDeliveringTrack(track, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public listAll1(limit?: number, page?: number, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).listAll1(limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public listAllClientsMin(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).listAllClientsMin(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} solved 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public listInStockWithErrors(solved: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).listInStockWithErrors(solved, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelSizeModel} parcelSizeModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public register(parcelSizeModel: ParcelSizeModel, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).register(parcelSizeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelUndefinedModel} parcelUndefinedModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public registerUndefined(parcelUndefinedModel: ParcelUndefinedModel, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).registerUndefined(parcelUndefinedModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public returnParcel(id: number, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).returnParcel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingParcelsControllerRestApi
     */
    public utilize(id: number, options?: RawAxiosRequestConfig) {
        return IncomingParcelsControllerRestApiFp(this.configuration).utilize(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceControllerApi - axios parameter creator
 * @export
 */
export const InvoiceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} invoiceId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approve: async (invoiceId: number, value: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('approve', 'invoiceId', invoiceId)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('approve', 'value', value)
            const localVarPath = `/api/business/invoice/{invoiceId}/approve/{value}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"value"}}`, encodeURIComponent(String(value)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateFilter} dateFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory: async (dateFilter: DateFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFilter' is not null or undefined
            assertParamExists('getHistory', 'dateFilter', dateFilter)
            const localVarPath = `/api/business/invoice/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFilter !== undefined) {
                for (const [key, value] of Object.entries(dateFilter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateFilter} dateFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNew: async (dateFilter: DateFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFilter' is not null or undefined
            assertParamExists('getNew', 'dateFilter', dateFilter)
            const localVarPath = `/api/business/invoice/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFilter !== undefined) {
                for (const [key, value] of Object.entries(dateFilter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceControllerApi - functional programming interface
 * @export
 */
export const InvoiceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} invoiceId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approve(invoiceId: number, value: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approve(invoiceId, value, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.approve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DateFilter} dateFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistory(dateFilter: DateFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceReadDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistory(dateFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DateFilter} dateFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNew(dateFilter: DateFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceReadDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNew(dateFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getNew']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceControllerApi - factory interface
 * @export
 */
export const InvoiceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} invoiceId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approve(invoiceId: number, value: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.approve(invoiceId, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DateFilter} dateFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(dateFilter: DateFilter, options?: RawAxiosRequestConfig): AxiosPromise<Array<InvoiceReadDto>> {
            return localVarFp.getHistory(dateFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DateFilter} dateFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNew(dateFilter: DateFilter, options?: RawAxiosRequestConfig): AxiosPromise<Array<InvoiceReadDto>> {
            return localVarFp.getNew(dateFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceControllerApi - object-oriented interface
 * @export
 * @class InvoiceControllerApi
 * @extends {BaseAPI}
 */
export class InvoiceControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} invoiceId 
     * @param {boolean} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public approve(invoiceId: number, value: boolean, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).approve(invoiceId, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DateFilter} dateFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getHistory(dateFilter: DateFilter, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getHistory(dateFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DateFilter} dateFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getNew(dateFilter: DateFilter, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getNew(dateFilter, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogControllerRestApi - axios parameter creator
 * @export
 */
export const LogControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} sourceId 
         * @param {ListAllBySourceIdSourceEnum} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllBySourceId: async (sourceId: number, source: ListAllBySourceIdSourceEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('listAllBySourceId', 'sourceId', sourceId)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('listAllBySourceId', 'source', source)
            const localVarPath = `/api/logs/source/{sourceId}/{source}`
                .replace(`{${"sourceId"}}`, encodeURIComponent(String(sourceId)))
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogModel} logModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save5: async (logModel: LogModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logModel' is not null or undefined
            assertParamExists('save5', 'logModel', logModel)
            const localVarPath = `/api/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogControllerRestApi - functional programming interface
 * @export
 */
export const LogControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} sourceId 
         * @param {ListAllBySourceIdSourceEnum} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllBySourceId(sourceId: number, source: ListAllBySourceIdSourceEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllBySourceId(sourceId, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogControllerRestApi.listAllBySourceId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LogModel} logModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save5(logModel: LogModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save5(logModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogControllerRestApi.save5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LogControllerRestApi - factory interface
 * @export
 */
export const LogControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} sourceId 
         * @param {ListAllBySourceIdSourceEnum} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllBySourceId(sourceId: number, source: ListAllBySourceIdSourceEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<LogModel>> {
            return localVarFp.listAllBySourceId(sourceId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogModel} logModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save5(logModel: LogModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save5(logModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogControllerRestApi - object-oriented interface
 * @export
 * @class LogControllerRestApi
 * @extends {BaseAPI}
 */
export class LogControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} sourceId 
     * @param {ListAllBySourceIdSourceEnum} source 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerRestApi
     */
    public listAllBySourceId(sourceId: number, source: ListAllBySourceIdSourceEnum, options?: RawAxiosRequestConfig) {
        return LogControllerRestApiFp(this.configuration).listAllBySourceId(sourceId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogModel} logModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerRestApi
     */
    public save5(logModel: LogModel, options?: RawAxiosRequestConfig) {
        return LogControllerRestApiFp(this.configuration).save5(logModel, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAllBySourceIdSourceEnum = {
    Consignments: 'CONSIGNMENTS',
    ConsignmentsContainers: 'CONSIGNMENTS_CONTAINERS',
    ConsignmentsPallet: 'CONSIGNMENTS_PALLET'
} as const;
export type ListAllBySourceIdSourceEnum = typeof ListAllBySourceIdSourceEnum[keyof typeof ListAllBySourceIdSourceEnum];


/**
 * MenuControllerRestApi - axios parameter creator
 * @export
 */
export const MenuControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menu: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuControllerRestApi - functional programming interface
 * @export
 */
export const MenuControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async menu(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.menu(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuControllerRestApi.menu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MenuControllerRestApi - factory interface
 * @export
 */
export const MenuControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menu(options?: RawAxiosRequestConfig): AxiosPromise<Array<PermissionGroup>> {
            return localVarFp.menu(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuControllerRestApi - object-oriented interface
 * @export
 * @class MenuControllerRestApi
 * @extends {BaseAPI}
 */
export class MenuControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuControllerRestApi
     */
    public menu(options?: RawAxiosRequestConfig) {
        return MenuControllerRestApiFp(this.configuration).menu(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OutgoingParcelsControllerRestApi - axios parameter creator
 * @export
 */
export const OutgoingParcelsControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countsByTabs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/outgoing/parcels/counts-by-tabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommentsModel} commentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (commentsModel: CommentsModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentsModel' is not null or undefined
            assertParamExists('createComment', 'commentsModel', commentsModel)
            const localVarPath = `/api/outgoing/parcels/create/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelModel} parcelModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParcelCollection: async (parcelModel: ParcelModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelModel' is not null or undefined
            assertParamExists('createParcelCollection', 'parcelModel', parcelModel)
            const localVarPath = `/api/outgoing/parcels/collect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLabel', 'id', id)
            const localVarPath = `/api/outgoing/parcels/label`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelAdminFilter} parcelAdminFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcelsByFilter: async (parcelAdminFilter: ParcelAdminFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelAdminFilter' is not null or undefined
            assertParamExists('getParcelsByFilter', 'parcelAdminFilter', parcelAdminFilter)
            const localVarPath = `/api/outgoing/parcels/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelAdminFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutgoingParcelsControllerRestApi - functional programming interface
 * @export
 */
export const OutgoingParcelsControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutgoingParcelsControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countsByTabs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingParcelCountsByTabs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countsByTabs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutgoingParcelsControllerRestApi.countsByTabs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CommentsModel} commentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(commentsModel: CommentsModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(commentsModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutgoingParcelsControllerRestApi.createComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelModel} parcelModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParcelCollection(parcelModel: ParcelModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelCollectResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParcelCollection(parcelModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutgoingParcelsControllerRestApi.createParcelCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabel(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabel(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutgoingParcelsControllerRestApi.getLabel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelAdminFilter} parcelAdminFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParcelsByFilter(parcelAdminFilter: ParcelAdminFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoPageParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParcelsByFilter(parcelAdminFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutgoingParcelsControllerRestApi.getParcelsByFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OutgoingParcelsControllerRestApi - factory interface
 * @export
 */
export const OutgoingParcelsControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutgoingParcelsControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countsByTabs(options?: RawAxiosRequestConfig): AxiosPromise<OutgoingParcelCountsByTabs> {
            return localVarFp.countsByTabs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommentsModel} commentsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(commentsModel: CommentsModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createComment(commentsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelModel} parcelModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParcelCollection(parcelModel: ParcelModel, options?: RawAxiosRequestConfig): AxiosPromise<ParcelCollectResponseModel> {
            return localVarFp.createParcelCollection(parcelModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getLabel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelAdminFilter} parcelAdminFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcelsByFilter(parcelAdminFilter: ParcelAdminFilter, options?: RawAxiosRequestConfig): AxiosPromise<DtoPageParcelModel> {
            return localVarFp.getParcelsByFilter(parcelAdminFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutgoingParcelsControllerRestApi - object-oriented interface
 * @export
 * @class OutgoingParcelsControllerRestApi
 * @extends {BaseAPI}
 */
export class OutgoingParcelsControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingParcelsControllerRestApi
     */
    public countsByTabs(options?: RawAxiosRequestConfig) {
        return OutgoingParcelsControllerRestApiFp(this.configuration).countsByTabs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommentsModel} commentsModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingParcelsControllerRestApi
     */
    public createComment(commentsModel: CommentsModel, options?: RawAxiosRequestConfig) {
        return OutgoingParcelsControllerRestApiFp(this.configuration).createComment(commentsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelModel} parcelModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingParcelsControllerRestApi
     */
    public createParcelCollection(parcelModel: ParcelModel, options?: RawAxiosRequestConfig) {
        return OutgoingParcelsControllerRestApiFp(this.configuration).createParcelCollection(parcelModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingParcelsControllerRestApi
     */
    public getLabel(id: number, options?: RawAxiosRequestConfig) {
        return OutgoingParcelsControllerRestApiFp(this.configuration).getLabel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelAdminFilter} parcelAdminFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingParcelsControllerRestApi
     */
    public getParcelsByFilter(parcelAdminFilter: ParcelAdminFilter, options?: RawAxiosRequestConfig) {
        return OutgoingParcelsControllerRestApiFp(this.configuration).getParcelsByFilter(parcelAdminFilter, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaletteControllerRestApi - axios parameter creator
 * @export
 */
export const PaletteControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete9: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete9', 'id', id)
            const localVarPath = `/api/dict/palettes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBarCode2', 'id', id)
            const localVarPath = `/api/dict/palettes/barcode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll3: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict/palettes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PalletDictModel} palletDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save7: async (palletDictModel: PalletDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'palletDictModel' is not null or undefined
            assertParamExists('save7', 'palletDictModel', palletDictModel)
            const localVarPath = `/api/dict/palettes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(palletDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PalletDictModel} palletDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update4: async (palletDictModel: PalletDictModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'palletDictModel' is not null or undefined
            assertParamExists('update4', 'palletDictModel', palletDictModel)
            const localVarPath = `/api/dict/palettes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(palletDictModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaletteControllerRestApi - functional programming interface
 * @export
 */
export const PaletteControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaletteControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete9(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete9(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaletteControllerRestApi.delete9']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBarCode2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBarCode2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaletteControllerRestApi.getBarCode2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll3(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PalletDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll3(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaletteControllerRestApi.listAll3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PalletDictModel} palletDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save7(palletDictModel: PalletDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save7(palletDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaletteControllerRestApi.save7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PalletDictModel} palletDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update4(palletDictModel: PalletDictModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update4(palletDictModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaletteControllerRestApi.update4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaletteControllerRestApi - factory interface
 * @export
 */
export const PaletteControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaletteControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete9(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete9(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode2(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getBarCode2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll3(options?: RawAxiosRequestConfig): AxiosPromise<Array<PalletDictModel>> {
            return localVarFp.listAll3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PalletDictModel} palletDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save7(palletDictModel: PalletDictModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.save7(palletDictModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PalletDictModel} palletDictModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update4(palletDictModel: PalletDictModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update4(palletDictModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaletteControllerRestApi - object-oriented interface
 * @export
 * @class PaletteControllerRestApi
 * @extends {BaseAPI}
 */
export class PaletteControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaletteControllerRestApi
     */
    public delete9(id: number, options?: RawAxiosRequestConfig) {
        return PaletteControllerRestApiFp(this.configuration).delete9(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaletteControllerRestApi
     */
    public getBarCode2(id: number, options?: RawAxiosRequestConfig) {
        return PaletteControllerRestApiFp(this.configuration).getBarCode2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaletteControllerRestApi
     */
    public listAll3(options?: RawAxiosRequestConfig) {
        return PaletteControllerRestApiFp(this.configuration).listAll3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PalletDictModel} palletDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaletteControllerRestApi
     */
    public save7(palletDictModel: PalletDictModel, options?: RawAxiosRequestConfig) {
        return PaletteControllerRestApiFp(this.configuration).save7(palletDictModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PalletDictModel} palletDictModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaletteControllerRestApi
     */
    public update4(palletDictModel: PalletDictModel, options?: RawAxiosRequestConfig) {
        return PaletteControllerRestApiFp(this.configuration).update4(palletDictModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PalletControllerRestApi - axios parameter creator
 * @export
 */
export const PalletControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} seal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParcel: async (id: number, seal: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addParcel', 'id', id)
            // verify required parameter 'seal' is not null or undefined
            assertParamExists('addParcel', 'seal', seal)
            const localVarPath = `/api/pallets/add/container`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (seal !== undefined) {
                localVarQueryParameter['seal'] = seal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete7: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete7', 'id', id)
            const localVarPath = `/api/pallets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById2', 'id', id)
            const localVarPath = `/api/pallets/byId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllByConsignmentId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listAllByConsignmentId', 'id', id)
            const localVarPath = `/api/pallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromPallet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFromPallet', 'id', id)
            const localVarPath = `/api/pallets/remove/from/pallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save4: async (palletModel: PalletModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'palletModel' is not null or undefined
            assertParamExists('save4', 'palletModel', palletModel)
            const localVarPath = `/api/pallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(palletModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2: async (palletModel: PalletModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'palletModel' is not null or undefined
            assertParamExists('update2', 'palletModel', palletModel)
            const localVarPath = `/api/pallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(palletModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDimensions: async (palletModel: PalletModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'palletModel' is not null or undefined
            assertParamExists('updateDimensions', 'palletModel', palletModel)
            const localVarPath = `/api/pallets/update/dimensions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(palletModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PalletControllerRestApi - functional programming interface
 * @export
 */
export const PalletControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PalletControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} seal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addParcel(id: number, seal: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addParcel(id, seal, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.addParcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete7(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete7(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.delete7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PalletModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.getById2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllByConsignmentId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PalletModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllByConsignmentId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.listAllByConsignmentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromPallet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromPallet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.removeFromPallet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save4(palletModel: PalletModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save4(palletModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.save4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update2(palletModel: PalletModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update2(palletModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.update2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDimensions(palletModel: PalletModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDimensions(palletModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalletControllerRestApi.updateDimensions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PalletControllerRestApi - factory interface
 * @export
 */
export const PalletControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PalletControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} seal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParcel(id: number, seal: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.addParcel(id, seal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete7(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete7(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2(id: number, options?: RawAxiosRequestConfig): AxiosPromise<PalletModel> {
            return localVarFp.getById2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllByConsignmentId(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<PalletModel>> {
            return localVarFp.listAllByConsignmentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromPallet(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeFromPallet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save4(palletModel: PalletModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.save4(palletModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(palletModel: PalletModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update2(palletModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PalletModel} palletModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDimensions(palletModel: PalletModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateDimensions(palletModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PalletControllerRestApi - object-oriented interface
 * @export
 * @class PalletControllerRestApi
 * @extends {BaseAPI}
 */
export class PalletControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {string} seal 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public addParcel(id: number, seal: string, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).addParcel(id, seal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public delete7(id: number, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).delete7(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public getById2(id: number, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).getById2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public listAllByConsignmentId(id: number, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).listAllByConsignmentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public removeFromPallet(id: number, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).removeFromPallet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PalletModel} palletModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public save4(palletModel: PalletModel, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).save4(palletModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PalletModel} palletModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public update2(palletModel: PalletModel, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).update2(palletModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PalletModel} palletModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalletControllerRestApi
     */
    public updateDimensions(palletModel: PalletModel, options?: RawAxiosRequestConfig) {
        return PalletControllerRestApiFp(this.configuration).updateDimensions(palletModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParcelControllerRestApi - axios parameter creator
 * @export
 */
export const ParcelControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBarCode', 'id', id)
            const localVarPath = `/api/parcel/getBarcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById1', 'id', id)
            const localVarPath = `/api/parcel/getById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdOrExternalTrack: async (val: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'val' is not null or undefined
            assertParamExists('getByIdOrExternalTrack', 'val', val)
            const localVarPath = `/api/parcel/getByIdOrExternalTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (val !== undefined) {
                localVarQueryParameter['val'] = val;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdOrTrack: async (val: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'val' is not null or undefined
            assertParamExists('getByIdOrTrack', 'val', val)
            const localVarPath = `/api/parcel/getByIdOrTrackCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (val !== undefined) {
                localVarQueryParameter['val'] = val;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTrack: async (track: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'track' is not null or undefined
            assertParamExists('getByTrack', 'track', track)
            const localVarPath = `/api/parcel/getByTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (track !== undefined) {
                localVarQueryParameter['track'] = track;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCdekNumberBarcode: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCdekNumberBarcode', 'id', id)
            const localVarPath = `/api/parcel/getCdekNumberBarcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQr: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQr', 'id', id)
            const localVarPath = `/api/parcel/getQr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitType: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/parcel/transit/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllByParcelId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listAllByParcelId', 'id', id)
            const localVarPath = `/api/parcel/status/updates/getById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelModel} parcelModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update9: async (parcelModel: ParcelModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelModel' is not null or undefined
            assertParamExists('update9', 'parcelModel', parcelModel)
            const localVarPath = `/api/parcel/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParcelControllerRestApi - functional programming interface
 * @export
 */
export const ParcelControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParcelControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBarCode(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBarCode(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getBarCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById1(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getById1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdOrExternalTrack(val: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdOrExternalTrack(val, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getByIdOrExternalTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdOrTrack(val: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdOrTrack(val, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getByIdOrTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByTrack(track: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByTrack(track, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getByTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCdekNumberBarcode(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCdekNumberBarcode(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getCdekNumberBarcode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQr(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQr(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getQr']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransitType(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransitType(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.getTransitType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllByParcelId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusUpdatesModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllByParcelId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.listAllByParcelId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelModel} parcelModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update9(parcelModel: ParcelModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update9(parcelModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelControllerRestApi.update9']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParcelControllerRestApi - factory interface
 * @export
 */
export const ParcelControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParcelControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getBarCode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.getById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdOrExternalTrack(val: string, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.getByIdOrExternalTrack(val, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} val 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdOrTrack(val: string, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.getByIdOrTrack(val, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTrack(track: string, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.getByTrack(track, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCdekNumberBarcode(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getCdekNumberBarcode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQr(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getQr(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitType(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getTransitType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllByParcelId(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<StatusUpdatesModel>> {
            return localVarFp.listAllByParcelId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelModel} parcelModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update9(parcelModel: ParcelModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update9(parcelModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParcelControllerRestApi - object-oriented interface
 * @export
 * @class ParcelControllerRestApi
 * @extends {BaseAPI}
 */
export class ParcelControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getBarCode(id: string, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getBarCode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getById1(id: number, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} val 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getByIdOrExternalTrack(val: string, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getByIdOrExternalTrack(val, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} val 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getByIdOrTrack(val: string, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getByIdOrTrack(val, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} track 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getByTrack(track: string, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getByTrack(track, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getCdekNumberBarcode(id: number, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getCdekNumberBarcode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getQr(id: string, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getQr(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public getTransitType(options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).getTransitType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public listAllByParcelId(id: number, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).listAllByParcelId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelModel} parcelModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelControllerRestApi
     */
    public update9(parcelModel: ParcelModel, options?: RawAxiosRequestConfig) {
        return ParcelControllerRestApiFp(this.configuration).update9(parcelModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParcelManualErrorControllerApi - axios parameter creator
 * @export
 */
export const ParcelManualErrorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ParcelManualErrorCreateDto} parcelManualErrorCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2: async (parcelManualErrorCreateDto: ParcelManualErrorCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelManualErrorCreateDto' is not null or undefined
            assertParamExists('create2', 'parcelManualErrorCreateDto', parcelManualErrorCreateDto)
            const localVarPath = `/api/parcel-manual-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelManualErrorCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parcelManualErrorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete6: async (parcelManualErrorId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelManualErrorId' is not null or undefined
            assertParamExists('delete6', 'parcelManualErrorId', parcelManualErrorId)
            const localVarPath = `/api/parcel-manual-error/{parcelManualErrorId}`
                .replace(`{${"parcelManualErrorId"}}`, encodeURIComponent(String(parcelManualErrorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Set<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiple: async (requestBody: Set<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteMultiple', 'requestBody', requestBody)
            const localVarPath = `/api/parcel-manual-error/delete/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {Set<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solve: async (parcelId: number, requestBody: Set<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelId' is not null or undefined
            assertParamExists('solve', 'parcelId', parcelId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('solve', 'requestBody', requestBody)
            const localVarPath = `/api/parcel-manual-error/parcel/{parcelId}/solve`
                .replace(`{${"parcelId"}}`, encodeURIComponent(String(parcelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        types: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/parcel-manual-error/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parcelManualErrorId 
         * @param {ParcelManualErrorUpdateDto} parcelManualErrorUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (parcelManualErrorId: number, parcelManualErrorUpdateDto: ParcelManualErrorUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelManualErrorId' is not null or undefined
            assertParamExists('update1', 'parcelManualErrorId', parcelManualErrorId)
            // verify required parameter 'parcelManualErrorUpdateDto' is not null or undefined
            assertParamExists('update1', 'parcelManualErrorUpdateDto', parcelManualErrorUpdateDto)
            const localVarPath = `/api/parcel-manual-error/{parcelManualErrorId}`
                .replace(`{${"parcelManualErrorId"}}`, encodeURIComponent(String(parcelManualErrorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelManualErrorUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParcelManualErrorControllerApi - functional programming interface
 * @export
 */
export const ParcelManualErrorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParcelManualErrorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ParcelManualErrorCreateDto} parcelManualErrorCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create2(parcelManualErrorCreateDto: ParcelManualErrorCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create2(parcelManualErrorCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelManualErrorControllerApi.create2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} parcelManualErrorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete6(parcelManualErrorId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete6(parcelManualErrorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelManualErrorControllerApi.delete6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Set<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMultiple(requestBody: Set<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMultiple(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelManualErrorControllerApi.deleteMultiple']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {Set<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async solve(parcelId: number, requestBody: Set<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.solve(parcelId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelManualErrorControllerApi.solve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async types(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.types(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelManualErrorControllerApi.types']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} parcelManualErrorId 
         * @param {ParcelManualErrorUpdateDto} parcelManualErrorUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(parcelManualErrorId: number, parcelManualErrorUpdateDto: ParcelManualErrorUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(parcelManualErrorId, parcelManualErrorUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelManualErrorControllerApi.update1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParcelManualErrorControllerApi - factory interface
 * @export
 */
export const ParcelManualErrorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParcelManualErrorControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ParcelManualErrorCreateDto} parcelManualErrorCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(parcelManualErrorCreateDto: ParcelManualErrorCreateDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.create2(parcelManualErrorCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parcelManualErrorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete6(parcelManualErrorId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete6(parcelManualErrorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Set<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiple(requestBody: Set<number>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMultiple(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {Set<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solve(parcelId: number, requestBody: Set<number>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.solve(parcelId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        types(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.types(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parcelManualErrorId 
         * @param {ParcelManualErrorUpdateDto} parcelManualErrorUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(parcelManualErrorId: number, parcelManualErrorUpdateDto: ParcelManualErrorUpdateDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update1(parcelManualErrorId, parcelManualErrorUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParcelManualErrorControllerApi - object-oriented interface
 * @export
 * @class ParcelManualErrorControllerApi
 * @extends {BaseAPI}
 */
export class ParcelManualErrorControllerApi extends BaseAPI {
    /**
     * 
     * @param {ParcelManualErrorCreateDto} parcelManualErrorCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelManualErrorControllerApi
     */
    public create2(parcelManualErrorCreateDto: ParcelManualErrorCreateDto, options?: RawAxiosRequestConfig) {
        return ParcelManualErrorControllerApiFp(this.configuration).create2(parcelManualErrorCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parcelManualErrorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelManualErrorControllerApi
     */
    public delete6(parcelManualErrorId: number, options?: RawAxiosRequestConfig) {
        return ParcelManualErrorControllerApiFp(this.configuration).delete6(parcelManualErrorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Set<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelManualErrorControllerApi
     */
    public deleteMultiple(requestBody: Set<number>, options?: RawAxiosRequestConfig) {
        return ParcelManualErrorControllerApiFp(this.configuration).deleteMultiple(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parcelId 
     * @param {Set<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelManualErrorControllerApi
     */
    public solve(parcelId: number, requestBody: Set<number>, options?: RawAxiosRequestConfig) {
        return ParcelManualErrorControllerApiFp(this.configuration).solve(parcelId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelManualErrorControllerApi
     */
    public types(options?: RawAxiosRequestConfig) {
        return ParcelManualErrorControllerApiFp(this.configuration).types(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parcelManualErrorId 
     * @param {ParcelManualErrorUpdateDto} parcelManualErrorUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelManualErrorControllerApi
     */
    public update1(parcelManualErrorId: number, parcelManualErrorUpdateDto: ParcelManualErrorUpdateDto, options?: RawAxiosRequestConfig) {
        return ParcelManualErrorControllerApiFp(this.configuration).update1(parcelManualErrorId, parcelManualErrorUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParcelRegistryControllerApi - axios parameter creator
 * @export
 */
export const ParcelRegistryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<ValidateRegistryModel>} validateRegistryModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (validateRegistryModel: Array<ValidateRegistryModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateRegistryModel' is not null or undefined
            assertParamExists('deleteProduct', 'validateRegistryModel', validateRegistryModel)
            const localVarPath = `/api/parcel/registry/delete/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateRegistryModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/api/parcel/registry/byId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcel: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExcel', 'id', id)
            const localVarPath = `/api/parcel/registry/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelByCdekCodesFromCdek: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getExcelByCdekCodesFromCdek', 'body', body)
            const localVarPath = `/api/parcel/registry/excel/by/cdek/numbers/fromCdek`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelById: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getExcelById', 'body', body)
            const localVarPath = `/api/parcel/registry/excel/by/id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelPayments: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/parcel/registry/excel/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks', 'body', body)
            const localVarPath = `/api/parcel/registry/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list3: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/parcel/registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountry: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/parcel/registry/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVerified: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/parcel/registry/verified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParcelRegistryModel} parcelRegistryModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save3: async (parcelRegistryModel: ParcelRegistryModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelRegistryModel' is not null or undefined
            assertParamExists('save3', 'parcelRegistryModel', parcelRegistryModel)
            const localVarPath = `/api/parcel/registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelRegistryModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} jiraNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToCheck: async (id: number, jiraNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendToCheck', 'id', id)
            // verify required parameter 'jiraNumber' is not null or undefined
            assertParamExists('sendToCheck', 'jiraNumber', jiraNumber)
            const localVarPath = `/api/parcel/registry/send/to/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (jiraNumber !== undefined) {
                localVarQueryParameter['jiraNumber'] = jiraNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FileModel} fileModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateExcel: async (fileModel: FileModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileModel' is not null or undefined
            assertParamExists('validateExcel', 'fileModel', fileModel)
            const localVarPath = `/api/parcel/registry/verify/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verify', 'id', id)
            const localVarPath = `/api/parcel/registry/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParcelRegistryControllerApi - functional programming interface
 * @export
 */
export const ParcelRegistryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParcelRegistryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<ValidateRegistryModel>} validateRegistryModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(validateRegistryModel: Array<ValidateRegistryModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(validateRegistryModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.deleteProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelRegistryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.getById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcel(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcel(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.getExcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcelByCdekCodesFromCdek(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcelByCdekCodesFromCdek(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.getExcelByCdekCodesFromCdek']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcelById(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcelById(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.getExcelById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcelPayments(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcelPayments(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.getExcelPayments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistryExcelResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list3(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParcelRegistryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list3(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.list3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCountry(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDictModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCountry(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.listCountry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVerified(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParcelRegistryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVerified(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.listVerified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParcelRegistryModel} parcelRegistryModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save3(parcelRegistryModel: ParcelRegistryModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save3(parcelRegistryModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.save3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} jiraNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendToCheck(id: number, jiraNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendToCheck(id, jiraNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.sendToCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FileModel} fileModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateExcel(fileModel: FileModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidatedRegistryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateExcel(fileModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.validateExcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verify(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verify(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelRegistryControllerApi.verify']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParcelRegistryControllerApi - factory interface
 * @export
 */
export const ParcelRegistryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParcelRegistryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<ValidateRegistryModel>} validateRegistryModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(validateRegistryModel: Array<ValidateRegistryModel>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProduct(validateRegistryModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ParcelRegistryModel> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcel(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getExcel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelByCdekCodesFromCdek(body: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getExcelByCdekCodesFromCdek(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelById(body: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getExcelById(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelPayments(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getExcelPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks(body: string, options?: RawAxiosRequestConfig): AxiosPromise<RegistryExcelResponseModel> {
            return localVarFp.getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list3(options?: RawAxiosRequestConfig): AxiosPromise<Array<ParcelRegistryModel>> {
            return localVarFp.list3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountry(options?: RawAxiosRequestConfig): AxiosPromise<Array<CountryDictModel>> {
            return localVarFp.listCountry(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVerified(options?: RawAxiosRequestConfig): AxiosPromise<Array<ParcelRegistryModel>> {
            return localVarFp.listVerified(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParcelRegistryModel} parcelRegistryModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save3(parcelRegistryModel: ParcelRegistryModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save3(parcelRegistryModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} jiraNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToCheck(id: number, jiraNumber: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendToCheck(id, jiraNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileModel} fileModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateExcel(fileModel: FileModel, options?: RawAxiosRequestConfig): AxiosPromise<ValidatedRegistryModel> {
            return localVarFp.validateExcel(fileModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.verify(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParcelRegistryControllerApi - object-oriented interface
 * @export
 * @class ParcelRegistryControllerApi
 * @extends {BaseAPI}
 */
export class ParcelRegistryControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<ValidateRegistryModel>} validateRegistryModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public deleteProduct(validateRegistryModel: Array<ValidateRegistryModel>, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).deleteProduct(validateRegistryModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public getById(id: number, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public getExcel(id: number, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).getExcel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public getExcelByCdekCodesFromCdek(body: string, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).getExcelByCdekCodesFromCdek(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public getExcelById(body: string, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).getExcelById(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public getExcelPayments(options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).getExcelPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks(body: string, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public list3(options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).list3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public listCountry(options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).listCountry(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public listVerified(options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).listVerified(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParcelRegistryModel} parcelRegistryModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public save3(parcelRegistryModel: ParcelRegistryModel, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).save3(parcelRegistryModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} jiraNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public sendToCheck(id: number, jiraNumber: string, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).sendToCheck(id, jiraNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileModel} fileModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public validateExcel(fileModel: FileModel, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).validateExcel(fileModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelRegistryControllerApi
     */
    public verify(id: number, options?: RawAxiosRequestConfig) {
        return ParcelRegistryControllerApiFp(this.configuration).verify(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParcelTrackControllerApi - axios parameter creator
 * @export
 */
export const ParcelTrackControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcelHistoryByInternalTrack: async (track: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'track' is not null or undefined
            assertParamExists('getParcelHistoryByInternalTrack', 'track', track)
            const localVarPath = `/api/public/parcel/track/{track}`
                .replace(`{${"track"}}`, encodeURIComponent(String(track)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParcelTrackControllerApi - functional programming interface
 * @export
 */
export const ParcelTrackControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParcelTrackControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParcelHistoryByInternalTrack(track: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiParcelWithStatusesReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParcelHistoryByInternalTrack(track, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelTrackControllerApi.getParcelHistoryByInternalTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParcelTrackControllerApi - factory interface
 * @export
 */
export const ParcelTrackControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParcelTrackControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcelHistoryByInternalTrack(track: string, options?: RawAxiosRequestConfig): AxiosPromise<ApiParcelWithStatusesReadDto> {
            return localVarFp.getParcelHistoryByInternalTrack(track, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParcelTrackControllerApi - object-oriented interface
 * @export
 * @class ParcelTrackControllerApi
 * @extends {BaseAPI}
 */
export class ParcelTrackControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} track 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelTrackControllerApi
     */
    public getParcelHistoryByInternalTrack(track: string, options?: RawAxiosRequestConfig) {
        return ParcelTrackControllerApiFp(this.configuration).getParcelHistoryByInternalTrack(track, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParcelV2ControllerApi - axios parameter creator
 * @export
 */
export const ParcelV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ParcelCreateDto} parcelCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (parcelCreateDto: ParcelCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelCreateDto' is not null or undefined
            assertParamExists('create', 'parcelCreateDto', parcelCreateDto)
            const localVarPath = `/api/v2/parcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: async (parcelId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelId' is not null or undefined
            assertParamExists('findById', 'parcelId', parcelId)
            const localVarPath = `/api/v2/parcel/{parcelId}`
                .replace(`{${"parcelId"}}`, encodeURIComponent(String(parcelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindIncomingByFilterStatusEnum} status 
         * @param {IncomingParcelFilter} incomingParcelFilter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findIncomingByFilter: async (status: FindIncomingByFilterStatusEnum, incomingParcelFilter: IncomingParcelFilter, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('findIncomingByFilter', 'status', status)
            // verify required parameter 'incomingParcelFilter' is not null or undefined
            assertParamExists('findIncomingByFilter', 'incomingParcelFilter', incomingParcelFilter)
            const localVarPath = `/api/v2/parcel/incoming/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(incomingParcelFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindOutgoingByFilterStatusEnum} status 
         * @param {OutgoingParcelFilter} outgoingParcelFilter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOutgoingByFilter: async (status: FindOutgoingByFilterStatusEnum, outgoingParcelFilter: OutgoingParcelFilter, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('findOutgoingByFilter', 'status', status)
            // verify required parameter 'outgoingParcelFilter' is not null or undefined
            assertParamExists('findOutgoingByFilter', 'outgoingParcelFilter', outgoingParcelFilter)
            const localVarPath = `/api/v2/parcel/outgoing/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(outgoingParcelFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {ParcelUpdateDto} parcelUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (parcelId: number, parcelUpdateDto: ParcelUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelId' is not null or undefined
            assertParamExists('update', 'parcelId', parcelId)
            // verify required parameter 'parcelUpdateDto' is not null or undefined
            assertParamExists('update', 'parcelUpdateDto', parcelUpdateDto)
            const localVarPath = `/api/v2/parcel/{parcelId}`
                .replace(`{${"parcelId"}}`, encodeURIComponent(String(parcelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParcelV2ControllerApi - functional programming interface
 * @export
 */
export const ParcelV2ControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParcelV2ControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ParcelCreateDto} parcelCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(parcelCreateDto: ParcelCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(parcelCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelV2ControllerApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById(parcelId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findById(parcelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelV2ControllerApi.findById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindIncomingByFilterStatusEnum} status 
         * @param {IncomingParcelFilter} incomingParcelFilter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findIncomingByFilter(status: FindIncomingByFilterStatusEnum, incomingParcelFilter: IncomingParcelFilter, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoParcelReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findIncomingByFilter(status, incomingParcelFilter, pageNumber, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelV2ControllerApi.findIncomingByFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindOutgoingByFilterStatusEnum} status 
         * @param {OutgoingParcelFilter} outgoingParcelFilter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOutgoingByFilter(status: FindOutgoingByFilterStatusEnum, outgoingParcelFilter: OutgoingParcelFilter, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoParcelReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOutgoingByFilter(status, outgoingParcelFilter, pageNumber, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelV2ControllerApi.findOutgoingByFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {ParcelUpdateDto} parcelUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(parcelId: number, parcelUpdateDto: ParcelUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(parcelId, parcelUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParcelV2ControllerApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParcelV2ControllerApi - factory interface
 * @export
 */
export const ParcelV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParcelV2ControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ParcelCreateDto} parcelCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(parcelCreateDto: ParcelCreateDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.create(parcelCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById(parcelId: number, options?: RawAxiosRequestConfig): AxiosPromise<ParcelReadDto> {
            return localVarFp.findById(parcelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindIncomingByFilterStatusEnum} status 
         * @param {IncomingParcelFilter} incomingParcelFilter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findIncomingByFilter(status: FindIncomingByFilterStatusEnum, incomingParcelFilter: IncomingParcelFilter, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PageDtoParcelReadDto> {
            return localVarFp.findIncomingByFilter(status, incomingParcelFilter, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindOutgoingByFilterStatusEnum} status 
         * @param {OutgoingParcelFilter} outgoingParcelFilter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOutgoingByFilter(status: FindOutgoingByFilterStatusEnum, outgoingParcelFilter: OutgoingParcelFilter, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PageDtoParcelReadDto> {
            return localVarFp.findOutgoingByFilter(status, outgoingParcelFilter, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parcelId 
         * @param {ParcelUpdateDto} parcelUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(parcelId: number, parcelUpdateDto: ParcelUpdateDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update(parcelId, parcelUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParcelV2ControllerApi - object-oriented interface
 * @export
 * @class ParcelV2ControllerApi
 * @extends {BaseAPI}
 */
export class ParcelV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {ParcelCreateDto} parcelCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelV2ControllerApi
     */
    public create(parcelCreateDto: ParcelCreateDto, options?: RawAxiosRequestConfig) {
        return ParcelV2ControllerApiFp(this.configuration).create(parcelCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parcelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelV2ControllerApi
     */
    public findById(parcelId: number, options?: RawAxiosRequestConfig) {
        return ParcelV2ControllerApiFp(this.configuration).findById(parcelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindIncomingByFilterStatusEnum} status 
     * @param {IncomingParcelFilter} incomingParcelFilter 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelV2ControllerApi
     */
    public findIncomingByFilter(status: FindIncomingByFilterStatusEnum, incomingParcelFilter: IncomingParcelFilter, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return ParcelV2ControllerApiFp(this.configuration).findIncomingByFilter(status, incomingParcelFilter, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindOutgoingByFilterStatusEnum} status 
     * @param {OutgoingParcelFilter} outgoingParcelFilter 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelV2ControllerApi
     */
    public findOutgoingByFilter(status: FindOutgoingByFilterStatusEnum, outgoingParcelFilter: OutgoingParcelFilter, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return ParcelV2ControllerApiFp(this.configuration).findOutgoingByFilter(status, outgoingParcelFilter, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parcelId 
     * @param {ParcelUpdateDto} parcelUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelV2ControllerApi
     */
    public update(parcelId: number, parcelUpdateDto: ParcelUpdateDto, options?: RawAxiosRequestConfig) {
        return ParcelV2ControllerApiFp(this.configuration).update(parcelId, parcelUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindIncomingByFilterStatusEnum = {
    NotFilled: 'NOT_FILLED',
    New: 'NEW',
    Registered: 'REGISTERED',
    PreparedToSent: 'PREPARED_TO_SENT',
    CheckingConsignment: 'CHECKING_CONSIGNMENT',
    RegistryVerified: 'REGISTRY_VERIFIED',
    Sent: 'SENT',
    Received: 'RECEIVED',
    Undefined: 'UNDEFINED',
    WaitForInfo: 'WAIT_FOR_INFO',
    ReturnParcel: 'RETURN_PARCEL',
    Utilize: 'UTILIZE',
    Removed: 'REMOVED',
    History: 'HISTORY',
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    Assemble: 'ASSEMBLE',
    Consigned: 'CONSIGNED'
} as const;
export type FindIncomingByFilterStatusEnum = typeof FindIncomingByFilterStatusEnum[keyof typeof FindIncomingByFilterStatusEnum];
/**
 * @export
 */
export const FindOutgoingByFilterStatusEnum = {
    NotFilled: 'NOT_FILLED',
    New: 'NEW',
    Registered: 'REGISTERED',
    PreparedToSent: 'PREPARED_TO_SENT',
    CheckingConsignment: 'CHECKING_CONSIGNMENT',
    RegistryVerified: 'REGISTRY_VERIFIED',
    Sent: 'SENT',
    Received: 'RECEIVED',
    Undefined: 'UNDEFINED',
    WaitForInfo: 'WAIT_FOR_INFO',
    ReturnParcel: 'RETURN_PARCEL',
    Utilize: 'UTILIZE',
    Removed: 'REMOVED',
    History: 'HISTORY',
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    Assemble: 'ASSEMBLE',
    Consigned: 'CONSIGNED'
} as const;
export type FindOutgoingByFilterStatusEnum = typeof FindOutgoingByFilterStatusEnum[keyof typeof FindOutgoingByFilterStatusEnum];


/**
 * PermissionControllerRestApi - axios parameter creator
 * @export
 */
export const PermissionControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionControllerRestApi - functional programming interface
 * @export
 */
export const PermissionControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionControllerRestApi.list2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PermissionControllerRestApi - factory interface
 * @export
 */
export const PermissionControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list2(options?: RawAxiosRequestConfig): AxiosPromise<Array<PermissionModel>> {
            return localVarFp.list2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionControllerRestApi - object-oriented interface
 * @export
 * @class PermissionControllerRestApi
 * @extends {BaseAPI}
 */
export class PermissionControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionControllerRestApi
     */
    public list2(options?: RawAxiosRequestConfig) {
        return PermissionControllerRestApiFp(this.configuration).list2(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PimWmsControllerApi - axios parameter creator
 * @export
 */
export const PimWmsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsignment: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createConsignment', 'id', id)
            const localVarPath = `/api/pim-wms/upload-consignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PimWmsControllerApi - functional programming interface
 * @export
 */
export const PimWmsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PimWmsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsignment(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsignment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PimWmsControllerApi.createConsignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PimWmsControllerApi - factory interface
 * @export
 */
export const PimWmsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PimWmsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsignment(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createConsignment(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PimWmsControllerApi - object-oriented interface
 * @export
 * @class PimWmsControllerApi
 * @extends {BaseAPI}
 */
export class PimWmsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PimWmsControllerApi
     */
    public createConsignment(id: number, options?: RawAxiosRequestConfig) {
        return PimWmsControllerApiFp(this.configuration).createConsignment(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PlaceControllerRestApi - axios parameter creator
 * @export
 */
export const PlaceControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete8: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete8', 'id', id)
            const localVarPath = `/api/dict/place/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBarCode1', 'id', id)
            const localVarPath = `/api/dict/place/barcode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict/place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlaceModel} placeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save6: async (placeModel: PlaceModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeModel' is not null or undefined
            assertParamExists('save6', 'placeModel', placeModel)
            const localVarPath = `/api/dict/place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(placeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlaceModel} placeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update3: async (placeModel: PlaceModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeModel' is not null or undefined
            assertParamExists('update3', 'placeModel', placeModel)
            const localVarPath = `/api/dict/place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(placeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaceControllerRestApi - functional programming interface
 * @export
 */
export const PlaceControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaceControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete8(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete8(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlaceControllerRestApi.delete8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBarCode1(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBarCode1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlaceControllerRestApi.getBarCode1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlaceControllerRestApi.listAll2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PlaceModel} placeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save6(placeModel: PlaceModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save6(placeModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlaceControllerRestApi.save6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PlaceModel} placeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update3(placeModel: PlaceModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update3(placeModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlaceControllerRestApi.update3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PlaceControllerRestApi - factory interface
 * @export
 */
export const PlaceControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaceControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete8(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete8(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBarCode1(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getBarCode1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll2(options?: RawAxiosRequestConfig): AxiosPromise<Array<PlaceModel>> {
            return localVarFp.listAll2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlaceModel} placeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save6(placeModel: PlaceModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.save6(placeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlaceModel} placeModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update3(placeModel: PlaceModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update3(placeModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlaceControllerRestApi - object-oriented interface
 * @export
 * @class PlaceControllerRestApi
 * @extends {BaseAPI}
 */
export class PlaceControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceControllerRestApi
     */
    public delete8(id: number, options?: RawAxiosRequestConfig) {
        return PlaceControllerRestApiFp(this.configuration).delete8(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceControllerRestApi
     */
    public getBarCode1(id: number, options?: RawAxiosRequestConfig) {
        return PlaceControllerRestApiFp(this.configuration).getBarCode1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceControllerRestApi
     */
    public listAll2(options?: RawAxiosRequestConfig) {
        return PlaceControllerRestApiFp(this.configuration).listAll2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlaceModel} placeModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceControllerRestApi
     */
    public save6(placeModel: PlaceModel, options?: RawAxiosRequestConfig) {
        return PlaceControllerRestApiFp(this.configuration).save6(placeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlaceModel} placeModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceControllerRestApi
     */
    public update3(placeModel: PlaceModel, options?: RawAxiosRequestConfig) {
        return PlaceControllerRestApiFp(this.configuration).update3(placeModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportQueueControllerApi - axios parameter creator
 * @export
 */
export const ReportQueueControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportQueueCreateDto} reportQueueCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (reportQueueCreateDto: ReportQueueCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportQueueCreateDto' is not null or undefined
            assertParamExists('create1', 'reportQueueCreateDto', reportQueueCreateDto)
            const localVarPath = `/api/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportQueueCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} reportQueueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete5: async (reportQueueId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportQueueId' is not null or undefined
            assertParamExists('delete5', 'reportQueueId', reportQueueId)
            const localVarPath = `/api/report/{reportQueueId}`
                .replace(`{${"reportQueueId"}}`, encodeURIComponent(String(reportQueueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} reportQueueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport: async (reportQueueId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportQueueId' is not null or undefined
            assertParamExists('downloadReport', 'reportQueueId', reportQueueId)
            const localVarPath = `/api/report/download/{reportQueueId}`
                .replace(`{${"reportQueueId"}}`, encodeURIComponent(String(reportQueueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportQueueControllerApi - functional programming interface
 * @export
 */
export const ReportQueueControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportQueueControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ReportQueueCreateDto} reportQueueCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(reportQueueCreateDto: ReportQueueCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(reportQueueCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportQueueControllerApi.create1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} reportQueueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete5(reportQueueId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete5(reportQueueId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportQueueControllerApi.delete5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} reportQueueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReport(reportQueueId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDownloadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReport(reportQueueId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportQueueControllerApi.downloadReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async read(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportQueueReadDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.read(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportQueueControllerApi.read']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportQueueControllerApi - factory interface
 * @export
 */
export const ReportQueueControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportQueueControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ReportQueueCreateDto} reportQueueCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(reportQueueCreateDto: ReportQueueCreateDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.create1(reportQueueCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} reportQueueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete5(reportQueueId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete5(reportQueueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} reportQueueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(reportQueueId: number, options?: RawAxiosRequestConfig): AxiosPromise<FileDownloadDto> {
            return localVarFp.downloadReport(reportQueueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read(options?: RawAxiosRequestConfig): AxiosPromise<Array<ReportQueueReadDto>> {
            return localVarFp.read(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportQueueControllerApi - object-oriented interface
 * @export
 * @class ReportQueueControllerApi
 * @extends {BaseAPI}
 */
export class ReportQueueControllerApi extends BaseAPI {
    /**
     * 
     * @param {ReportQueueCreateDto} reportQueueCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportQueueControllerApi
     */
    public create1(reportQueueCreateDto: ReportQueueCreateDto, options?: RawAxiosRequestConfig) {
        return ReportQueueControllerApiFp(this.configuration).create1(reportQueueCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} reportQueueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportQueueControllerApi
     */
    public delete5(reportQueueId: number, options?: RawAxiosRequestConfig) {
        return ReportQueueControllerApiFp(this.configuration).delete5(reportQueueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} reportQueueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportQueueControllerApi
     */
    public downloadReport(reportQueueId: number, options?: RawAxiosRequestConfig) {
        return ReportQueueControllerApiFp(this.configuration).downloadReport(reportQueueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportQueueControllerApi
     */
    public read(options?: RawAxiosRequestConfig) {
        return ReportQueueControllerApiFp(this.configuration).read(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoleControllerRestApi - axios parameter creator
 * @export
 */
export const RoleControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete4: async (roleId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('delete4', 'roleId', roleId)
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPermissions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoleModel} roleModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save2: async (roleModel: RoleModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleModel' is not null or undefined
            assertParamExists('save2', 'roleModel', roleModel)
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleControllerRestApi - functional programming interface
 * @export
 */
export const RoleControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete4(roleId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete4(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleControllerRestApi.delete4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleControllerRestApi.list1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPermissions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPermissions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleControllerRestApi.listPermissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RoleModel} roleModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save2(roleModel: RoleModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save2(roleModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleControllerRestApi.save2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RoleControllerRestApi - factory interface
 * @export
 */
export const RoleControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete4(roleId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete4(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1(options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleModel>> {
            return localVarFp.list1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPermissions(options?: RawAxiosRequestConfig): AxiosPromise<Array<PermissionModel>> {
            return localVarFp.listPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleModel} roleModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save2(roleModel: RoleModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save2(roleModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleControllerRestApi - object-oriented interface
 * @export
 * @class RoleControllerRestApi
 * @extends {BaseAPI}
 */
export class RoleControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerRestApi
     */
    public delete4(roleId: number, options?: RawAxiosRequestConfig) {
        return RoleControllerRestApiFp(this.configuration).delete4(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerRestApi
     */
    public list1(options?: RawAxiosRequestConfig) {
        return RoleControllerRestApiFp(this.configuration).list1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerRestApi
     */
    public listPermissions(options?: RawAxiosRequestConfig) {
        return RoleControllerRestApiFp(this.configuration).listPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleModel} roleModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerRestApi
     */
    public save2(roleModel: RoleModel, options?: RawAxiosRequestConfig) {
        return RoleControllerRestApiFp(this.configuration).save2(roleModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchControllerApi - axios parameter creator
 * @export
 */
export const SearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (search: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('search', 'search', search)
            const localVarPath = `/api/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(search: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchControllerApi.search']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(search: string, options?: RawAxiosRequestConfig): AxiosPromise<SearchModel> {
            return localVarFp.search(search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} search 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchControllerApi
     */
    public search(search: string, options?: RawAxiosRequestConfig) {
        return SearchControllerApiFp(this.configuration).search(search, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StockControllerRestApi - axios parameter creator
 * @export
 */
export const StockControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete3: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete3', 'id', id)
            const localVarPath = `/api/stock/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} regionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCities: async (regionCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionCode' is not null or undefined
            assertParamExists('listCities', 'regionCode', regionCode)
            const localVarPath = `/api/stock/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (regionCode !== undefined) {
                localVarQueryParameter['regionCode'] = regionCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stock/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockModel} stockModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1: async (stockModel: StockModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockModel' is not null or undefined
            assertParamExists('save1', 'stockModel', stockModel)
            const localVarPath = `/api/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockModel} stockModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update8: async (stockModel: StockModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockModel' is not null or undefined
            assertParamExists('update8', 'stockModel', stockModel)
            const localVarPath = `/api/stock/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockControllerRestApi - functional programming interface
 * @export
 */
export const StockControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete3(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete3(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockControllerRestApi.delete3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockControllerRestApi.listAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} regionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCities(regionCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CdekCityModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCities(regionCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockControllerRestApi.listCities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRegions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CdekRegionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRegions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockControllerRestApi.listRegions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StockModel} stockModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save1(stockModel: StockModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save1(stockModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockControllerRestApi.save1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StockModel} stockModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update8(stockModel: StockModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update8(stockModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockControllerRestApi.update8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StockControllerRestApi - factory interface
 * @export
 */
export const StockControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete3(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StockModel>> {
            return localVarFp.listAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} regionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCities(regionCode: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CdekCityModel>> {
            return localVarFp.listCities(regionCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegions(options?: RawAxiosRequestConfig): AxiosPromise<Array<CdekRegionModel>> {
            return localVarFp.listRegions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockModel} stockModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1(stockModel: StockModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.save1(stockModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockModel} stockModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update8(stockModel: StockModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update8(stockModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockControllerRestApi - object-oriented interface
 * @export
 * @class StockControllerRestApi
 * @extends {BaseAPI}
 */
export class StockControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockControllerRestApi
     */
    public delete3(id: number, options?: RawAxiosRequestConfig) {
        return StockControllerRestApiFp(this.configuration).delete3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockControllerRestApi
     */
    public listAll(options?: RawAxiosRequestConfig) {
        return StockControllerRestApiFp(this.configuration).listAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} regionCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockControllerRestApi
     */
    public listCities(regionCode: string, options?: RawAxiosRequestConfig) {
        return StockControllerRestApiFp(this.configuration).listCities(regionCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockControllerRestApi
     */
    public listRegions(options?: RawAxiosRequestConfig) {
        return StockControllerRestApiFp(this.configuration).listRegions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockModel} stockModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockControllerRestApi
     */
    public save1(stockModel: StockModel, options?: RawAxiosRequestConfig) {
        return StockControllerRestApiFp(this.configuration).save1(stockModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockModel} stockModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockControllerRestApi
     */
    public update8(stockModel: StockModel, options?: RawAxiosRequestConfig) {
        return StockControllerRestApiFp(this.configuration).update8(stockModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrackNumberControllerRestApi - axios parameter creator
 * @export
 */
export const TrackNumberControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/track/number/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} trackNumber 
         * @param {GetResponseCompanyEnum} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponse: async (trackNumber: string, company: GetResponseCompanyEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackNumber' is not null or undefined
            assertParamExists('getResponse', 'trackNumber', trackNumber)
            // verify required parameter 'company' is not null or undefined
            assertParamExists('getResponse', 'company', company)
            const localVarPath = `/api/track/number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (trackNumber !== undefined) {
                localVarQueryParameter['trackNumber'] = trackNumber;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackNumberControllerRestApi - functional programming interface
 * @export
 */
export const TrackNumberControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackNumberControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrackNumberControllerRestApi.getCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} trackNumber 
         * @param {GetResponseCompanyEnum} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResponse(trackNumber: string, company: GetResponseCompanyEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResponse(trackNumber, company, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrackNumberControllerRestApi.getResponse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TrackNumberControllerRestApi - factory interface
 * @export
 */
export const TrackNumberControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackNumberControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} trackNumber 
         * @param {GetResponseCompanyEnum} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponse(trackNumber: string, company: GetResponseCompanyEnum, options?: RawAxiosRequestConfig): AxiosPromise<TrackResponseModel> {
            return localVarFp.getResponse(trackNumber, company, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackNumberControllerRestApi - object-oriented interface
 * @export
 * @class TrackNumberControllerRestApi
 * @extends {BaseAPI}
 */
export class TrackNumberControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackNumberControllerRestApi
     */
    public getCompany(options?: RawAxiosRequestConfig) {
        return TrackNumberControllerRestApiFp(this.configuration).getCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} trackNumber 
     * @param {GetResponseCompanyEnum} company 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackNumberControllerRestApi
     */
    public getResponse(trackNumber: string, company: GetResponseCompanyEnum, options?: RawAxiosRequestConfig) {
        return TrackNumberControllerRestApiFp(this.configuration).getResponse(trackNumber, company, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetResponseCompanyEnum = {
    Dhl: 'DHL',
    Ups: 'UPS',
    Usps: 'USPS',
    Fedex: 'FEDEX',
    None: 'NONE'
} as const;
export type GetResponseCompanyEnum = typeof GetResponseCompanyEnum[keyof typeof GetResponseCompanyEnum];


/**
 * TransitStockControllerRestApi - axios parameter creator
 * @export
 */
export const TransitStockControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cdekId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findParcelByCdekCode: async (cdekId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cdekId' is not null or undefined
            assertParamExists('findParcelByCdekCode', 'cdekId', cdekId)
            const localVarPath = `/api/transit/stock/find/by`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cdekId !== undefined) {
                localVarQueryParameter['cdekId'] = cdekId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransitStockControllerRestApi - functional programming interface
 * @export
 */
export const TransitStockControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransitStockControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} cdekId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findParcelByCdekCode(cdekId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findParcelByCdekCode(cdekId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransitStockControllerRestApi.findParcelByCdekCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransitStockControllerRestApi - factory interface
 * @export
 */
export const TransitStockControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransitStockControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {string} cdekId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findParcelByCdekCode(cdekId: string, options?: RawAxiosRequestConfig): AxiosPromise<ParcelModel> {
            return localVarFp.findParcelByCdekCode(cdekId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransitStockControllerRestApi - object-oriented interface
 * @export
 * @class TransitStockControllerRestApi
 * @extends {BaseAPI}
 */
export class TransitStockControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {string} cdekId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitStockControllerRestApi
     */
    public findParcelByCdekCode(cdekId: string, options?: RawAxiosRequestConfig) {
        return TransitStockControllerRestApiFp(this.configuration).findParcelByCdekCode(cdekId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserControllerRestApi - axios parameter creator
 * @export
 */
export const UserControllerRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        block: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('block', 'id', id)
            const localVarPath = `/api/user/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPasswordRestoreRequest: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createPasswordRestoreRequest', 'id', id)
            const localVarPath = `/api/user/create/restore/password/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        current: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} authId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete2: async (authId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authId' is not null or undefined
            assertParamExists('delete2', 'authId', authId)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authId !== undefined) {
                localVarQueryParameter['authId'] = authId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/api/user/byId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetUsersByTypeTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByType: async (type: GetUsersByTypeTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getUsersByType', 'type', type)
            const localVarPath = `/api/user/byType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (userModel: UserModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userModel' is not null or undefined
            assertParamExists('save', 'userModel', userModel)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectStock: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectStock', 'id', id)
            const localVarPath = `/api/user/select/stock/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnPassword: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateOwnPassword', 'body', body)
            const localVarPath = `/api/user/update/own/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (userId: number, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePassword', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePassword', 'body', body)
            const localVarPath = `/api/user/update/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerRestApi - functional programming interface
 * @export
 */
export const UserControllerRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerRestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async block(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.block(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.block']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPasswordRestoreRequest(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPasswordRestoreRequest(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.createPasswordRestoreRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async current(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.current(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.current']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} authId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete2(authId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete2(authId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.delete2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.getUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetUsersByTypeTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByType(type: GetUsersByTypeTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByType(type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.getUsersByType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.list']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(userModel: UserModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(userModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.save']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectStock(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectStock(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.selectStock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnPassword(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnPassword(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.updateOwnPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(userId: number, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerRestApi.updatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserControllerRestApi - factory interface
 * @export
 */
export const UserControllerRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerRestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        block(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.block(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPasswordRestoreRequest(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createPasswordRestoreRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        current(options?: RawAxiosRequestConfig): AxiosPromise<AuthModel> {
            return localVarFp.current(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} authId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete2(authId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.delete2(authId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<UserModel> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetUsersByTypeTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByType(type: GetUsersByTypeTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserModel>> {
            return localVarFp.getUsersByType(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleModel>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(userModel: UserModel, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.save(userModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectStock(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.selectStock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnPassword(body: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateOwnPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(userId: number, body: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePassword(userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerRestApi - object-oriented interface
 * @export
 * @class UserControllerRestApi
 * @extends {BaseAPI}
 */
export class UserControllerRestApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public block(id: number, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).block(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public createPasswordRestoreRequest(id: number, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).createPasswordRestoreRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public current(options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).current(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} authId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public delete2(authId: number, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).delete2(authId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public getUserById(id: number, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetUsersByTypeTypeEnum} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public getUsersByType(type: GetUsersByTypeTypeEnum, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).getUsersByType(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public list(options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserModel} userModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public save(userModel: UserModel, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).save(userModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public selectStock(id: number, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).selectStock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public updateOwnPassword(body: string, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).updateOwnPassword(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerRestApi
     */
    public updatePassword(userId: number, body: string, options?: RawAxiosRequestConfig) {
        return UserControllerRestApiFp(this.configuration).updatePassword(userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetUsersByTypeTypeEnum = {
    Manager: 'MANAGER',
    Client: 'CLIENT',
    Business: 'BUSINESS',
    Shopping: 'SHOPPING',
    Api: 'API',
    TelegramBot: 'TELEGRAM_BOT'
} as const;
export type GetUsersByTypeTypeEnum = typeof GetUsersByTypeTypeEnum[keyof typeof GetUsersByTypeTypeEnum];


